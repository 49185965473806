import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    open: true,
  },
  reducers: {
    openSidebar(state, action) {
      state.open = true;
    },
    toggleSidebar(state, action) {
      state.open = !state.open;
    },
    closeSidebar(state, action) {
      state.open = false;
    },
  },
});

export const { openSidebar, toggleSidebar, closeSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
