import React, { useState } from "react";

import Select from "react-select";
// import { colourOptions } from "../data";

export default ({
  name,
  placeholder,
  isDisabled,
  value,
  initialValue,
  options,
  onChange,
}) => {
  return (
    <>
      <Select
        className=""
        classNamePrefix="select"
        // value={value}
        defaultValue={initialValue}
        isDisabled={isDisabled}
        placeholder={placeholder}
        // isLoading={true}
        // isClearable={true}
        // isRtl={isRtl}
        isSearchable={true}
        name={name}
        options={options}
        onChange={(selectedOption) =>
          onChange({ target: { value: selectedOption.value, name } })
        }
      />
    </>
  );
};
