import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";

const CustomSelectAsync = ({
  name,
  placeholder,
  isDisabled,
  isLoading,
  loadOptions,
  onChange,
  initialValue,
}) => {
  // const [options, setOptions] = useState([]); // State to store the options
  // const [initialValue, setInitialValue] = useState(null);

  // // Load the options asynchronously
  // useEffect(() => {
  //   loadOptions(value.firstName).then((loadedOptions) => {
  //     setOptions(loadedOptions);

  //     // Find the option that matches the value object
  //     const foundOption = loadedOptions.find(
  //       (option) => option.value === value.value
  //     );

  //     // Set the initial value based on the found option
  //     setInitialValue(foundOption);
  //   });
  // }, [loadOptions, value.value]);

  return (
    <AsyncSelect
      className=""
      classNamePrefix="select"
      defaultValue={initialValue} // Set the initial value
      // value={value}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable={true}
      name={name}
      placeholder={placeholder ? placeholder : ""}
      // options={options} // Pass the loaded options
      loadOptions={loadOptions}
      onChange={(selectedOption) =>
        onChange({ target: { value: selectedOption.value, name } })
      }
    />
  );
};

export default CustomSelectAsync;
