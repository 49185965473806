import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../table";
import { useSelector } from "react-redux";

function Accordion() {
  const [isOpen, setOpen] = useState(false);

  const acc = [1, 2, 3, 4];

  const AccordionHandler = (index) => {
    setOpen(isOpen === index ? false : index);
  };

  return (
    <div className="container-fluid">
      {acc.map((x, index) => (
        <div className="row">
          <div className="col-lg-12">
            <div
              className="card card-block card-stretch card-height"
              onClick={() => AccordionHandler(index)}
            >
              {" "}
              <div className="card-header d-flex justify-content-between">
                <div className="header-title d-flex align-items-center">
                  <i className="fa mr-10 fa-plus " />
                  <h4 className="card-title mb-0 ml-1 ">Permission List</h4>
                </div>
              </div>
              {/* <Table dataSource={list} isLoading={isLoading} columns={columns} /> */}
              {isOpen === index && (
                <div className="card-body table-responsive">
                  <table className="table text-center table-bordered bg_white">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Admin</th>
                        <th>Driver</th>
                        <th>Rider</th>
                      </tr>
                      <tr>
                        <td className="text-capitalize">role add</td>
                        <td>
                          <input
                            className="checkbox no-wh permission_check"
                            id="permission-1-2"
                            type="checkbox"
                            name="permission[role add][]"
                            value="admin"
                            checked=""
                          />
                        </td>
                        <td>
                          <input
                            className="checkbox no-wh permission_check"
                            id="permission-3-2"
                            type="checkbox"
                            name="permission[role add][]"
                            value="driver"
                          />
                        </td>
                        <td>
                          <input
                            className="checkbox no-wh permission_check"
                            id="permission-2-2"
                            type="checkbox"
                            name="permission[role add][]"
                            value="rider"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-capitalize">role list</td>
                        <td>
                          <input
                            className="checkbox no-wh permission_check"
                            id="permission-1-3"
                            type="checkbox"
                            name="permission[role list][]"
                            value="admin"
                            checked=""
                          />
                        </td>
                        <td>
                          <input
                            className="checkbox no-wh permission_check"
                            id="permission-3-3"
                            type="checkbox"
                            name="permission[role list][]"
                            value="driver"
                          />
                        </td>
                        <td>
                          <input
                            className="checkbox no-wh permission_check"
                            id="permission-2-3"
                            type="checkbox"
                            name="permission[role list][]"
                            value="rider"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <input
                    type="submit"
                    name="Save"
                    value="Save"
                    className="btn btn-md btn-primary float-right mall-10"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordion;
