import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Link, useLocation } from "react-router-dom";

import CheckPermission from "../../utils/checkPermission";
import TableV2 from "../../components/tableV2";
import { useGetWalletsQuery } from "../../redux/services/walletAPI";

const WalletsTable = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");

  const searchValueHandler = (value) => {
    setQuery(value);
  };

  const {
    data: wallets,
    isLoading,
    // error,
  } = useGetWalletsQuery({ page, limit, country: auth.country, query });

  const columns = [
    {
      // key: "firstName",
      displayField: (e) => (
        <>
          {e.user?.firstName} {e.user?.lastName}
        </>
      ),
      displayName: "User Name",
      searchable: true,
      key: "user.firstName",
    },
    {
      // key: "firstName",
      displayField: (e) => <>{e.modelNameOfTheUser}</>,
      displayName: "User Type",
      searchable: true,        
      key: "modelNameOfTheUser",
    },
    {
      // key: "lastName",
      displayField: (e) => (
        <>
          {e.currency} {e.amount}
        </>
      ),
      displayName: "Amount",      
      searchable: true,
      key: "amount",
    },
    {
      // key: "status",
      displayName: "Status",
      displayField: (e) => (
        <>
          {e.status === "Active" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
      searchable: false,
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (                  
        <div className="d-flex justify-content-start align-items-center">
          <Link className="mr-2" to="" title="Update Rider">
            <i className="fas fa-edit text-primary" />
          </Link>
          {/* <Link className="mr-2" href="">
            <i className="fas fa-eye text-secondary" />
          </Link> */}
        </div>
      ),
      searchable: false,
      hidden: CheckPermission(location),
    },
  ];

  // useEffect(() => {
  //   dispatch(asyncGetWallets());                           
  // }, [auth.country]);

  return (
    <>
      <TableV2
        dataSource={wallets?.results || []}
        isLoading={isLoading}
        columns={columns}
        currentPage={page}                        
        setCurrentPage={setPage}
        itemsPerPage={limit}
        setItemsPerPage={setLimit}
        totalEntries={wallets?.totalResults}
        totalPages={wallets?.totalPages}
        searchValueHandler={searchValueHandler}
      />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};
export default WalletsTable;
             