import React from "react";

function CurrencySymbol(props) {
  const { currencyCode } = props;

  // Create an instance of Intl.NumberFormat with the currency code
  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode,
  });

  // Get the currency symbol
  const currencySymbol = formatter
    .formatToParts(1)
    .find((part) => part.type === "currency").value;

  return <>{currencySymbol}</>;
}

export default CurrencySymbol;
