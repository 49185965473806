import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { Link, useLocation } from "react-router-dom";
import { asyncGetRideFares } from "../../redux/slices/rideFareSlice";
import CheckPermission from "../../utils/checkPermission";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const FareTable = () => {
  const rideFares = useSelector((state) => state.rideFares);
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  const { isLoading, list } = rideFares;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);

  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });

  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };

  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllCabs(
  //       { companyId: auth?.cab?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= cabs.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllCabs(
  //         { companyId: auth?.cab?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllCabs(
  //         { companyId: auth?.cab?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllCabs(
  //       { companyId: auth?.cab?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(asyncGetRideFares());
  }, [auth.country]);

  const columns = [
    {
      key: "",
      displayName: "Category",
      displayField: (e) => <>{e?.vehicleCategory?.name}</>,
      searchable: true,
    },
    // { key: "currency", displayName: "Currency" },
    {
      displayField: (e) => (
        <>
          {e?.currency} {e?.baseFare}
        </>
      ),
      displayName: "Base Fare",
      searchable: true,
    },

    {
      displayField: (e) => (
        <>
          {e?.currency} {e?.perKmFee}
        </>
      ),
      displayName: "Fare/Km",
      searchable: true,
    },
    {
      displayField: (e) => (
        <>
          {e?.currency} {e?.minimumFare}
        </>
      ),
      displayName: "Min. Fare",
      searchable: true,
    },

    {
      displayName: "Wait/Min",
      displayField: (e) => (
        <>
          {e?.currency} {e?.perMinuteWaitingFee}
        </>
      ),

      searchable: true,
    },
    // {
    //   displayName: "Commission",
    //   displayField: (e) => (
    //     <>
    //       {e.commissionType == "Fixed" && e?.currency} {e.commissionValue}{" "}
    //       {e.commissionType == "Fixed" ? "" : "%"}
    //     </>
    //   ),
    //   searchable: true,
    // },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link
            to={`/fares/edit/${e._id}`}
            className="mr-2"
            title="Update Rider"
          >
            <i className="fas fa-edit text-primary" />
          </Link>
          {/* <Link className="mr-2" href="">
            <i className="fas fa-eye text-secondary" />
          </Link> */}
          {/* <a
            className="mr-2 text-danger"
            href="javascript:void(0)"
            data--submit="rider36"
            data--confirmation="true"
            data-title="Delete Rider"
            title="Delete Rider"
            data-message="Are you sure you want to delete?"
          >
            <i className="fas fa-trash-alt" />
          </a> */}
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
    </>
  );
};
export default FareTable;
