import React from "react";
import { Link, useLocation } from "react-router-dom";
import CabDocumentsTable from "./CabDocumentsTable";
import CheckPermission from "../../utils/checkPermission";

function CabDocuments() {
  const location = useLocation();
  const check = CheckPermission(location)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Cab Documents List</h4>
              </div>

              {!check  && 
              <Link to="add" className="float-right btn btn-sm btn-primary">
                <i className="fa fa-plus-circle" /> Add Cab Document
              </Link>
              }
            </div>
            <CabDocumentsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CabDocuments;
