import React, { useEffect, useState } from "react";

import WalletsTable from "./CountryTable";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetVehicleCategories } from "../../redux/slices/vehicleCategorySlice";
import { asyncCreateRideFare } from "../../redux/slices/rideFareSlice";
import CountryForm from "./countryForm";
import {
  asyncPostCreateCountry,
  asyncUpdateCountry,
} from "../../redux/slices/countrySlice";

function EditCountry() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const countrries = useSelector((state) => state.countries);
  const { isLoading, list } = countrries;

  const { id } = useParams();

  useEffect(() => {
    const country = list.find((x) => x._id === id);
    if (country) {
      setFormData({
        name: country.name,
        shortName: country.shortName,
        code: country.code,
        currency: country.currency,
      });
    } else {
      navigate("/countries");
    }
  }, []);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      asyncUpdateCountry({
        data: formData,
        id: id,
        callback: () => {
          setFormData({
            name: "",
            shortName: "",
            code: "",
            currency: "",
          });
          navigate("/countries");
        },
      })
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Edit Country </h4>
              </div>
            </div>
            <CountryForm
              submitHandler={submitHandler}
              changeHandler={changeHandler}
              formData={formData}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCountry;
