import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/images/logo-black-b.png";

import sidebarList from "./MenuSidebar";

function Sidebar() {
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { role } = userInfo;

  const toggleSidebar = (e) => {
    document.body.classList.toggle("sidebar-main");
  };

  const scrollSidebar = (scrollAmount) => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTop += scrollAmount;
    }
  };

  // useEffect(() => {
  //   // Add event listeners to control scrolling
  //   const handleWheel = (event) => {
  //     // Adjust the scroll amount as needed (negative for scroll up, positive for scroll down)
  //     const scrollAmount = event.deltaY;

  //     // Prevent the default scroll behavior
  //     event.preventDefault();

  //     // Scroll the sidebar
  //     scrollSidebar(scrollAmount);
  //   };

  //   // Attach the event listener to the sidebar element
  //   if (sidebarRef.current) {
  //     sidebarRef.current.addEventListener("wheel", handleWheel);

  //     // Use a timeout to check for overflow after a slight delay
  //     setTimeout(() => {
  //       setShowScrollbar(
  //         sidebarRef.current.scrollHeight > sidebarRef.current.clientHeight
  //       );
  //     }, 100); // Adjust the timeout duration as needed
  //   }

  //   return () => {
  //     // Remove the event listener when the component unmounts
  //     if (sidebarRef.current) {
  //       sidebarRef.current.removeEventListener("wheel", handleWheel);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const sidebarElement = sidebarRef.current;

    if (sidebarElement) {
      const handleWheel = (event) => {
        const scrollAmount = event.deltaY;
        event.preventDefault();
        scrollSidebar(scrollAmount);
      };

      sidebarElement.addEventListener("wheel", handleWheel);

      setTimeout(() => {
        setShowScrollbar(
          sidebarElement.scrollHeight > sidebarElement.clientHeight
        );
      }, 100);

      return () => {
        if (sidebarElement) {
          sidebarElement.removeEventListener("wheel", handleWheel);
        }
      };
    }
  }, []);

  const logoutHandler = () => {
    dispatch(logout());
  };
  const [openDropdown, setOpenDropdown] = useState(null);

  // Function to handle dropdown click
  const handleDropdownClick = (name) => {
    setOpenDropdown((prev) => (prev === name ? null : name)); // Toggle the dropdown
  };
  return (
    <div className="mm-sidebar sidebar-default">
      <div className="mm-sidebar-logo d-flex align-items-center justify-content-between bg-primary">
        <a href="/" className="header-logo">
          <img
            src={Logo}
            className="img-fluid mode light-img rounded-normal light-logo site_logo_preview"
            alt="logo"
          />
          <img
            src={Logo}
            className="img-fluid mode dark-img rounded-normal darkmode-logo w"
            alt="dark-logo"
          />
        </a>
        <div className="side-menu-bt-sidebar" onClick={toggleSidebar}>
          <i className="fas fa-bars wrapper-menu" />
        </div>
      </div>

      <div
        ref={sidebarRef} // Reference to the sidebar element
        className="data-scrollbar scrollbar-macosx"
        data-scroll={1}
        data-scrollbar="true"
        tabIndex={-1}
        style={{
          overflow: "hidden",
          outline: "none",
          backgroundColor: "#353535",
        }}
      >
        <div className="scroll-content">
          <nav className="mm-sidebar-menu">
            <ul id="mm-sidebar-toggle" className="side-menu">
              {sidebarList
                ?.filter((item) => item.role.includes(role))
                .map((items, index) => {
                  return (
                    <li className="" key={items?.name}>
                      {items?.subItems ? (
                        <>
                          <Link
                            data-toggle="collapse"
                            role="button"
                            aria-expanded={openDropdown === items?.name} // Set expanded state
                            aria-controls="collapseExample"
                            className=""
                            // to={""}
                            // href={`#${items?.name}`}
                            onClick={() => handleDropdownClick(items?.name)} // Handle the dropdown click
                          >
                            {/* <i className={items.icons} /> */}
                            <img
                              src={items.icons}
                              style={{
                                width: "20px",
                                height: "20px",
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                            <span className="ml-3"> {items?.name} </span>
                            <i
                              className={
                                "fas fa-angle-right mm-arrow-right arrow-active"
                              }
                            />
                            <i
                              className={
                                "fas fa-angle-down mm-arrow-right arrow-hover"
                              }
                            />
                          </Link>

                          <ul
                            className={`  submenu collapse ${
                              openDropdown === items?.name ? "show" : ""
                            }`}
                            id={items?.name}
                          >
                            {items?.subItems
                              ?.filter((item) => item.role.includes(role))
                              ?.map((subItems) => (
                                <li
                                  className="ml-4 sidebar-layout"
                                  key={subItems?.name}
                                >
                                  {/* Make sure to add a unique key */}
                                  <NavLink
                                    className="nav-link"
                                    activeclassname=" active"
                                    end
                                    to={subItems.path}
                                  >
                                    <i className={subItems?.icons} />
                                    <span> {subItems?.name} </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </>
                      ) : (
                        <Link className="" to={`${items?.path}`}>
                          {/* <i className={items.icons} /> */}
                          <img
                            src={items.icons}
                            style={{
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                          <span className="ml-3"> {items?.name} </span>
                        </Link>
                      )}
                    </li>
                  );
                })}
              <li className="accordionItem closeIt">
                <NavLink
                  className="nav-item text-lightest f-15 sidebar-text-color"
                  end
                  to=""
                  onClick={logoutHandler}
                >
                  <i className="fas fa-sign-out-alt"></i>
                  <span className="pl-3">Logout</span>
                </NavLink>
              </li>
            </ul>
          </nav>

          {/* <div className="mb-5 pb-2">
            <div className="pt-5 pb-2">
              <div className="scrollbar-track scrollbar-track-x"></div>

              <div
                className={`scrollbar-track ${
                  showScrollbar ? "show" : ""
                } scrollbar-track-y`}
              ></div>
            </div>
          </div> */}
        </div>
        {/* <div
          class="text-center d-flex justify-content-between align-items-center position-fixed bottom-0 bg-primary py-2"
          style={{ width: 260, bottom: 0, zIndex: 10 }}
        >
          <a
            class="nav-item text-lightest f-15 sidebar-text-color accordionItemHeading "
            title="Dashboard"
            href="/track"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="text-lightest"
              id="sidebarToggle"
            >
              <path d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"></path>
            </svg>
          </a>
          <p class="mb-0 pr-4 text-lightest">
            {new Date().getFullYear()} © Cabtify
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
