import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import moment from "moment";
import CurrencySymbol from "../../components/table/CurrencySymbol";

import { useRideRequestsQuery } from "../../redux/services/rideAPI";
import TableV2 from "../../components/tableV2";

const columns = [
  {
    // key: "firstName",
    displayName: "Passenger Name",
    displayField: (e) => {
      return (
        <>
          {e.passenger?.firstName} {e.passenger?.lastName}
        </>
      );
    },
    searchable: true,
  },
  {
    // key: "firstName",
    displayName: "Driver Name",
    displayField: (e) => {
      return (
        <>
          {e.driver?.firstName} {e.driver?.lastName}
        </>
      );
    },
    searchable: true,
  },
  {
    // key: "phone",
    displayName: "Est. Pickup",
    displayField: (e) => {
      return <>{e?.pickup?.address?.formattedAddress}</>;
    },
    searchable: true,
  },
  {
    // key: "phone",
    displayName: "Est. Dropoff",
    displayField: (e) => {
      return <>{e?.dropoff?.address?.formattedAddress}</>;
    },
    searchable: true,
  },
  {
    // key: "phone",
    displayName: "Est. Distance/Duration",
    displayField: (e) => {
      return (
        <>
          {e.distance} / {e.duration}
        </>
      );
    },
    searchable: true,
  },
  {
    displayName: "Est. Fare",
    displayField: (e) => {
      return (
        <>
          <CurrencySymbol currencyCode={e?.fareDetails?.currency || "USD"} />{" "}
          {e.fareDetails?.totalFare}
        </>
      );
    },
  },
  {
    displayName: "Booking Time",
    displayField: (e) => {
      return <>{moment(e.bookingTime).format("hh:mm a @ DD MMMM, YYYY")}</>;
    },
  },
  //   {
  //     // key: "verified",
  //     displayName: "Verified",
  //     displayField: (e) => {
  //       return <>{e.verified ? "Yes" : "No"}</>;
  //     },
  //     searchable: true,
  //   },

  {
    key: "status",
    displayName: "Status",
  },
  {
    displayName: "Action",
    key: "",
    displayField: (e) => <Link to="">View</Link>,
    searchable: true,
  },
];
const RideRequestsTable = () => {
  const auth = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const {
    data: ridesRequest,
    isLoading,
    // isError,
  } = useRideRequestsQuery({
    page: currentPage,
    limit: itemsPerPage,
    country: auth.country,
  });

  return (
    <>
      <TableV2
        dataSource={ridesRequest?.results || []}
        isLoading={isLoading}
        columns={columns}
        totalEntries={ridesRequest?.totalResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={ridesRequest?.totalPages}
      />
    </>
  );
};
export default RideRequestsTable;
