import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncSignIn = createAsyncThunk(
  "signIn",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPublic.post("/users/login", obj);
      toast.success("Logged in successfully!");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateProfile = createAsyncThunk(
  "UpdateProfile",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;
    try {
      const response = await axiosPrivate.patch("/users/update-profile", obj);
      callback && callback();
      toast.success("Profile details updated successfully!");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncChangePassword = createAsyncThunk(
  "ChangePassword",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;
    try {
      const response = await axiosPrivate.put("/users/change-password", obj);
      toast.success("Password updated Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    userInfo: localStorage.getItem("cabtifyUserInfo")
      ? JSON.parse(localStorage.getItem("cabtifyUserInfo"))
      : null,
    tokens: localStorage.getItem("cabtifyTokens")
      ? JSON.parse(localStorage.getItem("cabtifyTokens"))
      : null,
    // userInfo: null,
    isError: false,
    country: localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country"))
      : "",
  },
  extraReducers: (builder) => {
    // Cases for signin
    builder.addCase(asyncSignIn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncSignIn.fulfilled, (state, action) => {
      localStorage.setItem(
        "cabtifyTokens",
        JSON.stringify(action.payload.tokens)
      );
      localStorage.setItem(
        "cabtifyUserInfo",
        JSON.stringify(action.payload.user)
      );
      state.isLoading = false;
      state.userInfo = action.payload.user;
      state.tokens = action.payload.tokens;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncSignIn.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    //Cases for update profile
    builder.addCase(asyncUpdateProfile.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncUpdateProfile.fulfilled, (state, action) => {
      // localStorage.setItem(
      //   "cabtifyTokens",
      //   JSON.stringify(action.payload.tokens)
      // );
      localStorage.setItem("cabtifyUserInfo", JSON.stringify(action.payload));
      state.isLoading = false;
      state.userInfo = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncUpdateProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    //Cases for change password
    builder.addCase(asyncChangePassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncChangePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncChangePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
  reducers: {
    logout(state) {
      localStorage.removeItem("cabtifyTokens");
      localStorage.removeItem("cabtifyInfo");
      localStorage.removeItem("country");
      state.userInfo = null;
      state.tokens = null;
    },

    countryAllData: (state, action) => {
      localStorage.setItem("country", JSON.stringify(action.payload));
      state.country = action.payload;
    },
  },
});

export const { logout, countryAllData } = authSlice.actions;
export default authSlice.reducer;
