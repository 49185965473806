import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const applicationAPI = createApi({
  reducerPath: "applicationAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchDriver", "refetchDriverApplication"],
  endpoints: (builder) => ({
    getApplication: builder.mutation({
      query: ({ id, page = 1, limit = 10 }) => ({
        url: `/drivers${id}`,
        method: "GET",
        params: { page, limit },
      }),
      providesTags: ["refetchDriver"],
    }),

    getDriverApplicationDetails: builder.query({
      query: (id) => ({
        url: `/drivers/applications/${id}`,
        method: "GET",
      }),
      providesTags: ["refetchDriverApplication"],
    }),

    getVerifyDocument: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/documents/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["refetchDriverApplication"],
    }),

    getVerifyVehicles: builder.query({
      query: () => ({
        url: `/vehicleCategories`,
        method: "GET",
      }),
      invalidatesTags: ["refetchDriverApplication"],
    }),

    getVerifyVehiclesUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `vehicles/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["refetchDriverApplication"],
    }),

    getVerifyDriverUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `drivers/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["refetchDriverApplication"],
    }),
  }),
});

export const {
  useGetApplicationMutation,
  useGetDriverApplicationDetailsQuery,
  useGetVerifyDocumentMutation,
  useGetVerifyVehiclesQuery,
  useGetVerifyVehiclesUpdateMutation,
  useGetVerifyDriverUpdateMutation,
} = applicationAPI;
