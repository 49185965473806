import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AllowCompoment = ({ allow, children }) => {
  // Access the auth state from Redux store
  const auth = useSelector((state) => state.auth);

  // Check if the user has the required permission
  const hasPermission = allow.includes(auth.userInfo.role);
  if (hasPermission) {
    return children;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

export default AllowCompoment;
