import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const driversAPI = createApi({
  reducerPath: "driversAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchDrivers"],
  endpoints: (builder) => ({
 

    driverEarnings: builder.query({
      query: ({ page = 1, limit = 10, query, country }) => ({
        url: `/drivers/earnings`,
        method: "GET",
        params: {
          page,
          limit,
          country: country ? country : undefined,
          query: query ? query : undefined,
        },
      }),
      invalidatesTags: ["refetchDrivers"],
    }),
  }),
});

export const {
  
  useDriverEarningsQuery,
} = driversAPI;
