import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetDailySattlements = createAsyncThunk(
  "asyncGetDailySattlements",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/dailySattlements${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncClearSattlementRequests = createAsyncThunk(
  "clearSattlementRequests",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/dailySattlements/clearSattlementRequests${
          country ? `?country=${country}` : ""
        }`
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateCommissionTopupRequestByAdmin = createAsyncThunk(
  "asyncUpdateCommissionTopupRequestByAdmin",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { id, data, callback, toast } = payload;
    try {
      const response = await axiosPrivate.post(
        `/dailySattlements/updateCommissionTopupRequestByAdmin/${id}`,
        data
      );
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

const sattlementsSlice = createSlice({
  name: "sattlements",
  initialState: {
    isLoading: false,

    list: [],
    requestList: [],
    isError: false,
    add: {},
    update: {},
    document: { isLoading: false, list: [], isError: false },
    addDocument: { isLoading: false, data: {}, isError: false },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetDailySattlements.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetDailySattlements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetDailySattlements.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    builder.addCase(asyncClearSattlementRequests.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncClearSattlementRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      state.requestList = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncClearSattlementRequests.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    builder.addCase(
      asyncUpdateCommissionTopupRequestByAdmin.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.requestList = state.requestList.map((x) =>
          x._id === action.payload._id ? action.payload : x
        );
        state.isError = false;
        state.message = null;
      }
    );
  },
});

// export const {} = sattlementsSlice.actions;
export default sattlementsSlice.reducer;
