import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";

function FlatFeeForm({ submitHandler, formData, changeHandler, isLoading }) {
  const vehicleCategories = useSelector((state) => state.vehicleCategories);

  const userInfo = useSelector((state) => state.auth.userInfo);
  const country = userInfo.country;

  console.log("formData=>", formData);

  return (
    <div className="d-flex flex-column w-tables rounded mt-3 ">
      <div
        id="leads-table_wrapper"
        className="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <form onSubmit={submitHandler} className="p-3">
          <div className="row">
            <>
              <div className="col-md-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    for="service_package"
                  >
                    Select Category
                  </label>
                  <select
                    className="form-control height-35 f-14"
                    id="service_package"
                    name="vehicleCategory"
                    onChange={changeHandler}
                    value={formData.vehicleCategory}
                    required
                  >
                    <option>Select category</option>
                    {vehicleCategories.list.map((x) => (
                      <option value={x._id} key={x._id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group my-3">
                  <label className="f-14 text-dark-grey mb-12" for="fixed_type">
                    Fee Type
                  </label>
                  <select
                    className="form-control height-35 f-14"
                    id="fixed_type"
                    name="type"
                    onChange={changeHandler}
                    value={formData.type}
                    required
                  >
                    <option value="">Select type</option>
                    <option value="percentage">Percentage</option>
                    <option value="flat_fee">Flat Fee</option>
                    <option value="flat_fee_plus_commission">
                      Flat Fee + Commission
                    </option>
                  </select>
                </div>
              </div>
              {formData.type && formData.type !== "" && (
                <>
                  {formData.type !== "percentage" ? (
                    <>
                      {/* <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            for="flat_fee_interval"
                          >
                            Fee Interval
                          </label>
                          <select
                            className="form-control height-35 f-14"
                            id="flat_fee_interval"
                            name="feeInterval"
                            onChange={changeHandler}
                            value={formData.feeInterval}
                            required
                          >
                            <option>Select type</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            for="flat_Fee_value"
                          >
                            Flat Fee Value ( {country.currency} )
                          </label>
                          <input
                            type="number"
                            step="0.1"
                            min={0}
                            className="form-control height-35 f-14"
                            id="flat_Fee_value"
                            name="value"
                            onChange={changeHandler}
                            value={formData.value}
                            required
                          />
                        </div>
                      </div>
                      {formData.type === "flat_fee_plus_commission" && (
                        <div className="col-md-6">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark-grey mb-12"
                              for="flat_Fee_Commission_value"
                            >
                              Flat Fee Commission Value ( {country.currency} )
                            </label>
                            <input
                              type="number"
                              step="0.1"
                              min={0}
                              className="form-control height-35 f-14"
                              id="flat_Fee_Commission_value"
                              name="commission"
                              onChange={changeHandler}
                              value={formData.commission}
                              required
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark-grey mb-12"
                          for="flat_Fee_value"
                        >
                          Value in Percentage ( % )
                        </label>
                        <input
                          type="number"
                          step="0.1"
                          min={0}
                          className="form-control height-35 f-14"
                          id="flat_Fee_value"
                          name="value"
                          onChange={changeHandler}
                          value={formData.value}
                          required
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
            <div className="col-lg-12">
              <div
                className="form-group my-3"
                style={{ marginTop: "1.5em !important" }}
              >
                <CustomButton
                  className="btn-primary rounded f-14 p-2 mr-3"
                  isLoading={isLoading}
                  title="Submit"
                  type="submit"
                />
                {/* <button
                  type="submit"                                    
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Submit
                </button> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FlatFeeForm;
