import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncUpdateMobileMoneySettings,
  asyncUpdateWalletsSettings,
} from "../../redux/slices/settingSlice";
import CustomButton from "../../components/CustomButton";

function WalletSettings() {
  const [generalValue, SetGeneralValue] = useState();
  const setting = useSelector((state) => state.setting);

  const { data, isLoading } = setting;
  const { walletSettings } = data;

  const dispatch = useDispatch();

  useEffect(() => {
    if (walletSettings) {
      SetGeneralValue({
        defaultBalance: walletSettings.defaultBalance,
        maximumAmountToAdd: walletSettings.maximumAmountToAdd,
        minimumAmountToAdd: walletSettings.minimumAmountToAdd,
        minimumAmountToBookRide: walletSettings.minimumAmountToBookRide,
      });
    }
  }, [walletSettings]);
       
  const mobileMoneyValueHandler = (event) => {
    const { name, value } = event.target;
    SetGeneralValue({ ...generalValue, [name]: value });
  };

  const submitHandler = () => {
    dispatch(
      asyncUpdateWalletsSettings({
        obj: generalValue,
      })
    );
  };
  return (
    <div className="paste_here active">
      <input
        name="_token"
        type="hidden"                                                                          
        value="M6N5LrwVhmXv4LVQmEYmqAZm7Pkzyfk52dn84Lb5"
      />
      <input
        className="form-control"
        name="page"
        type="hidden"
        value="wallet-setting"
      />

      <div className="col-md-12 mt-20">
        <div className="row">
          <div className="col-md-6 form-group">                       
            <label for="min_amount_to_add" className="form-control-label">
              Wallet Minimum Amount To Add
            </label>
            <input
              placeholder="Wallet Minimum Amount To Add"
              min="0"
              step="any"
              className="form-control"
              name="defaultBalance"
              type="number"
              value={generalValue ? generalValue.defaultBalance : ""}
              id="min_amount_to_add"
              onChange={mobileMoneyValueHandler}
            />                                                                        
          </div>
          <div className="col-md-6 form-group">
            <label for="max_amount_to_add" className="form-control-label">                
              Wallet Maximum Amount To Add
            </label>
            <input
              placeholder="Wallet Maximum Amount To Add"
              min="0"
              step="any"   
              className="form-control"
              name="maximumAmountToAdd"
              type="number"
              value={generalValue ? generalValue.maximumAmountToAdd : " "}                                                   
              id="max_amount_to_add"
              onChange={mobileMoneyValueHandler}                
            />
          </div>
          <div className="col-md-6 form-group">
            <label for="min_amount_to_get_ride" className="form-control-label">
              Wallet Minimum Amount to Book a Ride
            </label>
            <input
              placeholder="Driver Wallet Minimum Amount to Get an Ride"
              step="any"               
              className="form-control"
              name="minimumAmountToAdd"
              type="number"
              value={generalValue ? generalValue.minimumAmountToAdd : " "}
              id="min_amount_to_get_ride"
              onChange={mobileMoneyValueHandler}
            />
          </div>
          <div className="col-md-6 form-group">
            <label for="preset_topup_amount" className="form-control-label">
              Initial Wallet Balance{" "}        
              <span
                data-toggle="tooltip"
                data-placement="right"
                title="Enter preset amounts riders can select to top-up their wallet; Leave blank to hide presets. Separate each amount with the pipe | character. Example 10|50|100|500"
              >
                {/* <i className="fas fa-question-circle"></i> */}
              </span>
            </label>
            <input
              placeholder="10|50|100|500"
              className="form-control"                                 
              name="minimumAmountToBookRide"
              type="text"
              id="preset_topup_amount"
              value={generalValue ? generalValue.minimumAmountToBookRide : ""}
              onChange={mobileMoneyValueHandler}
            />
          </div>
        </div>
      </div>
      <CustomButton                
        className="btn btn-md btn-primary float-md-right"
        type="submit"
        value="Save"           
        onClick={submitHandler}
        title="Update"
        isLoading={isLoading}
      />
      <input type="hidden" name="active_tab" value="" />
    </div> 
  );
}

export default WalletSettings;
