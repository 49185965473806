import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const userAPI = createApi({
  reducerPath: "userAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchUsers"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page = 1, limit = 10, country }) => ({
        url: `/v2/users${country ? `?country=${country}` : ""}`,
        method: "GET",
        params: { page, limit },
      }),
      providesTags: ["refetchUsers"],
    }),
    updateUsers: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/users/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["refetchUsers"],
    }),
    createUsers: builder.mutation({
      query: ({ payload }) => ({
        url: `/users`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["refetchUsers"],
    }),
    deleteUsers: builder.mutation({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["refetchUsers"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUsersMutation,
  useDeleteUsersMutation,
  useCreateUsersMutation,
} = userAPI;
