import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetDrivers } from "../../redux/slices/driverSlice";
import { Link, useLocation } from "react-router-dom";
import { asyncGetCabs } from "../../redux/slices/cabSlice";
import CheckPermission from "../../utils/checkPermission";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const CabsTable = () => {
  const auth = useSelector((state) => state.auth);
  const cabs = useSelector((state) => state.cabs);

  const { isLoading, list } = cabs;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const location = useLocation();

  const [modal, setModal] = useState({ open: false, data: null });

  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(asyncGetCabs());
  }, [auth.country]);

  const columns = [
    {
      key: "plateNumber",
      displayName: "Plate Number",
      searchable: true,
    },
    {
      key: "make",
      displayName: "Make",
      searchable: true,
    },
    {
      key: "model",
      displayName: "Model",
      searchable: true,
    },
    {
      key: "year",
      displayName: "Year",
      searchable: true,
    },
    {
      key: "color",
      displayName: "Color",
      searchable: true,
    },
    {
      key: "category",
      displayName: "Category",
      displayField: (e) => <>{e.category ? e.category.name : "N/A"}</>,
      searchable: true,
    },
    // {
    //   displayName: "Images",
    //   displayField: (e) => {
    //     return <Link className="">View</Link>;
    //   },
    // },
    {
      // key: "status",
      displayField: (e) => (
        <>
          {e.verification === "Pending" ? (
            <span className="text-capitalize badge bg-primary">
              {e.verification}
            </span>
          ) : e.verification === "Verified" ? (
            <span className="text-capitalize badge bg-success">
              {e.verification}
            </span>
          ) : e.verification === "Rejected" ? (
            <span className="text-capitalize badge bg-danger">
              {e.verification}
            </span>
          ) : (
            <></>
          )}
        </>
      ),
      displayName: "Verification",
    },
    {
      // key: "status",
      displayName: "Status",
      displayField: (e) => (
        <>
          {e.status === "Active" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link className="mr-2" to={`edit/${e._id}`} title="Update Driver">
            <i className="fas fa-edit text-primary" />
          </Link>
          {/* <Link className="mr-2" to={`edit/${e._id}`}>
            <i className="fas fa-eye text-secondary" />
          </Link> */}
          {/* <Link className="mr-2" to={``}>
            <i className="fas fa-trash-alt text-danger" />
          </Link> */}
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};
export default CabsTable;
