import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import { asyncCreateDocumentRequirement } from "../../redux/slices/documentSlice";
import { useNavigate } from "react-router-dom";

function AddDriverDocument() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const documents = useSelector((state) => state.documents);
  const { isLoading } = documents;

  const [formData, setFormData] = useState({});

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      asyncCreateDocumentRequirement({
        data: formData,
        callback: () => {
          navigate("/documents/required");
        },
      })
    );
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Add Document Requirement</h4>
                </div>
              </div>
              <div className="card-body">
                <div className="new-user-info">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="name" className="form-control-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="Name"
                        className="form-control"
                        required=""
                        name="name"
                        type="text"
                        id="name"
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label
                        htmlFor="documentFor"
                        className="form-control-label"
                      >
                        Document For <span className="text-danger">*</span>
                      </label>
                      <select
                        placeholder="Name"
                        className="form-control"
                        required=""
                        name="type"
                        type="text"
                        id="documentFor"
                        onChange={changeHandler}
                      >
                        <option>Please select type</option>
                        <option value="Driver">Driver</option>
                        <option value="Vehicle">Vehicle</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label
                        htmlFor="is_required"
                        className="form-control-label"
                      >
                        Has Back Side
                      </label>
                      <select
                        className="form-control"
                        required=""
                        id="is_required"
                        name="hasBackSide"
                        data-select2-id="is_required"
                        tabIndex={-1}
                        aria-hidden="true"
                        onChange={changeHandler}
                      >
                        <option value={false} data-select2-id={2}>
                          No
                        </option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label
                        htmlFor="has_expiry_date"
                        className="form-control-label"
                      >
                        Has Expiry Date
                      </label>
                      <select
                        className="form-control"
                        required=""
                        id="has_expiry_date"
                        name="hasExpiry"
                        data-select2-id="has_expiry_date"
                        tabIndex={-1}
                        aria-hidden="true"
                        onChange={changeHandler}
                      >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="status" className="form-control-label">
                        Status
                      </label>
                      <select
                        className="form-control"
                        required=""
                        id="status"
                        name="status"
                        data-select2-id="status"
                        tabIndex={-1}
                        aria-hidden="true"
                        onChange={changeHandler}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                  <CustomButton
                    className="btn btn-md btn-primary float-right"
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddDriverDocument;
