import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetDriversDocuments } from "../../redux/slices/driverSlice";
import { Link } from "react-router-dom";
import ImagePopup from "../../components/modals/imagePopup";
import moment from "moment";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const DriverDocumentsTable = () => {
  const auth = useSelector((state) => state.auth);
  const drivers = useSelector((state) => state.drivers.documents);

  const { isLoading, list } = drivers;

  const [selectedDocument, setSelectedDocument] = useState(null);

  const dispatch = useDispatch();

  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(asyncGetDriversDocuments());
  }, [dispatch, auth.country]);

  const columns = [
    {
      displayName: "Driver Name",
      displayField: (e) =>
        e?.driver ? (
          <>
            {e?.driver?.firstName} {e?.driver?.lastName}
          </>
        ) : (
          <>N/A</>
        ),
      searchable: true,
    },

    {
      displayName: "Document Name",
      displayField: (e) => <>{e?.documentType?.name}</>,
      searchable: true,
    },

    {
      displayName: "Has Back Side",
      displayField: (e) => (
        <>
          {e?.documentType?.hasBackSide ? (
            <span className="text-capitalize badge bg-primary">Yes</span>
          ) : (
            <span className="text-capitalize badge bg-danger">No</span>
          )}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Has Expiry",
      displayField: (e) => (
        <>
          {e?.documentType?.hasExpiry ? (
            <span className="text-capitalize badge bg-primary">Yes</span>
          ) : (
            <span className="text-capitalize badge bg-danger">No</span>
          )}
        </>
      ),
      searchable: true,
    },
    {
      displayName: " Expiry Date",
      displayField: (e) => (
        <>
          {/* {console.log(e?.expirationDate, "Date")} */}
          {e?.expirationDate && e?.expirationDate !== ""
            ? moment(e?.expirationDate, "DD/MM/YYYY").format("MM-DD-YYYY")
            : "N/A"}
        </>
      ),
      searchable: true,
    },

    {
      displayName: "Status",
      displayField: (e) => (
        <>
          {e.status === "Pending" ? (
            <span className="text-capitalize badge bg-primary">{e.status}</span>
          ) : e.status === "Verified" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
    },

    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link
            className="mr-2"
            to={`/documents/edit/${e._id}`}
            title="Update Driver"
          >
            <i className="fas fa-edit text-primary" />
          </Link>
          <Link className="mr-2" to={``} onClick={() => setSelectedDocument(e)}>
            <i className="fas fa-eye text-secondary" />
          </Link>
        </div>
      ),
      searchable: true,
    },
  ];
  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {selectedDocument && (
        <ImagePopup
          show={selectedDocument}
          data={selectedDocument}
          handleClose={() => setSelectedDocument(null)}
        />
      )}
    </>
  );
};
export default DriverDocumentsTable;
