import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FaqsTable from "./FaqsTable";
import CustomModal from "../../components/modals/CustomModal";
import FaqsQuestion from "./faqsForm";
import {
  asyncGetFaqsQuestion,
  asyncPostFaqsQuestion,
} from "../../redux/slices/faqsSlice";
import { useDispatch } from "react-redux";
import FaqsForm from "./faqsForm";

function Faqs() {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const faqsQuestionSubmit = (event) => {
    event.preventDefault();

    dispatch(
      asyncPostFaqsQuestion({
        payload: {
          ...formData,
        },
        callback: () => {
          setOpenModal(false);
          dispatch(asyncGetFaqsQuestion());
          setFormData({
            subject: "",
            title: "",
            description: "",
            for: "",
          });
        },
      })
    );
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-block card-stretch card-height">
              {" "}
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title mb-0">Faqs List</h4>
                </div>
                <div
                  className="float-right btn btn-sm btn-primary"
                  onClick={() => setOpenModal(!openModal)}
                >
                  <i className="fa fa-plus-circle" /> Add Questions
                </div>{" "}
              </div>
              <FaqsTable show={openModal} setOpenModal={setOpenModal} />
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <CustomModal
          show={openModal}
          setOpenModal={setOpenModal}
          title={"Faqs"}
        >
          <FaqsForm
            setOpenModal={setOpenModal}
            submit={faqsQuestionSubmit}
            handleInputChange={handleInputChange}
            formData={formData}
          />
        </CustomModal>
      )}
    </>
  );
}

export default Faqs;
