import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import Rating from "react-rating";

function RatingComponent({ iconSize, rating, handleRatingChange, readOnly }) {
  return (
    <Rating
      emptySymbol={
        <AiOutlineStar size={iconSize} color="#FFD700" readonly={readOnly} />
      }
      fullSymbol={
        <AiFillStar size={iconSize} color="#FFD700" readonly={readOnly} />
      }
      readonly={readOnly}
      initialRating={rating}
      onClick={handleRatingChange}
    />
  );
}

export default RatingComponent;
