import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const flatFeeApi = createApi({
  reducerPath: "flatFeeAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchFlatFee"],
  endpoints: (builder) => ({
    getFlatFees: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: `/fees`,
        method: "GET",
        params: { page, limit },
      }),
      providesTags: ["refetchFlatFee"],
    }),

    getFlatFeeById: builder.query({
      query: ({ id }) => ({
        url: `/fees/${id}`,
        method: "GET",
      }),
      // providesTags: ["refetchFlatFee"],
    }),

    createFlatFee: builder.mutation({
      query: ({ payload }) => ({
        url: `/fees`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["refetchFlatFee"],
    }),

    updateFlatFee: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/fees/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["refetchFlatFee"],
    }),
  }),
});

export const {
  useGetFlatFeesQuery,
  useGetFlatFeeByIdQuery,
  useCreateFlatFeeMutation,
  useUpdateFlatFeeMutation,
} = flatFeeApi;
