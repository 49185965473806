import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const rideAPI = createApi({
  reducerPath: "rideAPI",
  baseQuery: baseQueryWithAuth,
  // tagTypes: ["refetchRides", "rideRequests"],
  endpoints: (builder) => ({
    getRides: builder.query({
      query: ({ page = 1, limit = 10, country }) => ({
        url: `/v2/rides${country ? `?country=${country}` : ""}`,
        method: "GET",
        params: { page, limit },
      }),
      // providesTags: ["refetchRides"],
    }),

    rideRequests: builder.query({
      query: ({ page = 1, limit = 10, country }) => ({
        url: `/v2/rideRequests${country ? `?country=${country}` : ""}`,
        method: "GET",
        params: { page, limit },
      }),
      // providesTags: ["rideRequests"],
    }),
  }),
});

export const { useGetRidesQuery, useRideRequestsQuery } = rideAPI;
