import React from "react";
import { Button, Modal } from "react-bootstrap";

function VerificationDocument({
  verifyDriverDocumentModal,
  verifyDoucmenthandleClose,
  verifyDocumentValueHandler,
  verifyDocumentValue,
  submitHandler,
  minDate,
}) {

  return (
    <Modal
      className=""
      show={verifyDriverDocumentModal}
      onHide={verifyDoucmenthandleClose}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title> Verify Document </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h6 className="my-2"> Verification</h6>
          <select
            className="form-control"
            onChange={verifyDocumentValueHandler}
            name="status"
          >
            <option>Open this select menu</option>
            <option value="Pending">Pending</option>
            <option value="Verified">Verified</option>
            <option value="Rejected">Rejected</option>
          </select>

          {verifyDocumentValue.status === "Rejected" ? (
            <div className="mt-3 ">
              <h6 className="my-2"> Reason</h6>
              <textarea
                className="form-control"
                as="textarea"
                rows={3}
                name="rejectReason"
                onChange={verifyDocumentValueHandler}
              />
            </div>
          ) : null}
          {verifyDriverDocumentModal?.hasExpiry ? (
            <div className="bg-black my-2">
              <h6 className="my-2">Expiry date </h6>
              <input
                className="form-control"
                onChange={verifyDocumentValueHandler}
                type="date"
                defaultValue={
                  verifyDriverDocumentModal?.expirationDate?.split("T")[0]
                }
                name="expirationDate"
                min={minDate}
              />
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={verifyDoucmenthandleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerificationDocument;
