import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetRequiredDocuments = createAsyncThunk(
  "asyncGetDocuments",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/requiredDocuments${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetSubmittedDocumentsView = createAsyncThunk(
  "asyncGetSubmittedDocumentsView",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { id } = payload;

    try {
      const response = await axiosPrivate.get(
        `/documents/getDriverDetailsAndDocuments/${id}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetSubmittedDocuments = createAsyncThunk(
  "asyncGetSubmittedDocuments",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/documents/getAggregatedResults${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateDocumentRequirement = createAsyncThunk(
  "asyncCreateDocumentRequirement",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;
    try {
      const response = await axiosPrivate.post("/requiredDocuments", data);
      toast.success("Document requirement added successfully!");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateDocumentRequirement = createAsyncThunk(
  "asyncCreateDocumentRequirement",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback, id } = payload;
    try {
      const response = await axiosPrivate.patch(
        `/requiredDocuments/${id}`,
        data
      );
      toast.success("Document requirement added successfully!");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const cabSlice = createSlice({
  name: "documents",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    submittedDocuments: { isLoading: false, list: [], isError: false },
    SubmittedDocumentsView: { isLoading: false, data: {}, isError: false },
  },
  extraReducers: (builder) => {
    // Cases for Get Cabs
    builder.addCase(asyncGetRequiredDocuments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRequiredDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRequiredDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // asyncGetSubmittedDocumentsView star
    builder.addCase(asyncGetSubmittedDocumentsView.pending, (state, action) => {
      state.SubmittedDocumentsView.isLoading = true;
    });
    builder.addCase(
      asyncGetSubmittedDocumentsView.fulfilled,
      (state, action) => {
        state.SubmittedDocumentsView.isLoading = false;
        state.SubmittedDocumentsView.data = action.payload;
        state.SubmittedDocumentsView.isError = false;
        state.SubmittedDocumentsView.message = null;
      }
    );
    builder.addCase(
      asyncGetSubmittedDocumentsView.rejected,
      (state, action) => {
        state.SubmittedDocumentsView.isLoading = false;
        state.SubmittedDocumentsView.message = action.payload;
        state.SubmittedDocumentsView.isError = true;
      }
    );

    // asyncGetSubmittedDocumentsView end

    // asyncGetSubmittedDocuments
    builder.addCase(asyncGetSubmittedDocuments.pending, (state, action) => {
      state.submittedDocuments.isLoading = true;
    });
    builder.addCase(asyncGetSubmittedDocuments.fulfilled, (state, action) => {
      state.submittedDocuments.isLoading = false;
      state.submittedDocuments.list = action.payload;
      state.submittedDocuments.isError = false;
      state.submittedDocuments.message = null;
    });
    builder.addCase(asyncGetSubmittedDocuments.rejected, (state, action) => {
      state.submittedDocuments.isLoading = false;
      state.submittedDocuments.message = action.payload;
      state.submittedDocuments.isError = true;
    });

    // Cases for Get Cabs documents
    builder.addCase(asyncCreateDocumentRequirement.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      asyncCreateDocumentRequirement.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.list = [action.payload, ...state.list];
        state.isError = false;
        state.message = null;
      }
    );
    builder.addCase(
      asyncCreateDocumentRequirement.rejected,
      (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isError = true;
      }
    );
  },
  reducers: {},
});

// export const {} = cabSlice.actions;
export default cabSlice.reducer;
