import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useGetVerifyVehiclesQuery } from "../../redux/services/applicationAPI";

function VerifyVehicleModal({ data, open, onChange, onClose, submitHandler }) {
  const { data: vehicleCategoriesData } = useGetVerifyVehiclesQuery();

  return (
    <Modal className="" show={open} onHide={onClose} animation={false}>
      <Modal.Header>
        <Modal.Title> Verify Vehicle Details </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h6 className="my-2"> Vehicle category </h6>
          <select
            className="form-control mb-3"
            onChange={onChange}
            name="category"
          >
            {vehicleCategoriesData?.map((items, index) => (
              <>
                <option value={items._id}> {items.name} </option>
              </>
            ))}
          </select>

          <div>
            <h6 className="my-2"> Verification </h6>
            <select
              className="form-control"
              onChange={onChange}
              name="verification"
            >
              <option>Open this select menu</option>
              <option value="Verified">Verified</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>

          {data?.verification === "Rejected" ? (
            <div className="mt-3 ">
              <h6 className="my-2">Reason</h6>
              <textarea
                className="form-control"
                as="textarea"
                rows={3}
                name="rejectReason"
                onChange={onchange}
              />
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerifyVehicleModal;
