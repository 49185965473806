import React, { useEffect, useState } from "react";

import { useDropzone } from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";

import { uploadSingleFile } from "../services/upload";

import { notifyFailure } from "./toast";

const DropzoneUpload = ({
  name,
  directory,
  onChange,
  isEnabled,
  disableReason,
  setUpLoading,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(60);
  const [uploadStatus, setUploadStatus] = useState("");
  // const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  // const [selectedImage, setSelectedImage] = useState(null);

  const onDrop = async (acceptedFiles) => {
    if (!isEnabled) {
      notifyFailure(disableReason);
      return; // Prevent upload if it's disabled
    }
    try {
      const selectedFile = acceptedFiles[0];

      setUploading(true);

      // setSelectedImage(URL.createObjectURL(selectedFile));

      const fileUrl = await uploadSingleFile({
        file: selectedFile,
        directory: directory,
        progressHandler: (progress) => {
          setUploadProgress(progress);
          setUploadStatus(`Uploading: ${progress}%`);
        },
        uploadCompleteHandler,
      });

      // setUploadedImageUrl(fileUrl);
      onChange({
        target: {
          name: name,
          value: fileUrl,
        },
      });
      // setUploading(true);

      // const formData = new FormData();
      // formData.append("image", selectedFile);

      // const response = await axios.post("/upload-url", formData, {
      //   onUploadProgress: (progressEvent) => {
      //     const progress = Math.round(
      //       (progressEvent.loaded / progressEvent.total) * 100
      //     );
      //     setUploadProgress(progress);
      //     setUploadStatus(`Uploading: ${progress}%`);
      //   },
      // });

      // setUploading(false);
      // setUploadStatus("Upload complete");
      // setUploadedImageUrl(response.data.imageUrl);
    } catch (error) {
      setUploading(false);
      setUploadStatus("Upload failed");
      console.error(error);
    }
  };

  useEffect(() => {
    if (setUpLoading) setUpLoading(uploading);
  }, [uploading, setUpLoading]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*", // Specify accepted file types (images)
  });

  const uploadCompleteHandler = () => {
    setUploadProgress(100);
    setUploading(false);
    setUploadStatus("Upload complete");
  };
  return (
    <div
      className="form-control container py-0 "
      style={{ height: "fit-content" }}
    >
      <div
        {...getRootProps()}
        className={`dropzone text-center p-4  d-flex flex-column align-items-center  justify-content-between cursor-pointer ${
          isDragActive ? "" : ""
        }`}
      >
        <div></div>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="mb-0 fs-2">Drop the image here ...</p>
        ) : (
          <div className="upload-icon">
            <i className="fas fa-cloud-upload-alt fs-6"></i>
            <p className="mb-0 fs-2">
              Drag &amp; drop an image here, or click to select
            </p>
          </div>
        )}
        {uploadStatus !== "" ? (
          <div className="mt-3 w-100">
            <ProgressBar
              animated={uploading}
              now={uploadProgress}
              label={uploadStatus}
            />
          </div>
        ) : (
          <div></div>
        )}

        {/* {uploadedImageUrl && (
        <div className="mt-4">
          <i className="fa fa-check-circle text-success" aria-hidden="true"></i>

          <p className="mb-0">Uploaded Image:</p>
          <img src={uploadedImageUrl} alt="Uploaded" className="img-fluid" />
        </div>
      )} */}
      </div>
      {/* {selectedImage && (
          <div className="mt-3">
            <p className="mb-0">Selected Image:</p>
            <img
              src={selectedImage}
              alt="Selected"
              className="img-fluid"
              height={"50px"}
            />
          </div>
        )} */}

      {/* {uploadStatus !== "" ? (
        <div className="mt-3">
          <ProgressBar animated now={uploadProgress} label={uploadStatus} />
        </div>
      ) : (
        <></>
      )} */}

      {uploadStatus === "Upload failed" && (
        <div className="mt-4">
          <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>

          <p className="text-danger mb-0">Upload failed. Please try again.</p>
        </div>
      )}
    </div>
  );
};

export default DropzoneUpload;
