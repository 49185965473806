import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GOOGLE_API_KEY } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function TrackingMap({ driversList, selectedDriver }) {
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [center, setCenter] = useState({});
  const [zoom, setZoom] = useState(12);
  const drivers = useSelector((state) => state.drivers);
  const { driverLocations } = drivers;
  const { list, isLoading } = driverLocations;

  const successCallback = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;

    setCenter({
      lat: lat,
      lng: lon,
    });
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  const dispatch = useDispatch();

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  //   const locationHandler = () => {
  //     dispatch(asyncGetDriversLocation());
  //   };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    // dispatch(asyncGetDriversLocation());
  }, [dispatch]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const closeInfoWindow = () => {
    setSelectedMarker(null);
  };

  useEffect(() => {
    if (selectedDriver) {
      setZoom(18);
      setCenter({
        lat: selectedDriver.location.coordinates[1],
        lng: selectedDriver.location.coordinates[0],
      });
    }
  }, [selectedDriver]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    // libraries: libraries,
  });

  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }

  return (
    <div className="bg-primary h-100 w-100">
      <GoogleMap mapContainerStyle={mapStyles} zoom={zoom} center={center}>
        {driversList.map((markerData, index) =>
          markerData.driver ? (
            <MarkerF
              key={index}
              // position={marker.location.coordinates}
              position={{
                lat: markerData.location.coordinates[1],
                lng: markerData.location.coordinates[0],
              }}
              onClick={() => handleMarkerClick(index)}
              icon={{
                url: markerData.hasActiveRide
                  ? "/assets/images/driver-onride.svg"
                  : markerData.isOnline
                  ? "/assets/images/driver-online.svg"
                  : "/assets/images/driver-offline.svg",
                scaledSize: { width: 30, height: 40 },
              }}
              // icon={<FaCarAlt />}
            >
              {selectedMarker === index && (
                <InfoWindowF
                  // position={{
                  //   lat: selectedMarker.location.coordinates[0],
                  //   lng: selectedMarker.location.coordinates[1],
                  // }}
                  onCloseClick={closeInfoWindow}
                >
                  <div className="text-black">
                    {markerData.hasActiveRide ? (
                      <h6 className="text-success">Ride in progress</h6>
                    ) : markerData.isOnline ? (
                      <h6 className="text-blue">Driver is Online</h6>
                    ) : (
                      <h6 className="text-danger">Driver is Offline</h6>
                    )}

                    <p className="my-1 text-black">
                      {" "}
                      <b>Driver Name: </b>
                      {`${
                        markerData.driver ? markerData.driver.firstName : ""
                      } ${markerData.driver ? markerData.driver.lastName : ""}`}
                    </p>

                    <p className="my-1">
                      <b>Phone: </b>
                      {markerData.driver ? markerData.driver.phone : ""}{" "}
                    </p>
                    <p className="my-1">
                      <b>Email: </b>
                      {markerData.driver ? markerData.driver.email : ""}{" "}
                    </p>
                    <p className="my-1">
                      <b>Last updated: </b>
                      {markerData.updatedAt
                        ? moment(markerData.updatedAt).format(
                            "hh:mm a @ MMM DD, YYYY"
                          )
                        : ""}
                    </p>
                    <Link
                      to={`/drivers/details/${markerData.driver._id}`}
                      className="text-primary"
                    >
                      <b>View Details</b>
                    </Link>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          ) : (
            <></>
          )
        )}
      </GoogleMap>
    </div>
  );
}

export default TrackingMap;
