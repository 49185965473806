import React from "react";
import PassengersTable from "./DriversTable";
import { Link, NavLink, useLocation } from "react-router-dom";
import DriversTable from "./DriversTable";
import Table from "../../components/table";
import { useSelector } from "react-redux";
import CheckPermission from "../../utils/checkPermission";

function Drivers() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { role } = userInfo;

  const location = useLocation();
  const check = CheckPermission(location);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Drivers List</h4>
              </div>

              {!check && (
                <Link to="add" className="float-right btn btn-sm btn-primary">
                  <i className="fa fa-plus-circle" /> Add Driver
                </Link>
              )}
            </div>
            <DriversTable />
            {/* <Table /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drivers;
