import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { Link, useLocation } from "react-router-dom";
import { asyncGetRideFares } from "../../redux/slices/rideFareSlice";
import CheckPermission from "../../utils/checkPermission";
import { useGetFlatFeesQuery } from "../../redux/services/flatFeeApi";
import TableV2 from "../../components/tableV2";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const FlatFeeTable = () => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  // const { isLoading, list } = rideFares;

  const dispatch = useDispatch();

  const [limit, setLimit] = useState(10);

  const [page, setPage] = useState(1);
  const {
    data: flatFees,
    isLoading,
    error,
    isFetching,
  } = useGetFlatFeesQuery({ page, limit });

  const columns = [
    {
      key: "",
      displayName: "Category",
      displayField: (e) => <>{e?.vehicleCategory?.name}</>,
      searchable: true,
    },
    // { key: "currency", displayName: "Currency" },
    {
      displayField: (e) => (
        <>
          {e?.type === "percentage"
            ? "Percentage"
            : e?.type === "flat_fee"
            ? "Flat Fee"
            : "Flat Fee + Commission"}
        </>
      ),
      displayName: "Fee Type",
      searchable: true,
    },

    // {
    //   displayField: (e) => <span className="">{e?.feeInterval}</span>,
    //   displayName: "Fee Interval",
    //   searchable: true,
    // },
    {
      displayField: (e) => (
        <>
          {e?.type !== "percentage"
            ? `${e?.country?.currency} ${e?.value}`
            : `${e?.value} %`}
        </>
      ),
      displayName: "Fee Value",
      searchable: true,
    },

    {
      displayName: "Commission per Ride",
      displayField: (e) => (
        <>{e.commission ? `${e?.country?.currency} ${e?.commission}` : "-"}</>
      ),

      searchable: true,
    },

    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link to={`/fees/edit/${e._id}`} className="mr-2" title="Update">
            <i className="fas fa-edit text-primary" />
          </Link>
          {/* <Link className="mr-2" href="">
            <i className="fas fa-eye text-secondary" />
          </Link> */}
          {/* <a
            className="mr-2 text-danger"
            href="javascript:void(0)"
            data--submit="rider36"
            data--confirmation="true"
            data-title="Delete Rider"
            title="Delete Rider"
            data-message="Are you sure you want to delete?"
          >
            <i className="fas fa-trash-alt" />
          </a> */}
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  return (
    <>
      <TableV2
        dataSource={flatFees?.results || []}
        isLoading={isLoading}
        columns={columns}
        totalEntries={flatFees?.totalResults}
        currentPage={page}
        setCurrentPage={setPage}
        itemsPerPage={limit}
        setItemsPerPage={setLimit}
        totalPages={flatFees?.totalPages}
      />
    </>
  );
};
export default FlatFeeTable;
