import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetVehicleCategories = createAsyncThunk(
  "getVehicleCategories",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/vehicleCategories${country ? `?country=${country}` : ""}`
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateVehicleCategory = createAsyncThunk(
  "postVehicleCategories",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;
    try {
      const response = await axiosPrivate.post("/vehicleCategories", obj);
      toast.success("fare created Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncDeleteVehicleCategories = createAsyncThunk(
  "DeleteVehicleCategories",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { id } = payload;

    try {
      await axiosPrivate.delete(`/vehicleCategories/${id}`);
      // toast.success("fare Update Successfully");
      return fulfillWithValue(id);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateVehicleCategory = createAsyncThunk(
  "updateVehicleCategory",
  async (
    { id, data, callback },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(
        `/vehicleCategories/${id}`,
        data
      );
      callback && callback();
      toast.success("Vehicle category created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const vehicleCategorySlice = createSlice({
  name: "vehicleCategories",
  initialState: {
    isLoading: false,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetVehicleCategories.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetVehicleCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetVehicleCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    builder.addCase(asyncCreateVehicleCategory.pending, (state, action) => {
      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateVehicleCategory.fulfilled, (state, action) => {
      state.add = {
        isLoading: false,
        message: "Vehicle category created successfully!",
      };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateVehicleCategory.rejected, (state, action) => {
      state.add = { isLoading: false, message: action.payload };
    });

    builder.addCase(asyncUpdateVehicleCategory.pending, (state, action) => {
      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateVehicleCategory.fulfilled, (state, action) => {
      state.update = {
        isLoading: false,
        message: "Vehicle category updated successfully!",
      };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateVehicleCategory.rejected, (state, action) => {
      state.update = { isLoading: false, message: action.payload };
    });

    builder.addCase(asyncDeleteVehicleCategories.fulfilled, (state, action) => {
      state.list = state.list.filter((x) => x._id !== action.payload);
    });
  },
  reducers: {},
});

// export const {} = vehicleCategorySlice.actions;
export default vehicleCategorySlice.reducer;
