import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { asyncDeleteUser, asyncGetUsers } from "../../redux/slices/userSlice";
import DefaultProfileImg from "../../assets/images/default-profile-img.png";

import { BUCKET_PUBLIC_URL } from "../../constants";
import TableV2 from "../../components/tableV2";
import Swal from "sweetalert2";
import {
  useDeleteUsersMutation,
  useGetUsersQuery,
} from "../../redux/services/userAPI";

const UserTable = () => {
  const auth = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // const { isLoading, list } = users;

  const { data, isLoading } = useGetUsersQuery({
    page: currentPage,
    limit: itemsPerPage,
    country: auth.country,
  });

  const [deleteUsers] = useDeleteUsersMutation();
  // console.log(data, "data");

  // const {
  //   // limit, page,
  //   results,
  //   totalPages,
  //   totalResults,
  // } = data;

  const dispatch = useDispatch();

  // const DeleteUserByIdHanlder = (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Swal.fire({
  //         title: "Deleted!",
  //         text: "User has been deleted.",
  //         icon: "success",
  //       });
  //       (async () => {
  //         try {
  //           // Await the deleteUsers call and unwrap the result
  //           await deleteUsers({ id }).unwrap();
  //           console.log("User deleted successfully");
  //         } catch (error) {
  //           console.error("Error deleting user:", error);
  //         }
  //       })();
  //     }
  //   });
  // };

  const DeleteUserByIdHandler = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      // Added async here
      if (result.isConfirmed) {
        try {
          // Await the deleteUsers call and unwrap the result
          await deleteUsers({ id }).unwrap();

          // After deletion is successful, show the success message
          Swal.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });

          console.log("User deleted successfully");
        } catch (error) {
          console.error("Error deleting user:", error);
        }
      }
    });
  };

  const columns = [
    {
      displayField: (e) => (
        <>
          <img
            src={`${BUCKET_PUBLIC_URL}${e.profileImageUrl}`}
            alt="Profile"
            height={40}
            width={40}
            className="rounded-pill"
            onError={(e) => {
              e.target.src = DefaultProfileImg;
            }}
          />
          {e?.firstName} {e?.lastName}
        </>
      ),
      displayName: "Name",
      searchable: true,
    },

    {
      displayField: (e) => (
        <>
          {e.email} {e.email === auth.userInfo?.email ? "( You )" : ""}
        </>
      ),
      displayName: "Email",
      searchable: true,
    },

    {
      displayField: (e) => <>{e.phone}</>,
      displayName: "Phone",
      searchable: true,
    },

    {
      displayField: (e) => (
        <>
          <div className="text-capitalize">{e.role.split("_").join(" ")}</div>{" "}
        </>
      ),
      displayName: "Role",
      searchable: true,
    },
    {
      displayField: (e) => <>{e.status}</>,
      displayName: "Status",
      searchable: true,
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link
            className="mr-2"
            title="Update Rider"
            to={`/users/edit/${e._id}`}
          >
            <i className="fas fa-edit text-primary" />
          </Link>
          <Link
            className="mr-2 "
            href=""
            style={{ color: "red" }}
            onClick={() => DeleteUserByIdHandler(e?._id)}
          >
            <i className="fas fa-trash-alt" />
          </Link>
        </div>
      ),
      searchable: true,
    },
  ];
  useEffect(() => {
    dispatch(asyncGetUsers({ page: currentPage, limit: itemsPerPage }));
  }, [dispatch, currentPage, itemsPerPage]);

  const searchDataHandlerOnTable = (e) => {
    const { value } = e.target;
    dispatch(
      asyncGetUsers({ query: value, page: currentPage, limit: itemsPerPage })
    );
  };

  return (
    <>
      <TableV2
        dataSource={data?.results || []}
        {...{
          columns,
          isLoading,
          searchDataHandlerOnTable,
          currentPage,
          setCurrentPage,
          itemsPerPage,
          setItemsPerPage,
          totalPages: data?.totalPages,
          totalEntries: data?.totalResults,
        }}
      />
    </>
  );
};
export default UserTable;
