import React, { useState } from "react";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import moment from "moment";
import { useGetRidesQuery } from "../../redux/services/rideAPI";
import TableV2 from "../../components/tableV2";

const columns = [
  {
    displayName: "Passenger Name",
    displayField: (e) => (
      <>
        {e.passenger?.firstName} {e.passenger?.lastName}
      </>
    ),
    searchable: true,
  },
  {
    displayName: "Driver Name",
    displayField: (e) => (
      <>
        {e.driver?.firstName} {e.driver?.lastName}
      </>
    ),
    searchable: true,
  },
  {
    displayName: "Pickup",
    displayField: (e) => (
      <>
        {e?.route?.pickup
          ? e?.route?.pickup?.address?.formattedAddress
          : `${e?.rideRequest?.pickup?.address?.formattedAddress} ( Est )`}
      </>
    ),
    searchable: true,
  },
  {
    displayName: "Dropoff",
    displayField: (e) => (
      <>
        {e?.route?.dropoff
          ? e?.route?.dropoff?.address?.formattedAddress
          : `${e?.rideRequest?.dropoff?.address?.formattedAddress} ( Est )`}
      </>
    ),
    searchable: true,
  },
  {
    // key: "verified",
    displayName: "Booking Time",
    displayField: (e) => {
      return <>{moment(e?.bookingTime).format("hh:mm a @ DD MMM, YYYY ")}</>;
    },
    searchable: true,
  },
  {
    displayName: "Distance / Duration",
    displayField: (e) => (
      <>
        {e?.route?.distance
          ? e?.route?.distance
          : `${e?.rideRequest?.distance} / ${e?.rideRequest?.duration} ( Est )`}
      </>
    ),
  },
  {
    displayName: "Fare",
    displayField: (e) => (
      <>
        {e?.route?.fareDetails
          ? e?.route?.fareDetails?.totalFare
          : `${e?.rideRequest?.fareDetails?.currency} ${e?.rideRequest?.fareDetails?.totalFare} ( Est )`}
      </>
    ),
  },

  {
    key: "status",
    displayName: "Status",
    displayField: (e) => (
      <>
        {e.status === "Completed" ? (
          <span className="text-capitalize badge bg-success">{e.status}</span>
        ) : e.status === "Active" ? (
          <span className="text-capitalize badge bg-primary">{e.status}</span>
        ) : (
          <span className="text-capitalize badge bg-danger">{e.status}</span>
        )}
      </>
    ),
  },
  {
    displayName: "Action",
    key: "",
    displayField: (e) => (
      <div className="d-flex justify-content-start align-items-center">
        {/* <Link className="mr-2" to="" title="Update Rider">
            <i className="fas fa-edit text-primary" />
          </Link> */}
        <Link className="mr-2" to={`/rides/${e._id}`}>
          <i className="fas fa-eye text-secondary" />
        </Link>
        {/* <a
            className="mr-2 text-danger"
            href="javascript:void(0)"
            data--submit="rider36"
            data--confirmation="true"
            data-title="Delete Rider"
            title="Delete Rider"
            data-message="Are you sure you want to delete?"
          >
            <i className="fas fa-trash-alt" />
          </a> */}
      </div>
    ),
    searchable: true,
  },
];

const RidesTable = () => {
  const auth = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const {
    data: rides,
    isLoading,
    // isError,
  } = useGetRidesQuery({
    page: currentPage,
    limit: itemsPerPage,
    country: auth.country,
  });

  return (
    <>
      <TableV2
        dataSource={rides?.results || []}
        isLoading={isLoading}
        columns={columns}
        totalEntries={rides?.totalResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={rides?.totalPages}
      />
    </>
  );
};
export default RidesTable;
