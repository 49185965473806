import React from "react";
import CabCategoryTable from "./CabCategoryTable";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckPermission from "../../utils/checkPermission";

function CabCategoryPage() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { role } = userInfo;

  const location = useLocation();
  const check = CheckPermission(location)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Cab Categories List</h4>
              </div>

              {!check    && (
                <Link to="add" className="float-right btn btn-sm btn-primary">
                  <i className="fa fa-plus-circle" /> Add Category
                </Link>
              )}
            </div>
            <CabCategoryTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CabCategoryPage;
