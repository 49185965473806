import { createSlice } from "@reduxjs/toolkit";
import { notificationAPI } from "../services/notificationAPI";

const initialState = {
  notification: [],
};

const slice = createSlice({
  name: "NotificationSlice",
  initialState,
  reducers: {
    addMessage: (state, action) => {

      state.notification = [...state.notification, action.payload];
    },
  },

  extraReducers: (builder) => {
    builder
      //   .addMatcher(chatAPI.endpoints.getChatMessage.matchPending, (state) => {
      //     state.messages = [];
      //     // state.selectedChat = null;
      //   })
      .addMatcher(
        notificationAPI.endpoints.getNotificationAPI.matchFulfilled,
        (state, { payload }) => {}
      );
    //   .addMatcher(chatAPI.endpoints.getChatMessage.matchRejected, (state) => {
    //     state.messages = null;
    //   })

    //   .addMatcher(
    //     chatAPI.endpoints.getAllChats.matchFulfilled,
    //     (state, { payload }) => {
    //       state.chats = payload.data;
    //     }
    //   );
  },
});

export const { addMessage, selectChat, typingChat } = slice.actions;

export default slice.reducer;
