import React, { useEffect, useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import CustomSelectAsync from "../../components/CustomSelectAsync";
import axios from "axios";
import axiosPrivate from "../../services/api";
import DropzoneUpload from "../../components/DropzoneUpload";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomButton from "../../components/CustomButton";
import { asyncPostCabDocuments } from "../../redux/slices/cabSlice";
import { Link } from "react-router-dom";

function AddCabDocument() {
  const auth = useSelector((state) => state.auth);
  const [requiredDocumentsList, setRequiredDocumentsList] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [formData, setFormData] = useState({
    modelNameOfTheDocumentFor: "Vehicle",
  });

  const cabs = useSelector((state) => state.cabs);
  const { addDocument } = cabs;
  const { isLoading } = addDocument;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define a function to load driver options from an API
  const loadDriverOptions = async (inputValue) => {
    // Make an API request to fetch driver options based on the inputValue
    const { data } = await axiosPrivate.get(
      `/vehicles/search?plateNumber=${inputValue}`
    );

    // Format the response data into options for the Select component
    const options = data.map((vehicle) => ({
      label: `${vehicle.make} ${vehicle.model} ${vehicle.year} ( ${vehicle.plateNumber} )`, // Replace with the driver's name property
      value: vehicle._id, // Replace with the driver's unique identifier property
    }));

    return options;
  };

  const getRequiredDocumentsList = async () => {
    try {
      const { data } = await axiosPrivate.get(`/requiredDocuments`);
      setRequiredDocumentsList(data.filter((x) => x.type === "Vehicle"));
    } catch (err) {
      console.log(err);
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      asyncPostCabDocuments({
        obj: formData,
        callback: () => navigate("/cabs"),
      })
    );
  };

  useEffect(() => {
    getRequiredDocumentsList();
  }, []);

  useEffect(() => {
    setSelectedDocumentType(
      requiredDocumentsList.find((x) => x._id === formData.documentType)
    );
  }, [formData.documentType]);

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Add Cab Document</h4>
                </div>
              </div>
              <div className="card-body pb-5">
                <div className="new-user-info">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="driver_id" className="form-control-label">
                        Select Vehicle <span className="text-danger">*</span>
                      </label>

                      <CustomSelectAsync
                        loadOptions={loadDriverOptions}
                        name="documentFor"
                        placeholder="Search here"
                        onChange={changeHandler}
                        formData={formData}
                      />
                      {/* <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        data-select2-id={1}
                        style={{ width: "23.6px" }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--single"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={0}
                            aria-disabled="false"
                            aria-labelledby="select2-driver_id-container"
                          >
                            <span
                              className="select2-selection__rendered"
                              id="select2-driver_id-container"
                              role="textbox"
                              aria-readonly="true"
                            >
                              <span className="select2-selection__placeholder">
                                Select Driver
                              </span>
                            </span>
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation" />
                            </span>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span> */}
                    </div>
                    <div className="form-group col-md-4">
                      <label
                        htmlFor="document_id"
                        className="form-control-label"
                      >
                        Select Document <span className="text-danger">*</span>
                      </label>
                      <CustomSelect
                        name="documentType"
                        placeholder="Select document"
                        options={requiredDocumentsList.map((x) => ({
                          label: x.name,
                          value: x._id,
                        }))}
                        onChange={changeHandler}
                      />
                    </div>
                    {selectedDocumentType?.hasExpiry && (
                      <div className="form-group col-md-4">
                        <label
                          className="form-control-label"
                          htmlFor="expire_date"
                        >
                          Expiry Date{" "}
                          <span className="text-danger" id="has_expiry_date" />{" "}
                        </label>
                        <input
                          className="form-control min-datepicker flatpickr-input"
                          placeholder="Expiry Date"
                          type="date"
                          onChange={changeHandler}
                          name="expirationDate"
                        />
                      </div>
                    )}
                    <div className="form-group col-md-4">
                      <label
                        htmlFor="is_verified"
                        className="form-control-label"
                      >
                        Verification <span className="text-danger">*</span>
                      </label>
                      <select className="form-control">
                        <option value="Peding">Pending</option>
                        <option value="Verified">Verified</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label
                        className="form-control-label"
                        htmlFor="driver_document"
                      >
                        Upload Front Side{" "}
                        <span className="text-danger" id="document_required" />{" "}
                      </label>
                      <DropzoneUpload
                        name="frontSideUrl"
                        directory={`driver-uploads/${formData?.driver}/${selectedDocumentType?._id}/front/`}
                        onChange={changeHandler}
                        isEnabled={
                          formData.documentFor && formData.documentType
                        }
                        disableReason="Please select driver and document type first!"
                      />
                      <span className="selected_file" />
                    </div>
                    {selectedDocumentType?.hasBackSide && (
                      <div className="form-group col-md-4">
                        <label
                          className="form-control-label"
                          htmlFor="driver_document"
                        >
                          Upload Back Side{" "}
                          <span
                            className="text-danger"
                            id="document_required"
                          />{" "}
                        </label>
                        <DropzoneUpload
                          name="backSideUrl"
                          directory={`driver-uploads/${formData?.driver}/${selectedDocumentType?._id}/back/`}
                          onChange={changeHandler}
                          isEnabled={formData.driver && formData.documentType}
                          disableReason="Please select driver and document type first!"
                        />
                        <span className="selected_file" />
                      </div>
                    )}
                    <div className="form-group col-md-4">
                      <label
                        className="form-control-label"
                        htmlFor="driver_document"
                      >
                        Uploaded documents
                        <span
                          className="text-danger"
                          id="document_required"
                        />{" "}
                      </label>
                      <div className="d-flex">
                        {formData.frontSideUrl && formData.frontSideUrl ? (
                          <div>
                            <img src={formData.frontSideUrl} height={150} />
                            <Link to="">Download</Link>
                          </div>
                        ) : (
                          <></>
                        )}
                        {selectedDocumentType?.hasBackSide &&
                        formData.backSideUrl &&
                        formData.backSideUrl ? (
                          <div>
                            <img src={formData.backSideUrl} height={150} />
                            <Link to="">Download</Link>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <CustomButton
                    className="btn btn-md btn-primary float-right"
                    // onClick={postCabDocumentsHandler}
                    type="submit"
                    title="Submit"
                    isLoading={isLoading}
                  />
                </div>
              </div>
              {/* <hr /> */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddCabDocument;
