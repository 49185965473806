import moment from "moment";

export const calculateMessageTime = (time) => {
  const currentDate = moment();
  const messageTime = moment(time);
  const dateString = time;

  const duration = moment.duration(currentDate.diff(dateString));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  if (days === 0 && hours === 0 && minutes === 0) {
    return "Just now";
  } else if (days === 0 && hours === 0 && minutes < 10) {
    return minutes > 1 ? minutes + " mins ago" : minutes + " min ago";
  } else if (days >= 1) {
    return messageTime.format("DD MMM  hh:mm a");
  } else {
    // return `${days === 0 ? "" : days + "d"} ${hours === 0 ? "" : hours + "h"} ${
    //   minutes === 0 ? "" : minutes + "m"
    // } ago`;

    return messageTime.format("hh:mm a");
  }
};
