import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetDrivers } from "../../redux/slices/driverSlice";
import { Link, useLocation } from "react-router-dom";
import { asyncGetCabDocuments } from "../../redux/slices/cabSlice";
import ImagePopup from "../../components/modals/imagePopup";
import CheckPermission from "../../utils/checkPermission";

const CabDocumentsTable = () => {
  const auth = useSelector((state) => state.auth);
  const cabs = useSelector((state) => state.cabs.document);

  const { isLoading, list } = cabs;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [selectedDocument, setSelectedDocument] = useState(null);

  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);

  const location = useLocation();

  useEffect(() => {
    dispatch(asyncGetCabDocuments());
  }, [auth.country]);

  const columns = [
    {
      displayName: "Vehicle Name",
      displayField: (e) =>
        e?.documentFor ? (
          <>
            {e?.documentFor?.make} {e?.documentFor?.model}{" "}
            {e?.documentFor?.year} ( {e?.documentFor?.plateNumber})
          </>
        ) : (
          <>N/A</>
        ),
      searchable: true,
    },

    {
      displayName: "Document Name",
      displayField: (e) => <>{e?.documentType?.name}</>,
      searchable: true,
    },
    {
      displayName: "Has Expiry",
      displayField: (e) => <>{e?.documentType?.hasExpiry ? "Yes" : "No"}</>,
      searchable: true,
    },

    {
      displayName: "Has Back Side",
      displayField: (e) => <>{e?.documentType?.hasBackSide ? "Yes" : "No"}</>,
      searchable: true,
    },

    {
      displayName: "Status",
      displayField: (e) => (
        <>
          {e.status === "Pending" ? (
            <span className="text-capitalize badge bg-primary">{e.status}</span>
          ) : e.status === "Verified" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
    },

    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link className="mr-2" to={`edit/${e._id}`} title="Update Driver">
            <i className="fas fa-edit text-primary" />
          </Link>
          <Link className="mr-2" to={``} onClick={() => setSelectedDocument(e)}>
            <i className="fas fa-eye text-secondary" />
          </Link>
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
      {selectedDocument && (
        <ImagePopup
          show={selectedDocument}
          data={selectedDocument}
          handleClose={() => setSelectedDocument(null)}
        />
      )}
    </>
  );
};
export default CabDocumentsTable;
