import React, { useState } from "react";

import ProfileDetails from "./profileDetails";
import ChangePassword from "./changePassword";

function ProfilePage() {
  const [tabs, setTabs] = useState("profile");

  const changeTabs = (screen) => {
    setTabs(screen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch">
            <div className="card-body p-0">
              <div className="d-flex justify-content-between align-items-center p-3">
                <h5 className="font-weight-bold">Profile</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item" onClick={() => changeTabs("profile")}>
                  <span
                    className={`nav-link ${
                      tabs === "profile" ? "active" : " "
                    }`}
                  >
                    {" "}
                    Profile{" "}
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => changeTabs("changePassword")}
                >
                  <span
                    className={`nav-link ${
                      tabs === "changePassword" ? "active" : " "
                    }`}
                  >
                    {" "}
                    Change Password{" "}
                  </span>
                </li>
              </ul>

              {tabs === "profile" && <ProfileDetails />}

              {tabs === "changePassword" && <ChangePassword />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
