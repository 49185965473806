import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
// import { asyncGetDrivers } from "../../redux/slices/driverSlice";
import { Link, useLocation } from "react-router-dom";
import { asyncGetPassengers } from "../../redux/slices/passengerSlice";
import DefaultProfileImg from "../../assets/images/default-profile-img.png";
// import sidebarList from "../../layout/AdminLayout/MenuSidebar";
import CheckPermission from "../../utils/checkPermission";
import { BUCKET_PUBLIC_URL } from "../../constants";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const PassengersTable = () => {
  const location = useLocation();

  const columns = [
    {
      key: "Name",
      displayName: "Name",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <img
            width={40}
            height={40}
            src={`${BUCKET_PUBLIC_URL}${e.profileImageUrl}`}
            className="rounded-circle object-fit-cover"
            onError={(e) => {
              e.target.src = DefaultProfileImg;
            }}
            alt=""
          />
          <p className="ml-2 mb-0">
            {e.firstName} {e.lastName}
          </p>
        </div>
      ),
      searchable: true,
    },
    {
      key: "email",
      displayName: "Email",
      searchable: true,
    },
    {
      key: "phone",
      displayName: "Phone",
      searchable: true,
    },
    {
      // key: "status",
      displayField: (e) => (
        <>
          {e.verification === "Pending" ? (
            <span className="text-capitalize badge bg-primary">
              {e.verification}
            </span>
          ) : e.status === "Verified" ? (
            <span className="text-capitalize badge bg-success">
              {e.verification}
            </span>
          ) : (
            <span className="text-capitalize badge bg-danger">
              {e.verification}
            </span>
          )}
        </>
      ),
      displayName: "Verification",
    },
    {
      key: "status",
      displayName: "Status",
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link className="mr-2" to={`edit/${e._id}`} title="Update Passenger">
            <i className="fas fa-edit text-primary" />
          </Link>
          <Link className="mr-2" to={`details/${e._id}`}>
            <i className="fas fa-eye text-secondary" />
          </Link>
          {/* <a
            className="mr-2 text-danger"
            href="javascript:void(0)"
            data--submit="rider36"
            data--confirmation="true"
            data-title="Delete Rider"
            title="Delete Rider"
            data-message="Are you sure you want to delete?"
          >
            <i className="fas fa-trash-alt" />
          </a> */}
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];
  const auth = useSelector((state) => state.auth);
  const passengers = useSelector((state) => state.passengers);

  const { isLoading, list } = passengers;
  // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  // const openMoreVertDropDown = () => {
  //   setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  // };

  // const [modal, setModal] = useState({ open: false, data: null });

  // const handleModal = (data) => {
  //   setModal({ open: !modal.open, data: data });
  // };
  const dispatch = useDispatch();
  // const [currentPageNumber, setCurrentPageNumber] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(asyncGetPassengers());
  }, [dispatch, auth.country]);

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};

export default PassengersTable;
