import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncUpdateRideSettings,
  asyncUpdateWalletsSettings,
} from "../../redux/slices/settingSlice";
import CustomButton from "../../components/CustomButton";

function RideSettings() {
  const [generalValue, SetGeneralValue] = useState();
  const setting = useSelector((state) => state.setting);

  const { data, isLoading } = setting;
  const { rideSettings } = data;

  const dispatch = useDispatch();

  useEffect(() => {
    if (rideSettings) {
      SetGeneralValue({
        driverFindingRadius: rideSettings.driverFindingRadius,
        maximumTimeToFindDriver: rideSettings.maximumTimeToFindDriver,
        rideAcceptDeclineDuration: rideSettings.rideAcceptDeclineDuration,
      });
    }
  }, [rideSettings]);

  const mobileMoneyValueHandler = (event) => {
    const { name, value } = event.target;
    SetGeneralValue({ ...generalValue, [name]: value });
  };

  const submitHandler = () => {
    dispatch(
      asyncUpdateRideSettings({
        obj: generalValue,
      })
    );
  };
  return (
    <div className="paste_here active">
      <input
        name="_token"
        type="hidden"
        value="M6N5LrwVhmXv4LVQmEYmqAZm7Pkzyfk52dn84Lb5"
      />
      <input
        className="form-control"
        name="page"
        type="hidden"
        value="ride-setting"
      />
      <div className="col-md-12 mt-20">
        <div className="row">
          <div className="col-md-6 form-group">
            <label
              for="max_time_for_find_drivers_for_regular_ride_in_minute"
              className="form-control-label"
            >
              Maximum Time for Find Driver in Minutes
            </label>
            <input
              placeholder="Maximum Time for Find Driver in Minutes"
              min="0"
              step="any"
              className="form-control"
              name="driverFindingRadius"
              type="number"
              value={generalValue ? generalValue.driverFindingRadius : " "}
              id="max_time_for_find_drivers_for_regular_ride_in_minute"
              onChange={mobileMoneyValueHandler}
            />
          </div>
          <div className="col-md-6 form-group">
            <label
              for="ride_accept_decline_duration_for_driver_in_second"
              className="form-control-label"
            >
              Ride Accept/Decline Duration for Driver in Second
            </label>
            <input
              placeholder="Ride Accept/Decline Duration for Driver in Second"
              min="0"
              step="any"
              className="form-control"
              name="maximumTimeToFindDriver"
              type="number"
              value={generalValue ? generalValue.maximumTimeToFindDriver : " "}
              onChange={mobileMoneyValueHandler}
              id="ride_accept_decline_duration_for_driver_in_second"
            />
          </div>
          <div className="col-md-6 form-group">
            <label for="preset_tip_amount" className="form-control-label">
              Driver Finding Radius from Pickup Location ( in metres )
              <span
                data-toggle="tooltip"
                data-placement="right"
                title="Enter preset amounts riders can tip drivers after a ride; Leave blank to hide presets. Separate each amount with the pipe | character. Example 0|5|10|50"
              >
                {/* <i className="fas fa-question-circle"></i> */}
              </span>
            </label>
            <input
              placeholder="0|5|10|50"
              className="form-control"
              name="rideAcceptDeclineDuration"
              type="text"
              value={
                generalValue ? generalValue.rideAcceptDeclineDuration : " "
              }
              onChange={mobileMoneyValueHandler}
            />
          </div>
        </div>
      </div>
      <CustomButton
        className="btn btn-md btn-primary float-md-right"
        type="submit"
        value="Save"
        onClick={submitHandler}
        title="Update"
        isLoading={isLoading}
      />
    </div>
  );
}

export default RideSettings;
