import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetCompanySetting = createAsyncThunk(
  "asyncGetCompanySetting",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/settings");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateGeneralSettings = createAsyncThunk(
  "asyncPutCompanySetting",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj } = payload;
    try {
      const response = await axiosPrivate.put("/settings/generalSettings", obj);
      toast.success("General settings updated successfully");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateMobileMoneySettings = createAsyncThunk(
  "asyncPutmobileMoneySettings",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj } = payload;
    try {
      const response = await axiosPrivate.put(
        "/settings/mobileMoneySettings",
        obj
      );
      toast.success("Mobile Money settings updated successfully");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateWalletsSettings = createAsyncThunk(
  "asyncPutWalletSettings",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj } = payload;
    try {
      const response = await axiosPrivate.put("/settings/walletSettings", obj);
      toast.success("Wallets settings updated successfully");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateRideSettings = createAsyncThunk(
  "asyncUpdateRideSettings",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj } = payload;
    try {
      const response = await axiosPrivate.put("/settings/rideSettings", obj);
      toast.success("Rides Settings updated successfully");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const companySettingSlice = createSlice({
  name: "companySetting",
  initialState: {
    isLoading: false,
    isError: false,
    data: {},
    update: {},
  },

  extraReducers: (builder) => {
    builder.addCase(asyncGetCompanySetting.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCompanySetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCompanySetting.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    builder.addCase(asyncUpdateGeneralSettings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncUpdateGeneralSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.generalSettings = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncUpdateGeneralSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    builder.addCase(asyncUpdateMobileMoneySettings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      asyncUpdateMobileMoneySettings.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data.mobileMoneySettings = action.payload;
        state.isError = false;
        state.message = null;
      }
    );
    builder.addCase(
      asyncUpdateMobileMoneySettings.rejected,
      (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isError = true;
      }
    );

    builder.addCase(asyncUpdateWalletsSettings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncUpdateWalletsSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.walletSettings = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncUpdateWalletsSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    builder.addCase(asyncUpdateRideSettings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncUpdateRideSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.rideSettings = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncUpdateRideSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
});

// export const {} = companySettingSlice.actions;
export default companySettingSlice.reducer;
