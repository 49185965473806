import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { asyncUpdateDocumentRequirement } from "../../redux/slices/documentSlice";

function RequiredDocumentsUpdate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  const documents = useSelector((state) => state.documents);
  const { isLoading, list } = documents;

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const cabFound = list.find((x) => x._id === id);
    if (cabFound) {
      setFormData({
        name: cabFound?.name,
        type: cabFound?.type,
        hasBackSide: cabFound?.hasBackSide,
        hasExpiry: cabFound?.hasExpiry,
        status: cabFound?.status,
      });
    }
  }, [list, id]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      asyncUpdateDocumentRequirement({
        id: id,
        data: formData,
        callback: () => {
          navigate("/documents/required");
        },
      })
    );
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Add Document Requirement</h4>
              </div>
            </div>
            <div className="card-body">
              <div className="new-user-info">
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="name" className="form-control-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Name"
                      className="form-control"
                      required=""
                      name="name"
                      type="text"
                      id="name"
                      value={formData.name}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="documentFor" className="form-control-label">
                      Document For <span className="text-danger">*</span>
                    </label>
                    <select
                      placeholder="Name"
                      className="form-control"
                      required=""
                      name="type"
                      type="text"
                      id="documentFor"
                      value={formData.type}
                      onChange={changeHandler}
                    >
                      <option>Please select type</option>
                      <option value="Driver">Driver</option>
                      <option value="Vehicle">Vehicle</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="is_required" className="form-control-label">
                      Has Back Side
                    </label>
                    <select
                      className="form-control "
                      required=""
                      id="is_required"
                      name="hasBackSide"
                      data-select2-id="is_required"
                      tabIndex={-1}
                      aria-hidden="true"
                      value={formData.hasBackSide}
                      onChange={changeHandler}
                    >
                      <option value={false} data-select2-id={2}>
                        No
                      </option>
                      <option value={true}>Yes</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label
                      htmlFor="has_expiry_date"
                      className="form-control-label"
                    >
                      Has Expiry Date
                    </label>
                    <select
                      className="form-control "
                      required=""
                      id="has_expiry_date"
                      name="hasExpiry"
                      data-select2-id="has_expiry_date"
                      tabIndex={-1}
                      aria-hidden="true"
                      value={formData.hasExpiry}
                      onChange={changeHandler}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="status" className="form-control-label">
                      Status
                    </label>
                    <select
                      className="form-control "
                      required=""
                      id="status"
                      name="status"
                      data-select2-id="status"
                      tabIndex={-1}
                      aria-hidden="true"
                      value={formData.status}
                      onChange={changeHandler}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <hr />
                <CustomButton
                  className="btn btn-md btn-primary float-right"
                  type="submit"
                  title="Submit"
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default RequiredDocumentsUpdate;
