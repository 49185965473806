import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncUpdateGeneralSettings } from "../../redux/slices/settingSlice";
import CustomButton from "../../components/CustomButton";

function GeneralSettings() {
  const [generalValue, SetGeneralValue] = useState();
  const setting = useSelector((state) => state.setting);

  const { data, isLoading } = setting;
  const { generalSettings } = data;

  
  const dispatch = useDispatch();

  useEffect(() => {
    if (generalSettings) {
      SetGeneralValue(generalSettings);
      // SetGeneralValue({
      //   contactEmail: generalSettings.contactEmail,
      //   contactNumber: generalSettings.contactNumber,
      //   copyrightText: generalSettings.copyrightText,
      //   facebookUrl: generalSettings.facebookUrl,
      //   linkedinUrl: generalSettings.linkedinUrl,
      //   twitterUrl: generalSettings.twitterUrl,
      // });
    }
  }, [generalSettings]);

  
  
  const generalValueHandler = (event) => {
    const { name, value } = event.target;
    SetGeneralValue({ ...generalValue, [name]: value });
  };

  const submitHandler = () => {
    dispatch(
      asyncUpdateGeneralSettings({
        obj: generalValue,
      })
    );
  };
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="form-group">
          <label for="contact_email" className="col-sm-6  form-control-label">
            Contact Email
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Contact Email"
              name="contactEmail"
              type="text"
              value={generalValue ? generalValue.contactEmail : " "}
              id="contact_email"
              onChange={generalValueHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label for="contact_number" className="col-sm-6  form-control-label">
            Contact Number
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Contact Number"
              name="contactNumber"
              type="text"
              value={generalValue ? generalValue.contactNumber : " "}
              id="contact_number"
              onChange={generalValueHandler}
            />
          </div>
        </div>{" "}
        <div className="form-group">
          <label for="copyright_text" className="col-sm-6  form-control-label">
            Copyright Text
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Copyright Text"
              name="copyrightText"
              type="text"
              value={generalValue ? generalValue.copyrightText : " "}
              onChange={generalValueHandler}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="form-group">
          <label for="facebook_url" className="col-sm-6  form-control-label">
            Facebook URL
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Enter Facebook URL"
              name="facebookUrl"
              type="text"
              value={generalValue ? generalValue.facebookUrl : " "}
              id="facebook_url"
              onChange={generalValueHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label for="twitter_url" className="col-sm-6  form-control-label">
            Twitter URL
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Enter Twitter URL"
              name="twitterUrl"
              type="text"
              value={generalValue ? generalValue.twitterUrl : " "}
              id="twitter_url"
              onChange={generalValueHandler}
            />
          </div>
        </div>

        <div className="form-group">
          <label for="linkedin_url" className="col-sm-6  form-control-label">
            LinkedIn URL
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Enter LinkedIn URL"
              name="linkedinUrl"
              type="text"
              id="linkedin_url"
              value={generalValue ? generalValue.linkedinUrl : " "}
              onChange={generalValueHandler}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="col-lg-12">
        <div className="form-group">
          <div className="col-md-offset-3 col-sm-12 ">
            <CustomButton
              className="btn btn-md btn-primary float-md-right"
              type="submit"
              value="Save"
              onClick={submitHandler}
              title="Update"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;
