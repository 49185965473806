import { createSlice } from '@reduxjs/toolkit';

const ToggleSlice = createSlice({
  name: 'toggle', // Slice ka naam
  initialState: {
    isActive: true, // Initial state
  },
  reducers: {
    toggleOpenAndClose: (state) => {
      state.isActive = !state.isActive; // True/False toggle logic
    },
   
  },
});

export const { toggleOpenAndClose } = ToggleSlice.actions; // Actions export
export default ToggleSlice.reducer; // Reducer export
