import React, { useEffect, useState } from "react";

// import WalletsTable from "./CountryTable";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { asyncGetVehicleCategories } from "../../redux/slices/vehicleCategorySlice";
// import { asyncCreateRideFare } from "../../redux/slices/rideFareSlice";
import CountryForm from "./countryForm";
import { asyncCreateCountry } from "../../redux/slices/countrySlice";

function AddCountry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const countries = useSelector((state) => state.countries);
  const { isLoading } = countries;

  const [formData, setFormData] = useState({});

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      asyncCreateCountry({
        payload: formData,
        callback: () => {
          navigate("/countries");
        },
      })
    );

    setFormData({
      name: "",
      shortName: "",
      code: "",
      currency: "",
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Add Country </h4>
              </div>
              {/* <Link to="add" className="float-right btn btn-sm btn-primary">
                <i className="fa fa-plus-circle" /> Add Passenger
              </Link>{" "} */}
            </div>
            {/* <CountryForm {...{ submitHandler, changeHandler, formData, isLoading }}
            /> */}
            <CountryForm
              submitHandler={submitHandler}
              changeHandler={changeHandler}
              formData={formData}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCountry;
