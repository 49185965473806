import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
import socket from "../../services/socket";

function AdminLayout({ children }) {
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  const { userInfo, tokens } = auth;

  useEffect(() => {
    if (!userInfo || !tokens) navigate("/login");
  }, [navigate, userInfo, tokens]);

  const connectSocket = () => {
    try {
      if (socket?.connect && !socket.connected && navigator?.onLine) {
        socket.connect();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (socket.conected && userInfo) {
      socket.emit("user-connected", userInfo._id);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo) {
      connectSocket();
      socket.on("connect", () => {
        socket.emit("user-connected", userInfo._id);
      });

      socket.on("disconnect", () => {
        socket.emit("user-disconnected", userInfo._id);
      });

      return () => {
        socket.emit("user-logout", userInfo._id);
        socket.emit("user-disconnected", userInfo._id);

        socket.disconnect();
      };
    }
  }, [userInfo]);

  return (
    <div>
      <div id="loading" style={{ display: "none" }}>
        <div id="loading-center"></div>
      </div>
      <Header />
      <Sidebar />

      <div id="remoteModelData" className="modal fade" role="dialog" />
      <div className="content-page">{children}</div>
      {/* <Footer /> */}
    </div>
  );
}

export default AdminLayout;
