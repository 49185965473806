import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncUpdateMobileMoneySettings } from "../../redux/slices/settingSlice";
import CustomButton from "../../components/CustomButton";

function MobileMoneySettings() {
  const [generalValue, SetGeneralValue] = useState();
  const setting = useSelector((state) => state.setting);

  const { data, isLoading } = setting;
  const { mobileMoneySettings } = data;

  const dispatch = useDispatch();

  useEffect(() => {
    if (mobileMoneySettings) {
      SetGeneralValue({                           
        accountNumber: mobileMoneySettings.accountNumber,
        accountTitle: mobileMoneySettings.accountTitle,
        agentContact: mobileMoneySettings.agentContact,
        serviceName: mobileMoneySettings.serviceName,
      });
    }
  }, [mobileMoneySettings]);

  const mobileMoneyValueHandler = (event) => {
    const { name, value } = event.target;
    SetGeneralValue({ ...generalValue, [name]: value });
  };

  const submitHandler = () => {
    dispatch(
      asyncUpdateMobileMoneySettings({
        obj: generalValue,
      })
    );
  };
                
  return (
    <div className="paste_here active">
      <div className="col-md-12 mt-20">
        <div className="row">
          <div className="col-md-6 form-group">
            <label
              for="max_time_for_find_drivers_for_regular_ride_in_minute"
              className="form-control-label"
            >
              Service Name
            </label>
            <input                       
              className="form-control"
              name="serviceName"
              type="text"
              value={generalValue ? generalValue.serviceName : ""}
              onChange={mobileMoneyValueHandler}
            />
          </div>
          <div className="col-md-6 form-group">
            <label
              for="max_time_for_find_drivers_for_regular_ride_in_minute"                
              className="form-control-label"
            >
              Account Number
            </label>
            <input
              className="form-control"
              name="accountNumber"
              type="text"
              value={generalValue ? generalValue.accountNumber : ""}
              onChange={mobileMoneyValueHandler}                          
            />
          </div>
          <div className="col-md-6 form-group">
            <label
              for="max_time_for_find_drivers_for_regular_ride_in_minute"
              className="form-control-label"
            >
              Account Title
            </label>
            <input
              className="form-control"
              name="accountTitle"                                 
              type="text"
              value={generalValue ? generalValue.accountTitle : ""}
              onChange={mobileMoneyValueHandler}
            />
          </div>

          <div className="col-md-6 form-group">
            <label
              for="max_time_for_find_drivers_for_regular_ride_in_minute"
              className="form-control-label"
            >
              Agent Contact
            </label>
            <input
              className="form-control"
              name="agentContact"
              type="text"
              value={generalValue ? generalValue.agentContact : ""}                                
              onChange={mobileMoneyValueHandler}
            />
          </div>
        </div>
      </div>
      <CustomButton
        className="btn btn-md btn-primary float-md-right"
        type="submit"
        value="Save"
        onClick={submitHandler}
        title="Update"
        isLoading={isLoading}
      />
    </div>
  );
}

export default MobileMoneySettings;
