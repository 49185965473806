import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { asyncGetAdminDashboard } from "../../redux/slices/dashboardSlice";
import RidesTable from "../RidesPage";
import DriverLocationsMap from "../DriversLocation/DriverLocationsMap";

function Dashboard() {
  const auth = useSelector((state) => state.auth);
  const adminDashboard = useSelector((state) => state.adminDashboard);
  const { data } = adminDashboard;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncGetAdminDashboard());
  }, [dispatch]);

  const { userInfo } = auth;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-flex align-items-center justify-content-between welcome-content">
              <div className="navbar-breadcrumb"></div>
              <div className=""></div>
            </div>
          </div>
          {/* <div className="col-lg-12">   
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx={9} cy={7} r={4} />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          {data?.verifiedDrivers ? data?.verifiedDrivers : 0}
                        </h5>
                        <p className="mb-0">Total Drivers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx={9} cy={7} r={4} />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <line x1={19} y1={7} x2={19} y2={10} />
                          <line x1={19} y1={14} x2={19} y2="14.01" />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          {data?.pendingDrivers ? data?.pendingDrivers : 0}
                        </h5>
                        <p className="mb-0">Pending Drivers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx={5} cy={18} r={3} />
                          <circle cx={19} cy={18} r={3} />
                          <polyline points="12 19 12 15 9 12 14 8 16 11 19 11" />
                          <circle cx={17} cy={5} r={1} />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          {" "}
                          {data?.totalPassengers
                            ? data?.totalPassengers
                            : 0}{" "}
                        </h5>
                        <p className="mb-0s">Total Riders</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx={7} cy={17} r={2} />
                          <circle cx={17} cy={17} r={2} />
                          <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          {" "}
                          {data?.totalRides ? data?.totalRides : 0}{" "}
                        </h5>
                        <p className="mb-0">Total Rides</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                          <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          {" "}
                          $
                          {data?.todayEarnings?.totalEarnings
                            ? data?.todayEarnings?.totalEarnings
                            : 0}{" "}
                        </h5>
                        <p className="mb-0">Today Earnings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                          <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          $
                          {data?.thisWeekEarnings?.totalEarnings
                            ? data?.thisWeekEarnings?.totalEarnings
                            : 0}{" "}
                        </h5>
                        <p className="mb-0">This Week Earnings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                          <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          $
                          {data?.thisMonthEarnings?.totalEarnings
                            ? data?.thisMonthEarnings?.totalEarnings
                            : 0}{" "}
                        </h5>
                        <p className="mb-0">This Month Earnings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card card-block card-stretch card-height">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mm-cart-image text-primary">
                        <svg
                          className="svg-icon svg-danger"
                          width={50}
                          height={52}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                          <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                        </svg>
                      </div>
                      <div className="mm-cart-text">
                        <h5 className="font-weight-700">
                          {" "}
                          $
                          {data?.totalEarnings?.totalEarnings
                            ? data?.totalEarnings?.totalEarnings
                            : 0}{" "}
                        </h5>
                        <p className="mb-0">Total Earnings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div class="row">
              <div class="col-md-3">
                <div class="b-shadow-4 rounded p-2 bg-col-new card">
                  <div
                    class="card-body p-2 clearfix"
                    style={{ minHeight: "240px" }}
                  >
                    <div class="driver-text mb-3">
                      <h4
                        class="text-primary card-headertext-blue h5 f-21 font-weight-bold font-weight-bold font-xs"
                        href="#"
                      >
                        TODAY'S TRIPS
                      </h4>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Active Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {data?.todayTrips?.activeTrips || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2" href="#">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Completed Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {data?.todayTrips?.completedTrips || 0}
                        </p>
                      </div>
                    </div>
                    {/* <div class="d-flex justify-content-between mb-2" href="#">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          No Show Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">0</p>
                      </div>
                    </div> */}
                    <div class="d-flex justify-content-between mb-2" href="#">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Canceled Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {data?.todayTrips?.canceledTrips || 0}
                        </p>
                      </div>
                    </div>
                    {/* <div class="d-flex justify-content-between mb-2" href="#">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Not Started Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">0</p>
                      </div>
                    </div> */}
                    <div class="d-flex justify-content-between mb-2" href="#">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Total Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {data?.todayTrips?.totalTrips || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="b-shadow-4 rounded p-2 bg-col-new card">
                  <div
                    class="card-body p-2 clearfix"
                    style={{ minHeight: "240px" }}
                  >
                    <div class="driver-text mb-3">
                      <h4
                        class="text-primary card-headertext-blue h5 f-21 font-weight-bold font-weight-bold font-xs"
                        href="#"
                      >
                        PAST TRIPS
                      </h4>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 20"
                        >
                          This Week Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0  dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 20"
                        >
                          {data?.pastTrips?.thisWeekTrips || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2" href="#">
                      <div class="driver-text">
                        <p
                          class=" f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 62"
                        >
                          Last Week Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p
                          class=" f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 62"
                        >
                          {data?.pastTrips?.lastWeekTrips || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0  dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 130"
                        >
                          This Month Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 130"
                        >
                          {data?.pastTrips?.thisMonthTrips || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 231"
                        >
                          Last Month Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 231"
                        >
                          {data?.pastTrips?.lastMonthTrips || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 2637"
                        >
                          Total Trips
                        </p>
                      </div>
                      <div class="driver-text">
                        <p
                          class="f-16 f-w-400 mb-0 dashboard-text"
                          title="oemc: 0, rtn: 0, shuttle van: 0, nemt: 2637"
                        >
                          {data?.pastTrips?.totalTrips || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="b-shadow-4 rounded p-2 bg-col-new card">
                  <div
                    class="card-body p-2 clearfix"
                    style={{ minHeight: "240px" }}
                  >
                    <div class="driver-text mb-3">
                      <h4 class="text-primary h5 f-21 font-weight-bold font-weight-bold font-xs">
                        USERS
                      </h4>
                    </div>

                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          Pending Drivers
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          {data?.drivers?.pendingDrivers || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          Verified Drivers
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          {data?.drivers?.verifiedDrivers || 0}
                        </p>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          Total Drivers
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          {data?.drivers?.totalDrivers || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          Online Drivers
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">15</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          Offline Drivers
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">7</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          Total Riders
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class="f-16 f-w-400 mb-0 dashboard-text">
                          {data?.drivers?.totalPassengers || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="b-shadow-4 rounded p-2 bg-col-new card">
                  <div
                    class="card-body p-2 clearfix"
                    style={{ minHeight: "240px" }}
                  >
                    <div class="driver-text mb-3">
                      <h4 class="text-primary h5 f-21 font-weight-bold font-weight-bold font-xs">
                        EARNINGS
                      </h4>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Today Earnings
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {userInfo?.country.currency || ""}{" "}
                          {data?.earnings?.todayEarnings || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Weekly Earnings
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {userInfo?.country.currency || ""}{" "}
                          {data?.earnings?.thisWeekEarnings || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Monthly Earnings
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {userInfo?.country.currency || ""}{" "}
                          {data?.earnings?.thisMonthEarnings || 0}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          Total Earnings
                        </p>
                      </div>
                      <div class="driver-text">
                        <p class=" f-16 f-w-400 mb-0 dashboard-text">
                          {userInfo?.country.currency || ""}{" "}
                          {data?.earnings?.totalEarnings || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            {/* <div className="card card-block card-stretch card-height"> */}
            {/* <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Recent Request</h4>
                </div>
                <div className="card-header-toolbar d-flex align-items-center"></div>
              </div> */}
            {userInfo.role !== "safety" && (
              <>
                <DriverLocationsMap />
                {/* <RidesTable /> */}
              </>
            )}

            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
