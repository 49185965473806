import React from "react";

function TableHead({
  itemsPerPage,
  setItemsPerPage,
  setCurrentPage,
  onChange,
  setSearchData,
  searchValueHandler,
}) {
  return (
    <div className="row align-items-center">
      <div className="col-md-2">
        <div className="dataTables_length">
          <label>
            Show{" "}
            <select
              name="dataTableBuilder_length"
              // aria-controls="dataTableBuilder"
              // className=""
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(e.target.value);
                setCurrentPage(1);
              }}
            >
              {[10, 25, 50, 100].map((current, i) => {
                return (
                  <option value={current} key={i}>
                    {current}
                  </option>
                );
              })}
            </select>
            entries
          </label>
        </div>
      </div>
      <div className="col-md-6" />
      <div className="col-md-4">
        <div className="dataTables_filter">
          <label>
            <input
              type="search"
              className="p-2"
              placeholder="Search"
              aria-controls="dataTableBuilder"
              onChange={(e) => searchValueHandler(e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
}

export default TableHead;
