import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "../../assets/css/custom.css";
import { IoCloseCircleSharp } from "react-icons/io5";

function ImagePopup({ show, handleClose, data }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreenTwo, setIsFullScreenTwo] = useState(false);
  //   const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseClick = () => {
    setIsFullScreen(false);
  };

  const handleImageClickSecond = () => {
    setIsFullScreenTwo(true);
  };
  const handleCloseClickSecond = () => {
    setIsFullScreenTwo(false);
  };

  const downloadImage = (imageUrl, fileName) => {
    // Create a hidden anchor element
    const link = document.createElement("a");
    link.style.display = "none";

    // Set the URL and the download attribute
    link.href = imageUrl;
    link.setAttribute("download", fileName);

    // Trigger a click event to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up the anchor element
    document.body.removeChild(link);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>View Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-2">
            <div className="m-2">
              <p>Front side</p>
              <div
                onClick={handleCloseClick}
                className={isFullScreen ? "fullscreen-container " : ""}
              >
                {isFullScreen && (
                  <button className="close-btn" onClick={handleCloseClick}>
                    <IoCloseCircleSharp size={24} color="#fff" />
                  </button>
                )}
                <img
                  src={data.frontSideUrl}
                  alt="Image"
                  className="img-fluid "
                  width="300px"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>

              <div>
                <Link onClick={() => handleImageClick()} to="" className="mr-2">
                  View
                </Link>
                <Link
                  onClick={() => downloadImage(data.frontSideUrl, "document")}
                  to=""
                >
                  Download
                </Link>
              </div>
            </div>
            {data.backSideUrl && data.backSideUrl !== "" && (
              <div className="m-2">
                <p>Back side</p>
                <div
                  onClick={handleCloseClickSecond}
                  className={isFullScreenTwo ? "fullscreen-containertwo  " : ""}
                >
                  {" "}
                  {isFullScreenTwo && (
                    <button className="close-btn" onClick={handleCloseClick}>
                      <IoCloseCircleSharp size={24} color="#fff" />
                    </button>
                  )}
                  <img
                    src={data.backSideUrl}
                    alt="Image"
                    className="img-fluid "
                    width="300px"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div>
                  <Link
                    onClick={() => handleImageClickSecond()}
                    to=""
                    className="mr-2"
                  >
                    View
                  </Link>
                  <Link
                    onClick={() => downloadImage(data.backSideUrl, "document")}
                    to=""
                  >
                    Download
                  </Link>
                </div>{" "}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <a href={data.frontSideUrl} download="image.jpg">
            <Button variant="primary">Download</Button>
          </a> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImagePopup;
