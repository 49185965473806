import React from "react";
import { Button, Modal } from "react-bootstrap";

function VerifyDriverModal({ data, open, onChange, onClose, submitHandler }) {
  return (
    <Modal className="" show={open} onHide={onClose} animation={false}>
      <Modal.Header>
        <Modal.Title> Verify Driver </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h6 className="my-2"> Status</h6>
          <select
            className="form-control"
            onChange={onChange}
            name="verification"
          >
            <option>Select status</option>
            <option value="Pending">Pending</option>
            <option value="Verified">Verified</option>
            <option value="Rejected">Rejected</option>
          </select>

          {data.verification === "Rejected" ? (
            <div className="mt-3 ">
              <h6 className="my-2"> Reason</h6>
              <textarea
                className="form-control"
                as="textarea"
                rows={3}
                name="rejectReason"
                onChange={onChange}
              />
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={submitHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerifyDriverModal;
