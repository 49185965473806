import React, { useEffect } from "react";
import {
  GoogleMap,
  Polyline,
  LoadScript,
  useLoadScript,
  PolylineF,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../constants";
import { useState } from "react";
import axios from "axios";

const MapWithRoute = ({
  rideWaypoints,
  waypointsToPickup,
  pickupLocation,
  dropoffLocation,
  startLocation,
}) => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const [activeMarker, setActiveMarker] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    // libraries: libraries,
  });

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker);
  };

  const [smoothPolyline, setSmoothPolyline] = useState([]);

  useEffect(() => {
    // Fetch smoothed polyline from Google Maps Roads API (Snap to Roads)
    if (rideWaypoints.length > 1) {
      const waypoints = rideWaypoints.map(
        (waypoint) => `${waypoint.coordinates[0]},${waypoint.coordinates[1]}`
      );
      fetchSnappedPoints(waypoints);
    }
  }, [rideWaypoints]);

  const fetchSnappedPoints = async (waypoints) => {
    try {
      const response = await axios.get(
        `https://roads.googleapis.com/v1/snapToRoads?path=${waypoints.join(
          "|"
        )}&interpolate=true&key=${GOOGLE_API_KEY}`
      );

      if (response.data.snappedPoints) {
        const snappedPolyline = response.data.snappedPoints.map((point) => ({
          lat: point.location.latitude,
          lng: point.location.longitude,
        }));

        setSmoothPolyline(snappedPolyline);
      }
    } catch (error) {
      console.error("Error fetching snapped points:", error);
    }
  };

  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={15}
      center={{
        lat: pickupLocation[0],
        lng: pickupLocation[1],
      }} // Center the map on the first point of your route
    >
      <PolylineF
        path={waypointsToPickup.map((x) => ({
          lat: x[0],
          lng: x[1],
        }))}
        geodesic={true}
        options={{
          strokeColor: "#000",
          strokeOpacity: 0.75,
          strokeWeight: 5,
          // icons: [
          //   {
          //     // icon: lineSymbol,
          //     offset: "0",
          //     repeat: "20px",
          //   },
          // ],
        }}
      />
      {smoothPolyline.length > 0 && (
        <PolylineF
          path={smoothPolyline}
          geodesic={true}
          options={{
            strokeColor: "green", // Change the color if needed
            strokeOpacity: 0.75,
            strokeWeight: 5,
          }}
        />
      )}
      <PolylineF
        path={rideWaypoints.map((x) => ({
          lat: x.coordinates[0],
          lng: x.coordinates[1],
        }))}
        geodesic={true}
        options={{
          strokeColor: "blue",
          strokeOpacity: 0.75,
          strokeWeight: 5,
          // icons: [
          //   {
          //     // icon: lineSymbol,
          //     offset: "0",
          //     repeat: "20px",
          //   },
          // ],
        }}
      />

      {startLocation && (
        <MarkerF
          position={{
            lat: startLocation[0],
            lng: startLocation[1],
          }}
          onMouseOver={() => handleMarkerClick(startLocation)}
          onMouseOut={() => handleMarkerClick(null)}
        >
          {activeMarker === startLocation && (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              {/* <div> */}
              <p className="fw-semibold">This is Driver start location!</p>
              {/* </div> */}
            </InfoWindowF>
          )}
        </MarkerF>
      )}
      {pickupLocation && (
        <MarkerF
          position={{
            lat: pickupLocation[0],
            lng: pickupLocation[1],
          }}
          onMouseOver={() => handleMarkerClick(pickupLocation)}
          onMouseOut={() => handleMarkerClick(null)}
        >
          {activeMarker === pickupLocation && (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              <div>
                <p className="fw-semibold">This is pickup location!</p>
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      )}
      {dropoffLocation && (
        <MarkerF
          position={{
            lat: dropoffLocation[0],
            lng: dropoffLocation[1],
          }}
          onMouseOver={() => handleMarkerClick(dropoffLocation)}
          onMouseOut={() => handleMarkerClick(null)}
        >
          {activeMarker === dropoffLocation && (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              {/* <div> */}
              <p className="fw-semibold">This is dropoff location!</p>
              {/* </div> */}
            </InfoWindowF>
          )}
        </MarkerF>
      )}
    </GoogleMap>
  );
};

export default MapWithRoute;
