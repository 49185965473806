import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table";

import { asyncGetDailySattlements } from "../../redux/slices/SattlementSlice";

function SattlementsPendingTable() {
  const auth = useSelector((state) => state.auth);
  const columns = [
    {
      displayName: "Driver Name",
      displayField: (e) => (
        <>
          {e?.driver?.firstName} {e?.driver?.lastName}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Total Amount Collected",
      displayField: (e) => (
        <>
          {e?.currency} {e?.totalAmountCollected}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Cash Collected",
      displayField: (e) => (
        <>
          {e?.currency} {e?.totalCashCollected}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Wallet Amount Received",
      displayField: (e) => (
        <>
          {e?.currency} {e?.totalAmountReceivedInWallet}
        </>
      ),

      searchable: true,
    },
    {
      displayName: "Total Commission Due",
      displayField: (e) => (
        <>
          {e?.currency} {e?.totalCommissionDue}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Due Date",
      displayField: (e) => <>{e?.date}</>,
      searchable: true,
    },
    {
      key: "CommissionPaid",
      displayName: "Commission Paid",
      displayField: (e) => (
        <>
          {e?.commissionPaid ? (
            <span className="text-capitalize badge bg-success">YES </span>
          ) : (
            <span className="text-capitalize badge bg-danger">NO </span>
          )}
        </>
      ),
      searchable: true,
    },

    // {
    //   displayName: "Action",
    //   key: "",
    //   displayField: (e) => (
    //     <div className="d-flex justify-content-start align-items-center">
    //       <Link className="mr-2" to={`/sattlements/edit/${e._id}`} title="Update Driver">
    //         <i className="fas fa-edit text-primary" />
    //       </Link>
    //       <Link className="mr-2" to={`details/${e._id}`}>
    //         <i className="fas fa-eye text-secondary" />
    //       </Link>
    //       <a
    //         className="mr-2 text-danger"
    //         href="javascript:void(0)"
    //         data--submit="rider36"
    //         data--confirmation="true"
    //         data-title="Delete Rider"
    //         title="Delete Rider"
    //         data-message="Are you sure you want to delete?"
    //       >
    //         <i className="fas fa-trash-alt" />
    //       </a>
    //     </div>
    //   ),
    //   searchable: true,
    // },
  ];

  const sattlements = useSelector((state) => state.sattlements);

  const { isLoading, list } = sattlements;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncGetDailySattlements());
  }, [dispatch, auth.country]);

  return (
    <div>
      <Table
        dataSource={list.filter((x) => !x?.commissionPaid)}
        isLoading={isLoading}
        columns={columns}
      />
    </div>
  );
}

export default SattlementsPendingTable;
