import TableHead from "./tableHead";
import TablePagination from "./tablePagination";
import TableRow from "./tableRow";
import DataNotFound from "../../assets/images/table-data-not-found.jpg";
import { useEffect, useState } from "react";

function Table({ title, columns, dataSource, onRowClick, isLoading }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);

  const searchDataHandlerOnTable = (event) => {
    let searchableColumns = columns.filter((x) => x.searchable);
    const newData = event.target.value.toLowerCase();
    const filteredItems = dataSource.filter((item) => {
      for (let i = 0; i < searchableColumns.length; i++) {
        let keySplirt = searchableColumns[i].key.split(".");
        let tempItem = item;
        for (let j = 0; j < keySplirt.length; j++) {
          tempItem = tempItem ? tempItem[keySplirt[j]] : undefined;
        }

        const value2 = tempItem?.toString().toLowerCase();

        if (value2 && value2?.includes(newData)) {
          return true;
        }
      }
      return false;
    });
    setCurrentItems(filteredItems);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentItems(dataSource.slice(indexOfFirstItem, indexOfLastItem));
  }, [indexOfFirstItem, indexOfLastItem, dataSource]);

  return (
    <div className="">
      <div
        id="dataTableBuilder_wrapper"
        className="dataTables_wrapper no-footer w-100"
      >
        <div className="px-4 pt-3 pb-0 m-0">
        <TableHead
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          setCurrentPage={setCurrentPage}
          onChange={searchDataHandlerOnTable}
        />

        </div>
        {" "}
        
        {/* <h2>{title}</h2> */}
        {isLoading &&
        dataSource &&
        Array.isArray(dataSource) &&
        dataSource.length == 0 ? (
          <div className="card">
            <img
              width="40%"
              className="mx-auto"
              src="https://hackernoon.com/images/0*4Gzjgh9Y7Gu8KEtZ.gif"
              alt="Loading..."
            />
          </div>
        ) : dataSource &&
          Array.isArray(dataSource) &&
          dataSource.length == 0 ? (
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <img
              src={DataNotFound}
              width="200"
              className="mx-auto"
              alt="No Data Found :("
            />
            <p>Data not found</p>
          </div>
        ) : (
          <div className="table-responsive ">
            <div
              id="dataTableBuilder_processing"
              className="dataTables_processing"
              style={{ display: "none" }}
            >
              Processing...
            </div>
            <table
              className="table w-100 dataTable no-footer"
              id="dataTableBuilder"
              role="grid"
              aria-describedby="dataTableBuilder_info"
              
            >
              <thead className="table-thead" >
                <tr role="row">
                  <th
                    title="No"
                    // width={60}
                    // className="sorting"
                    rowSpan={1}
                    colSpan={1}
                    // style={{ width: 60 }}
                    aria-label="No"
                  >
                    {" "}
                    S.No
                  </th>
                  {columns && Array.isArray(columns) && columns.length > 0
                    ? columns
                        .filter((x) => !x.hidden)
                        .map((x, i) => {
                          return (
                            <th
                              title="No"
                              // width={60}
                              // className="sorting"
                              rowSpan={1}
                              colSpan={1}
                              // style={{ width: 60 }}
                              aria-label="No"
                              key={i}
                            >
                              {x.displayName}
                            </th>
                          );
                        })
                    : null}
                </tr>
              </thead>
              <tbody>
                {currentItems &&
                Array.isArray(currentItems) &&
                currentItems.length > 0
                  ? currentItems.map((rowData, i) => (
                      <TableRow
                        srNo={indexOfFirstItem + i + 1}
                        key={i}
                        index={i}
                        rowData={rowData}
                        columns={columns}
                      />
                    ))
                  : null}
              </tbody>
            </table>

            <TablePagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={dataSource.length}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default Table;
