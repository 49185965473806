import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetDriversDocuments } from "../../redux/slices/driverSlice";
import { Link } from "react-router-dom";
import ImagePopup from "../../components/modals/imagePopup";
import { asyncGetSubmittedDocuments } from "../../redux/slices/documentSlice";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const SubmittedDocumentsTable = () => {
  const auth = useSelector((state) => state.auth);
  const submittedDocuments = useSelector(
    (state) => state.documents.submittedDocuments
  );

  const { isLoading, list } = submittedDocuments;
  // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  // const openMoreVertDropDown = () => {
  //   setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  // };

  const [selectedDocument, setSelectedDocument] = useState(null);

  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(asyncGetSubmittedDocuments());
  }, [auth.country]);

  const columns = [
    {
      displayName: "Driver Name",
      displayField: (e) => (
        <>
          {e?.firstName} {e?.lastName}
        </>
      ),
      searchable: true,
    },

    // {
    //   displayName: "Document Submitted",
    //   displayField: (e) => <>{e?.documentCount}</>,
    //   searchable: true,
    // },
    {
      displayName: "Assigned Vehicle",
      displayField: (e) => (
        <>
          {" "}
          {Object.keys(e?.assignedVehicle).length > 0 ? (
            <>
              {e?.assignedVehicle?.make} {e?.assignedVehicle?.model}{" "}
              {e?.assignedVehicle?.year} ({e?.assignedVehicle?.plateNumber})
            </>
          ) : (
            "N/A"
          )}{" "}
        </>
      ),
      searchable: true,
    },

    {
      displayName: "Documents Submitted",
      displayField: (e) => (
        <>
          {e?.documentCount && e?.documentCount > 0
            ? `( ${e?.documentCount} ) `
            : ""}{" "}
          {e?.documentsSubmitted.length > 0 ? (
            e?.documentsSubmitted.map((x) => " " + x?.documentType?.name) + ", "
          ) : (
            <span className="text-capitalize badge bg-danger">
              Not submitted
            </span>
          )}{" "}
        </>
      ),
      searchable: true,
    },
    {
      // key: "status",
      displayField: (e) => (
        <>
          {e.verification === "Pending" ? (
            <span className="text-capitalize badge bg-primary">
              {e.verification}
            </span>
          ) : e.verification === "Verified" ? (
            <span className="text-capitalize badge bg-success">
              {e.verification}
            </span>
          ) : e.verification === "Rejected" ? (
            <span className="text-capitalize badge bg-danger">
              {e.verification}
            </span>
          ) : (
            <></>
          )}
        </>
      ),
      displayName: "Verification",
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          {/* <Link className="mr-2" to={`edit/${e._id}`} title="Update Driver">
            <i className="fas fa-edit text-primary" />
          </Link> */}
          <Link
            className="mr-2"
            to={`${e._id}`}
            // onClick={() => setSelectedDocument(e)}
          >
            <i className="fas fa-eye text-secondary" />
          </Link>
        </div>
      ),
      searchable: true,
    },
  ];
  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {selectedDocument && (
        <ImagePopup
          show={selectedDocument}
          data={selectedDocument}
          handleClose={() => setSelectedDocument(null)}
        />
      )}
    </>
  );
};
export default SubmittedDocumentsTable;
