import React from "react";
import { ThreeDots } from "react-loader-spinner";

function DotsLoader({ dark }) {
  return (
    <div className="react-loader-spinner">
      <ThreeDots
        height="27"
        width="27"
        radius="9"
        color={dark ? "#000" : "#fff"}
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName="react-loader-spinner"
        visible={true}
      />
    </div>
  );
}

export default DotsLoader;
