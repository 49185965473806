import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { asyncGetDriverDetails } from "../../redux/slices/driverSlice";
import PageLoader from "../../components/loading/PageLoader";
import RatingComponent from "../../components/RatingComponent";
import { asyncGetSubmittedDocumentsView } from "../../redux/slices/documentSlice";
import { BUCKET_PUBLIC_URL } from "../../constants";

// const isLoading = false;

function SubmittedDocumentsDetails() {
  const SubmittedDocuments = useSelector((state) => state.documents);
  const { SubmittedDocumentsView } = SubmittedDocuments;
  const { data, isLoading } = SubmittedDocumentsView;

  // const { isLoading } = data;
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetSubmittedDocumentsView({ id: id }));
  }, []);

  const downloadImage = (imageUrl, fileName) => {
    // Create a hidden anchor element
    const link = document.createElement("a");
    link.style.display = "none";

    // Set the URL and the download attribute
    link.href = imageUrl;
    link.setAttribute("download", fileName);

    // Trigger a click event to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up the anchor element
    document.body.removeChild(link);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch">
            <div className="card-body p-0">
              <div className="d-flex justify-content-between align-items-center p-3">
                <h5 className="font-weight-bold">
                  View Driver Submitted Documents
                </h5>
                <Link
                  to="/documents/driver"
                  className="float-right btn btn-sm btn-primary"
                >
                  <i className="fa fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-block p-card">
              <div className="profile-box">
                <div className="profile-card rounded">
                  <img
                    src={`${BUCKET_PUBLIC_URL}${data?.driver?.profileImageUrl}`}
                    alt="01.jpg"
                    className="avatar-100 rounded d-block mx-auto img-fluid mb-3"
                  />
                  <h3 className="font-600 text-white text-center mb-0">
                    {data?.driver?.firstName} {data?.driver?.lastName}
                  </h3>
                  <p className="text-white text-center mb-5">
                    <span className="text-capitalize badge bg-success ">
                      {data?.driver?.status}
                    </span>
                  </p>
                </div>
                <div className="pro-content rounded">
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-envelope" />
                    </div>
                    <p className="mb-0 eml">{data?.driver?.email}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <p className="mb-0">{data?.driver?.phone}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i
                        className={`fas fa-${data?.driver?.gender?.toLowerCase()}`}
                      />
                    </div>
                    <p className="mb-0">{data?.driver?.gender}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    {data?.driver?.verification === "Verified" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-check`} />
                        </div>
                        <p className="mb-0">{data?.driver?.verification}</p>
                      </>
                    ) : data?.driver?.verification === "Pending" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fa fa-clock`} />
                        </div>
                        <p className="mb-0">
                          Verification {data?.driver?.verification}
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-ban`} />
                        </div>
                        <p className="mb-0">
                          Verification {data?.driver?.verification}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              {data?.documents && data?.documents.length > 0 ? (
                data?.documents?.map((x) => (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                          <h4 className="card-title">
                            {x?.documentType?.name} {`( ${x.status} )`}
                          </h4>
                        </div>
                      </div>
                      <div className="card-body pb-5">
                        <div className="d-flex gap-2">
                          <div className="m-2">
                            <h5>Front side:</h5>
                            <img
                              src={BUCKET_PUBLIC_URL + x.frontSideUrl}
                              alt="Image"
                              className="img-fluid"
                              width="300px"
                            />
                            <div>
                              <Link
                                onClick={() =>
                                  downloadImage(x.frontSideUrl, "document")
                                }
                                to=""
                              >
                                Download
                              </Link>
                            </div>
                          </div>
                          {x.backSideUrl && x.backSideUrl !== "" && (
                            <div className="m-2">
                              <h5>Back side:</h5>
                              <img
                                src={BUCKET_PUBLIC_URL + x.backSideUrl}
                                alt="Image"
                                className="img-fluid"
                                width="300px"
                              />
                              <div>
                                <Link
                                  onClick={() =>
                                    downloadImage(x.backSideUrl, "document")
                                  }
                                  to=""
                                >
                                  Download
                                </Link>
                              </div>{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>Documents Not Submitted</>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubmittedDocumentsDetails;
