import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FareForm from "./FareForm";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetVehicleCategories } from "../../redux/slices/vehicleCategorySlice";
import { asyncCreateRideFare } from "../../redux/slices/rideFareSlice";

const initialState = {};
function CreateFare() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const rideFares = useSelector((state) => state.rideFares);

  const {
    add: { isLoading },
  } = rideFares;

  const [formData, setFormData] = useState(initialState);

  const changeHandler = useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      asyncCreateRideFare({
        data: formData,
        callback: () => {
          navigate("/fares");
        },
      })
    );
  };

  useEffect(() => {
    dispatch(asyncGetVehicleCategories());
  }, [dispatch, auth.country]);

  return (
    <div>
      {/* <input
        name="_token"
        type="hidden"
        defaultValue="nKXO2rpGnpIJanM4AITcm75gWgwnlaw7rTcv6PKI"
      /> */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Add Fare</h4>
              </div>
            </div>
            <FareForm
              {...{ submitHandler, changeHandler, formData, isLoading }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFare;
