import React, { useEffect, useState } from "react";
import CustomSelectAsync from "../../components/CustomSelectAsync";
import CustomSelect from "../../components/CustomSelect";
import DropzoneUpload from "../../components/DropzoneUpload";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { asyncUpdateDocumentsCab } from "../../redux/slices/cabSlice";
import axiosPrivate from "../../services/api";
import moment from "moment";

function EditCabDocuments() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});

  const cabs = useSelector((state) => state.cabs);
  const { document } = cabs;
  const { isLoading, list } = document;

  const [requiredDocumentsList, setRequiredDocumentsList] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  const [customOption, setCustomOption] = useState(null);
  const [documentForCustom, setDocumentForCustom] = useState(null);

  useEffect(() => {
    const dataFound = list.find((x) => x._id === id);
    if (dataFound) {
      setFormData({
        documentFor: dataFound?.documentFor?._id,
        documentType: dataFound?.documentType?._id,
        expirationDate: dataFound?.expirationDate
          ? moment(dataFound?.expirationDate).format("YYYY-MM-DD")
          : "",
        frontSideUrl: dataFound?.frontSideUrl,
        backSideUrl: dataFound?.backSideUrl ? dataFound?.backSideUrl : "",
        status: dataFound?.status,
        modelNameOfTheDocumentFor: "Vehicle",
        rejectReason: dataFound.rejectReason,
      });

      setCustomOption({
        label: dataFound?.documentType?.name,
        value: dataFound?.documentType?._id,
      });

      setDocumentForCustom({
        label: `${dataFound?.documentFor?.make} ${dataFound?.documentFor?.model} ${dataFound?.documentFor?.plateNumber}`,
        value: dataFound.documentType._id,
      });

      getRequiredDocumentsList();
    } else {
      navigate("/documents/cab");
    }
  }, [navigate, list, id]);

  const getRequiredDocumentsList = async () => {
    try {
      const { data } = await axiosPrivate.get(`/requiredDocuments`);
      setRequiredDocumentsList(data.filter((x) => x.type === "Vehicle"));
    } catch (err) {
      console.log(err);
    }
  };
  // Define a function to load driver options from an API
  const loadDriverOptions = async (inputValue) => {
    // Make an API request to fetch driver options based on the inputValue
    const { data } = await axiosPrivate.get(
      `/vehicles/search?plateNumber=${inputValue}`
    );

    // Format the response data into options for the Select component
    const options = data.map((vehicle) => ({
      label: `${vehicle.make} ${vehicle.model} ${vehicle.year} ( ${vehicle.plateNumber} )`, // Replace with the driver's name property
      value: vehicle._id, // Replace with the driver's unique identifier property
    }));

    return options;
  };

  useEffect(() => {
    if (requiredDocumentsList.length > 0 && formData && formData.documentType)
      setSelectedDocumentType(
        requiredDocumentsList.find((x) => x._id === formData.documentType)
      );
  }, [formData, requiredDocumentsList]);

  const changeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      asyncUpdateDocumentsCab({
        obj: formData,
        id: id,
        callback: () => navigate("/documents/cab"),
      })
    );
  };

  const downloadImage = (imageUrl, fileName) => {
    // Create a hidden anchor element
    const link = document.createElement("a");
    link.style.display = "none";

    // Set the URL and the download attribute
    link.href = imageUrl;
    link.setAttribute("download", fileName);

    // Trigger a click event to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up the anchor element
    document.body.removeChild(link);
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Edit Cab Document</h4>
              </div>
            </div>
            <div className="card-body pb-5">
              <div className="new-user-info">
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="driver_id" className="form-control-label">
                      Select Vehicle <span className="text-danger">*</span>
                    </label>

                    {documentForCustom && (
                      <CustomSelectAsync
                        loadOptions={loadDriverOptions}
                        initialValue={documentForCustom}
                        name="documentFor"
                        placeholder="Search here"
                        onChange={changeHandler}
                        formData={formData}
                      />
                    )}

                    {/* <span
                    className="select2 select2-container select2-container--default"
                    dir="ltr"
                    data-select2-id={1}
                    style={{ width: "23.6px" }}
                  >
                    <span className="selection">
                      <span
                        className="select2-selection select2-selection--single"
                        role="combobox"
                        aria-haspopup="true"
                        aria-expanded="false"
                        tabIndex={0}
                        aria-disabled="false"
                        aria-labelledby="select2-driver_id-container"
                      >
                        <span
                          className="select2-selection__rendered"
                          id="select2-driver_id-container"
                          role="textbox"
                          aria-readonly="true"
                        >
                          <span className="select2-selection__placeholder">
                            Select Driver
                          </span>
                        </span>
                        <span
                          className="select2-selection__arrow"
                          role="presentation"
                        >
                          <b role="presentation" />
                        </span>
                      </span>
                    </span>
                    <span className="dropdown-wrapper" aria-hidden="true" />
                  </span> */}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="document_id" className="form-control-label">
                      Select Document <span className="text-danger">* </span>
                    </label>
                    {customOption && (
                      <CustomSelect
                        name="documentType"
                        placeholder="Select document"
                        options={requiredDocumentsList.map((x) => ({
                          label: x.name,
                          value: x._id,
                        }))}
                        initialValue={customOption}
                        // value={customOption}
                        onChange={changeHandler}
                      />
                    )}
                  </div>
                  {selectedDocumentType?.hasExpiry && (
                    <div className="form-group col-md-4">
                      <label
                        className="form-control-label"
                        htmlFor="expire_date"
                      >
                        Expiry Date{" "}
                        <span className="text-danger" id="has_expiry_date" />{" "}
                      </label>
                      <input
                        className="form-control min-datepicker flatpickr-input"
                        placeholder="Expiry Date"
                        type="date"
                        onChange={changeHandler}
                        name="expirationDate"
                        value={formData.expirationDate}
                      />
                    </div>
                  )}
                  <div className="form-group col-md-4">
                    <label htmlFor="is_verified" className="form-control-label">
                      Verification <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control "
                      name="status"
                      onChange={changeHandler}
                      value={formData.status}
                    >
                      <option value="Peding">Pending</option>
                      <option value="Verified">Verified</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </div>

                  {formData.status === "Rejected" ? (
                    <div className="form-group col-md-4">
                      <label htmlFor="driver_id" className="form-control-label">
                        Reject Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        style={{ lineHeight: "15px" }}
                        className="form-control min-datepicker flatpickr-input"
                        onChange={changeHandler}
                        name="rejectReason"
                        value={formData.rejectReason}
                      ></textarea>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label
                      className="form-control-label"
                      htmlFor="driver_document"
                    >
                      Upload Front Side{" "}
                      <span className="text-danger" id="document_required" />{" "}
                    </label>
                    <DropzoneUpload
                      name="frontSideUrl"
                      directory={`vehicle-uploads/${formData?.documentFor}/${selectedDocumentType?._id}/front/`}
                      onChange={changeHandler}
                      isEnabled={formData.documentFor && formData.documentType}
                      disableReason="Please select vehicle and document type first!"
                    />
                    <span className="selected_file" />
                  </div>
                  {selectedDocumentType?.hasBackSide && (
                    <div className="form-group col-md-4">
                      <label
                        className="form-control-label"
                        htmlFor="driver_document"
                      >
                        Upload Back Side{" "}
                        <span className="text-danger" id="document_required" />{" "}
                      </label>
                      <DropzoneUpload
                        name="backSideUrl"
                        directory={`driver-uploads/${formData?.driver}/${selectedDocumentType?._id}/back/`}
                        onChange={changeHandler}
                        isEnabled={formData.driver && formData.documentType}
                        disableReason="Please select driver and document type first!"
                      />
                      <span className="selected_file" />
                    </div>
                  )}

                  {formData?.frontSideUrl !== "" && (
                    <div className="form-group col-md-4">
                      <label
                        className="form-control-label"
                        htmlFor="driver_document"
                      >
                        Uploaded documents
                        <span
                          className="text-danger"
                          id="document_required"
                        />{" "}
                      </label>
                      <div className="d-flex gap-2">
                        <div className="m-2">
                          <h5>Front side:</h5>
                          <img
                            src={formData.frontSideUrl}
                            alt="Document Front"
                            className="img-fluid"
                            width="300px"
                          />
                          <div>
                            <Link
                              onClick={() =>
                                downloadImage(formData.frontSideUrl, "document")
                              }
                              to=""
                            >
                              Download
                            </Link>
                          </div>
                        </div>
                        {formData.backSideUrl &&
                          formData.backSideUrl !== "" && (
                            <div className="m-2">
                              <h5>Back side:</h5>
                              <img
                                src={formData.backSideUrl}
                                alt="Document Back"
                                className="img-fluid"
                                width="300px"
                              />
                              <div>
                                <Link
                                  onClick={() =>
                                    downloadImage(
                                      formData.backSideUrl,
                                      "document"
                                    )
                                  }
                                  to=""
                                >
                                  Download
                                </Link>
                              </div>{" "}
                            </div>
                          )}
                      </div>
                      {/* <div>        
                          {formData.frontSideUrl && formData.frontSideUrl ? (
                            <img src={formData.frontSideUrl} height={150} />
                          ) : (
                            <></>
                          )}
                          {selectedDocumentType?.hasBackSide &&
                          formData.backSideUrl &&
                          formData.backSideUrl ? (
                            <img src={formData.backSideUrl} height={150} />
                          ) : (
                            <></>
                          )}
                        </div> */}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <CustomButton
                  className="btn btn-md btn-primary float-right"
                  type="submit"
                  title="Submit"
                  isLoading={isLoading}
                />
              </div>
            </div>
            {/* <hr /> */}
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditCabDocuments;
