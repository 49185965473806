import React from "react";
import { Accordion, Badge, Button } from "react-bootstrap";
import { BUCKET_PUBLIC_URL } from "../../constants";
import notPaper from "../../../src/assets/images/NotPaper.webp";
import moment from "moment";

function ApplicationAccordion({ setShow, data, setVerifyDriverDocumentModal }) {
  return (
    <div className="row">
      <div className="col-xl-9 col-lg-8 col-md-7">
        <div className="card h-100 px-3">
          <Accordion className="mt-4 mb-0" defaultActiveKey={["0"]} alwaysOpen>
            {data?.documents?.map((items, index) => (
              <Accordion.Item eventKey={index}>
                {/* {console.log(items, "items")} */}
                <Accordion.Header>
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ gap: 8 }}
                    >
                      <p className="m-0"> {items?.name}</p>

                      {/* {items?.isSubmitted ? (
                        <Badge bg="success">Submitted</Badge>
                      ) : (
                        <Badge bg="danger">Missing</Badge>
                      )} */}
                    </div>

                    <div className="">
                      {items?.status === "Rejected" || true ? (
                        <>
                          <Badge
                            bg={
                              items?.status === "Pending"
                                ? "warning"
                                : items?.status === "Verified"
                                ? "success"
                                : items?.status === "Rejected"
                                ? "danger"
                                : "danger"
                            }
                          >
                            {items?.status}
                          </Badge>
                        </>
                      ) : null}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex gap-4 mt-3">
                    <div className="card mr-3 p-3">
                      <h6 className="card-title mr-2 d-flex align-items-center justify-content-between">
                        <span className="mr-3">Front Side</span>
                        {items?.isSubmitted && items?.frontSideUrl ? (
                          <Badge bg="success">Submitted</Badge>
                        ) : (
                          <Badge bg="danger">Missing</Badge>
                        )}
                      </h6>
                      <div
                        className="text-center"
                        onClick={() =>
                          setShow(`${BUCKET_PUBLIC_URL}${items?.frontSideUrl}`)
                        }
                      >
                        <img
                          src={
                            items?.frontSideUrl
                              ? `${BUCKET_PUBLIC_URL}${items?.frontSideUrl}`
                              : notPaper
                          }
                          width={100}
                          height={100}
                          alt="Logo"
                          className="my-2"
                        />
                      </div>
                    </div>

                    {items?.hasBackSide ? (
                      <div className="card mr-3 p-3">
                        <h6 className="card-title mr-2 d-flex align-items-center justify-content-between">
                          <span className="mr-3">Back Side</span>
                          {items?.isSubmitted && items?.backSideUrl ? (
                            <Badge bg="success">Submitted</Badge>
                          ) : (
                            <Badge bg="danger">Not submitted</Badge>
                          )}
                        </h6>{" "}
                        <div
                          className="text-center"
                          onClick={() =>
                            setShow(
                              `${BUCKET_PUBLIC_URL}${items?.backSideUrl}`
                            )
                          }
                        >
                          <img
                            src={
                              items?.backSideUrl
                                ? `${BUCKET_PUBLIC_URL}${items?.backSideUrl}`
                                : notPaper
                            }
                            width={100}
                            height={100}
                            alt="Logo"
                            className="my-2"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {items?.isSubmitted ? (
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div>
                        {items?.hasExpiry ? (
                          <div>
                            <h6 className="card-title "> Expiration Date: </h6>
                            <p className="m-0">
                              {moment(
                                items?.expirationDate,
                                "YYYY-MM-DD"
                              ).format("MM-DD-YYYY")}
                            </p>
                          </div>
                        ) : null}
                      </div>{" "}
                      <>
                        {items?.status !== "Verified" ? (
                          <div className="d-flex justify-content-end my-2">
                            <Button
                              variant="secondary"
                              className=""
                              style={{ fontSize: 14 }}
                              onClick={() =>
                                setVerifyDriverDocumentModal(items)
                              }
                            >
                              Verify Document
                            </Button>{" "}
                          </div>
                        ) : null}
                      </>
                    </div>
                  ) : null}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-md-5">
        <div className="card h-100">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <h5 className="card-title">Actions</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationAccordion;
