import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetDrivers = createAsyncThunk(
  "getDrivers",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/drivers${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetDriversLocation = createAsyncThunk(
  "asyncGetDriversLocation",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/drivers/locations");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetDriversDocuments = createAsyncThunk(
  "asyncGetDriversDocuments",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    console.log(country, "country")
    try {
      const response = await axiosPrivate.get(
        `/documents/driver${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetDriversEarning = createAsyncThunk(
  "asyncGetDriversEarning",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/drivers/earnings${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncPostDriversDocuments = createAsyncThunk(
  "asyncPostDriversDocuments",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;
    try {
      const response = await axiosPrivate.post("/documents", obj);
      toast.success("Driver created Documents Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateDriverDocument = createAsyncThunk(
  "asyncPostDriversDocuments",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { id, data, callback } = payload;
    try {
      const response = await axiosPrivate.patch(`/documents/${id}`, data);
      toast.success("Driver Document updated Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetDriverDetails = createAsyncThunk(
  "getDriverDetails",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { id } = obj;
    try {
      const response = await axiosPrivate.get(`/drivers/${id}`);
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateDriver = createAsyncThunk(
  "createDrivers",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;

    try {
      const response = await axiosPrivate.post("/drivers", obj);
      toast.success("Driver Created Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdateDriver = createAsyncThunk(
  "updateDriver",
  async (
    { id, obj, callback },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/drivers/${id}`, obj);
      toast.success("Driver Updated Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

const driverSlice = createSlice({
  name: "drivers",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    driverDetails: { isLoading: false, data: {}, isError: false },
    documents: { isLoading: false, list: [], isError: false },
    addDocument: { isLoading: false, data: {}, isError: false },
    driverLocations: { isLoading: false, list: [], isError: false },
    driversEarnings: { isLoading: false, list: [], isError: false },
  },
  extraReducers: (builder) => {
    // Cases for Get Drivers

    builder.addCase(asyncGetDrivers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetDrivers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetDrivers.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Get DriverDocuments

    builder.addCase(asyncGetDriversDocuments.pending, (state, action) => {
      state.documents.isLoading = true;
    });
    builder.addCase(asyncGetDriversDocuments.fulfilled, (state, action) => {
      state.documents.isLoading = false;
      state.documents.list = action.payload;
      state.documents.isError = false;
      state.documents.message = null;
    });
    builder.addCase(asyncGetDriversDocuments.rejected, (state, action) => {
      state.documents.isLoading = false;
      state.documents.message = action.payload;
      state.documents.isError = true;
    });

    // asyncGetDriversEarning get
    builder.addCase(asyncGetDriversEarning.pending, (state, action) => {
      state.driversEarnings.isLoading = true;
    });
    builder.addCase(asyncGetDriversEarning.fulfilled, (state, action) => {
      state.driversEarnings.isLoading = false;
      state.driversEarnings.list = action.payload;
      state.driversEarnings.isError = false;
      state.driversEarnings.message = null;
    });
    builder.addCase(asyncGetDriversEarning.rejected, (state, action) => {
      state.driversEarnings.isLoading = false;
      state.driversEarnings.message = action.payload;
      state.driversEarnings.isError = true;
    });

    // Cases for Get DriverLocations

    builder.addCase(asyncGetDriversLocation.pending, (state, action) => {
      state.driverLocations.isLoading = true;
    });
    builder.addCase(asyncGetDriversLocation.fulfilled, (state, action) => {
      state.driverLocations.isLoading = false;
      state.driverLocations.list = action.payload;
      state.driverLocations.isError = false;
      state.driverLocations.message = null;
    });
    builder.addCase(asyncGetDriversLocation.rejected, (state, action) => {
      state.driverLocations.isLoading = false;
      state.driverLocations.message = action.payload;
      state.driverLocations.isError = true;
    });

    builder.addCase(asyncPostDriversDocuments.pending, (state, action) => {
      state.addDocument.isLoading = true;
    });
    builder.addCase(asyncPostDriversDocuments.fulfilled, (state, action) => {
      state.addDocument.isLoading = false;
      state.addDocument.data = action.payload;
      state.addDocument.isError = false;
      state.addDocument.message = null;
    });
    builder.addCase(asyncPostDriversDocuments.rejected, (state, action) => {
      state.addDocument.isLoading = false;
      state.addDocument.message = action.payload;
      state.addDocument.isError = true;
    });

    // Cases for Get SingleDrivers
    builder.addCase(asyncGetDriverDetails.pending, (state, action) => {
      state.driverDetails.isLoading = true;
    });
    builder.addCase(asyncGetDriverDetails.fulfilled, (state, action) => {
      state.driverDetails.isLoading = false;
      state.driverDetails.data = action.payload;
      state.driverDetails.isError = false;
      state.driverDetails.message = null;
    });
    builder.addCase(asyncGetDriverDetails.rejected, (state, action) => {
      state.driverDetails.isLoading = false;
      state.driverDetails.message = action.payload;
      state.driverDetails.isError = true;
    });

    // Cases for Add Driver
    builder.addCase(asyncCreateDriver.pending, (state, action) => {
      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateDriver.fulfilled, (state, action) => {
      state.add = { isLoading: false, message: "Driver Created Successfully!" };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateDriver.rejected, (state, action) => {
      state.add = { isLoading: false, isError: true, message: action.payload };
    });

    // Cases for Update Driver
    builder.addCase(asyncUpdateDriver.pending, (state, action) => {
      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateDriver.fulfilled, (state, action) => {
      state.add = { isLoading: false, message: "Driver Updated Successfully!" };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateDriver.rejected, (state, action) => {
      state.update = {
        isLoading: false,
        isError: true,
        message: action.payload,
      };
    });
  },
  reducers: {},
});

// export const {} = driverSlice.actions;
export default driverSlice.reducer;
