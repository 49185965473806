import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const contactAPI = createApi({
  reducerPath: "contactAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchContact"],
  endpoints: (builder) => ({
    getContact: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: `/contacts`,
        method: "GET",
        params: { page, limit },
      }),
      providesTags: ["refetchContact"],
    }),

    UpdateContact: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/contacts/${id}`,
        method: "PATCH",
        body: payload,
      }),
    }),
  }),
});

export const { useGetContactQuery, useUpdateContactMutation } = contactAPI;
