import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const walletAPI = createApi({
  reducerPath: "walletAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchWallets"],
  endpoints: (builder) => ({
    getWallets: builder.query({
      query: ({ page = 1, limit = 10, query, country }) => ({
        url: `/wallets`,
        method: "GET",
        params: {
          page,
          limit,
          country: country ? country : undefined,
          query: query ? query : undefined,
        },
      }),
      providesTags: ["refetchWallets"],
    }),
  }),
});

export const { useGetWalletsQuery } = walletAPI;
