import dashboard from "../../assets/images/dashboard/dashboard.png";
import track from "../../assets/images/dashboard/track.png";
// import country from "../../assets/images/dashboard/country.png";
import document from "../../assets/images/dashboard/document.png";
// import logout from "../../assets/images/dashboard/logout.png";
import services from "../../assets/images/dashboard/services.png";
import passenger from "../../assets/images/dashboard/passenger.png";
import ride from "../../assets/images/dashboard/ride.png";
import wallets from "../../assets/images/dashboard/wallets.png";
import driver from "../../assets/images/dashboard/driver.png";
import truckAssests from "../../assets/images/dashboard/truckAssests.png";
import setting from "../../assets/images/dashboard/setting.png";
import user from "../../assets/images/dashboard/user.png";
// import { GiWorld } from "react-icons/gi";

const sidebarList = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icons: dashboard,
    role: ["super_admin", "country_admin", "safety"],
  },
  {
    name: "Track",
    path: "/track",
    icons: track,
    role: ["super_admin", "country_admin"],
  },

  {
    name: "User",
    path: "/countries/add",
    icons: user,
    role: ["super_admin", "country_admin"],
    subItems: [
      {
        name: "add",
        path: "/users/add",
        icons: "fas fa-plus-square",
        role: ["super_admin", "country_admin"],
      },
      {
        name: "User List",
        path: "/users",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin"],
        manage: ["super_admin", "country_admin"],
      },
    ],
  },

  {
    name: "passenger",
    path: "/passengers/add",
    icons: passenger,
    role: ["super_admin", "country_admin", "city_admin"],
    subItems: [
      {
        name: "Add Passenger",
        path: "/passengers/add",
        icons: "fas fa-plus-square",
        role: ["country_admin", "city_admin"],
        manage: ["super_admin", "country_admin", "city_admin"],
      },
      {
        name: "Passengers List",
        path: "/passengers",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
    ],
  },
  {
    name: "wallets",
    path: "/passengers/add",
    icons: wallets,
    role: ["super_admin", "country_admin", "city_admin"],
    subItems: [
      {
        name: "Wallets List",
        path: "/wallets",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Wallets Topup Requests",
        path: "/wallets/topup-requests",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
    ],
  },

  {
    name: "Driver",
    path: "/passengers/add",
    icons: driver,
    role: ["super_admin", "country_admin", "safety"],
    subItems: [
      {
        name: "Add Driver",
        path: "/drivers/add",
        icons: "fas fa-plus-square",
        role: ["country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Driver List",
        path: "/drivers",
        icons: "fas fa-plus-square",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },

      {
        name: "Pending Drivers",
        path: "/drivers/pending",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },

      {
        name: " Driver Application",
        path: "/drivers/application",
        icons: "fas fa-list",
        role: ["safety"],
        manage: ["country_admin", "city_admin", "safety"],
      },

      {
        name: "Driver Locations",
        path: "/drivers/locations",
        icons: "fas fa-map",
        role: ["super_admin", "country_admin"],
      },

      {
        name: "Driver Earnings",
        path: "/drivers/earnings",
        icons: "fas fa-money-bill",
        role: ["super_admin", "country_admin"],
        manage: ["super_admin", "country_admin", "city_admin"],
      },
    ],
  },
  {
    name: "Service",
    path: "/passengers/add",
    icons: services,
    role: ["super_admin", "country_admin", "city_admin"],
    subItems: [
      {
        name: "Categories List",
        path: "/categories",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Add Category",
        path: "/categories/add",
        icons: "fas fa-plus-square",
        role: ["country_admin"],
        manage: ["country_admin", "city_admin"],
      },

      {
        name: "Fares List",
        path: "/fares",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin"],
        manage: ["country_admin", "city_admin"],
      },

      {
        name: "Add Fare",
        path: "/fares/add",
        icons: "fas fa-plus-square",
        role: ["country_admin"],
        manage: ["country_admin", "city_admin"],
      },

      {
        name: "Fee List",
        path: "/fees",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin"],
        manage: ["country_admin", "city_admin"],
      },
    ],
  },

  {
    name: "Document",
    path: "/passengers/add",
    icons: document,
    role: ["super_admin", "country_admin", "city_admin"],
    subItems: [
      {
        name: "Submitted Documents List",
        path: "/documents/driver",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
      // {
      //   name: "Documents List",
      //   path: "/documents",
      //   icons: "fas fa-plus-square",
      //   role: ["super_admin", "country_admin", "city_admin"],
      //   manage: ["country_admin", "city_admin"],
      // },
      // {
      //   name: "Individual Cab Documents",
      //   path: "/documents/cab",
      //   icons: "fas fa-plus-square",
      //   role: ["super_admin", "country_admin", "city_admin"],
      //   manage: ["country_admin", "city_admin"],
      // },
      {
        name: "Required Documents List",
        path: "/documents/required",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
    ],
  },

  {
    name: "Ride",
    path: "/passengers/add",
    icons: ride,
    role: ["super_admin", "country_admin", "city_admin"],
    subItems: [
      {
        name: "Rides List",
        path: "/rides",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Ride Request List",
        path: "/ride-requests",
        icons: "fas fa-plus-square",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin", "city_admin"],
      },
    ],
  },

  {
    name: "Payment Methods",
    path: "/payment-methods",
    icons: truckAssests,
    role: ["country_admin"],
    manage: ["country_admin"],
  },
  {
    name: "Setting",
    path: "/passengers/add",
    icons: setting,
    role: ["super_admin", "country_admin", "city_admin"],
    subItems: [
      {
        name: "Countries",
        path: "/countries",
        icons: "fas fa-globe",
        role: ["super_admin"],
        manage: ["country_admin", "city_admin"],
      },

      {
        name: "Country Settings",
        path: "/setting",
        icons: "fas fa-cog",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Cabs List",
        path: "/cabs",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Pending Cabs",
        path: "/cabs/pending",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Clear Sattlement Requests",
        path: "/sattlements/clear-requests",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },
      {
        name: "Pending Sattlements",
        path: "/sattlements/pending",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin"],
      },

      {
        name: "Sattlement List",
        path: "/sattlements",
        icons: "fas fa-list",
        role: ["super_admin", "country_admin", "city_admin"],
        manage: ["country_admin", "city_admin", "city_admin"],
      },
      {
        name: "Faqs",
        path: "/faqs",
        icons: "fa fa-question",
        role: ["super_admin", "country_admin"],
        manage: ["super_admin", "country_admin"],
      },

      {
        name: "Queries",
        path: "/queries",
        icons: "fa fa-phone",
        role: ["super_admin", "country_admin"],
        manage: ["super_admin", "country_admin"],
      },
    ],
  },

  // {
  //   name: "Complaint",
  //   path: "/complaints",
  //   icons: "fas fa-file-alt",
  //   role: ["super_admin", "country_admin"],
  //   manage: ["super_admin", "country_admin"],
  // },
];

export default sidebarList;
