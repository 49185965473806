import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetCabs = createAsyncThunk(
  "getCabs",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/vehicles${country ? `?country=${country}` : ""}`
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateCabs = createAsyncThunk(
  "asyncCreateCabs",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;

    try {
      const response = await axiosPrivate.post("/vehicles", obj);
      toast.success("Cabs created document Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetCabDocuments = createAsyncThunk(
  "asyncGetCabDocuments",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;

    try {
      const response = await axiosPrivate.get(
        `/documents/vehicle${country ? `?country=${country}` : ""}`
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncPostCabDocuments = createAsyncThunk(
  "asyncPostCabDocuments",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { obj, callback } = payload;

    try {
      const response = await axiosPrivate.post("/documents", obj);
      toast.success("Cab created document Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateCab = createAsyncThunk(
  "createCabs",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/vehicles", obj);
      toast.success("Cab created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateCab = createAsyncThunk(
  "updateCab",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/vehicles/${id}`, data);
      toast.success("Cab created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateDocumentsCab = createAsyncThunk(
  "asyncUpdateDocumentsCab",
  async (
    { id, obj, callback },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/documents/${id}`, obj);
      toast.success("Cab created Update Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCabsUpdate = createAsyncThunk(
  "asyncCabsUpdate",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { id, obj, callback } = payload;
    try {
      const response = await axiosPrivate.patch(`/vehicles/${id}`, obj);
      callback && callback();
      toast.success("Cabs Update Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const cabSlice = createSlice({
  name: "cabs",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    document: { isLoading: false, list: [], isError: false },
    addDocument: { isLoading: false, data: {}, isError: false },
  },
  extraReducers: (builder) => {
    // Cases for Get Cabs

    builder.addCase(asyncGetCabs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCabs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCabs.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Get Cabs documents

    builder.addCase(asyncGetCabDocuments.pending, (state, action) => {
      state.document.isLoading = true;
    });
    builder.addCase(asyncGetCabDocuments.fulfilled, (state, action) => {
      state.document.isLoading = false;
      state.document.list = action.payload;
      state.document.isError = false;
      state.document.message = null;
    });
    builder.addCase(asyncGetCabDocuments.rejected, (state, action) => {
      state.document.isLoading = false;
      state.document.message = action.payload;
      state.document.isError = true;
    });

    // Cases for post Cabs documents

    builder.addCase(asyncPostCabDocuments.pending, (state, action) => {
      state.addDocument.isLoading = true;
    });
    builder.addCase(asyncPostCabDocuments.fulfilled, (state, action) => {
      state.addDocument.isLoading = false;
      state.addDocument.data = action.payload;
      state.addDocument.isError = false;
      state.addDocument.message = null;
    });
    builder.addCase(asyncPostCabDocuments.rejected, (state, action) => {
      state.addDocument.isLoading = false;
      state.addDocument.message = action.payload;
      state.addDocument.isError = true;
    });

    // Cases for Add Cab
    builder.addCase(asyncCreateCab.pending, (state, action) => {
      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateCab.fulfilled, (state, action) => {
      state.add = { isLoading: false, message: "Cab Created Successfully!" };
      state.list.push(action.payload);
    });
    builder.addCase(asyncCreateCab.rejected, (state, action) => {
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Cab
    builder.addCase(asyncUpdateCab.pending, (state, action) => {
      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateCab.fulfilled, (state, action) => {
      state.add = { isLoading: false, message: "Cab Updated Successfully!" };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });
    builder.addCase(asyncUpdateCab.rejected, (state, action) => {
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {},
});

// export const {} = cabSlice.actions;
export default cabSlice.reducer;
