import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { asyncGetDriverDetails } from "../../redux/slices/driverSlice";
import PageLoader from "../../components/loading/PageLoader";
import RatingComponent from "../../components/RatingComponent";
import { BUCKET_PUBLIC_URL } from "../../constants";

function DriverDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const drivers = useSelector((state) => state.drivers.driverDetails);
  const { isLoading, data } = drivers;

  const {
    personalDetails,
    vehicleDetails,
    stats,
    ratings,
    submittedDocuments,
    requiredDocuments,
  } = data;

  useEffect(() => {
    dispatch(asyncGetDriverDetails({ id: id }));
  }, [dispatch, id]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch">
            <div className="card-body p-0">
              <div className="d-flex justify-content-between align-items-center p-3">
                <h5 className="font-weight-bold">View Driver</h5>
                <Link
                  to="/drivers"
                  className="float-right btn btn-sm btn-primary"
                >
                  <i className="fa fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-block p-card">
              <div className="profile-box">
                <div className="profile-card rounded">
                  <img
                    src={`${BUCKET_PUBLIC_URL}${personalDetails?.profileImageUrl}`}
                    alt="01.jpg"
                    className="avatar-100 rounded d-block mx-auto img-fluid mb-3"
                  />
                  <h3 className="font-600 text-white text-center mb-0">
                    {personalDetails?.firstName} {personalDetails?.lastName}
                  </h3>
                  <p className="text-white text-center mb-5">
                    <span className="text-capitalize badge bg-success">
                      {personalDetails?.status}
                    </span>
                  </p>
                </div>
                <div className="pro-content rounded">
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-envelope" />
                    </div>
                    <p className="mb-0 eml">{personalDetails?.email}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <p className="mb-0">{personalDetails?.phone}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i
                        className={`fas fa-${personalDetails?.gender?.toLowerCase()}`}
                      />
                    </div>
                    <p className="mb-0">{personalDetails?.gender}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    {personalDetails?.verification === "Verified" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-check`} />
                        </div>
                        <p className="mb-0">{personalDetails?.verification}</p>
                      </>
                    ) : personalDetails?.verification === "Pending" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fa fa-clock`} />
                        </div>
                        <p className="mb-0">
                          Verification {personalDetails?.verification}
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-ban`} />
                        </div>
                        <p className="mb-0">
                          Verification {personalDetails?.verification}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {vehicleDetails && (
              <div className="card card-block">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title mb-0">Vehicle Details</h4>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3"></div>
                    <div className="col-9">
                      <p className="mb-0">
                        {vehicleDetails.category
                          ? vehicleDetails.category.name
                          : "N/A"}
                      </p>
                      <p className="mb-0">
                        {vehicleDetails.year} {vehicleDetails.make}{" "}
                        {vehicleDetails.model} ( {vehicleDetails.color})
                      </p>
                      <p className="mb-0">{vehicleDetails.plateNumber}</p>
                      {/* <p className="mb-0">848454046</p> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <div className="row">
              {/* <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Wallet Balance</p>
                      <p />
                      <h5> USD 10 </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Completed Rides</p>
                      <p />
                      <h5>{stats?.completedRides} </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Cancelled Rides</p>
                      <p />
                      <h5> {stats?.cancelledRides} </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Ratings</p>
                      <p />
                      <h5>
                        {ratings?.average} ( {ratings?.count} ){" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Joined On</p>
                      <p />
                      <h5>
                        {moment(personalDetails?.createdAt)?.format(
                          "DD MMM, YYYY"
                        )}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Total Earnings</p>
                      <p />
                      <h5>N/A </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Cash Earnings</p>
                      <p />
                      <h5>N/A </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Wallet Earnings</p>
                      <p />
                      <h5>N/A </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <p className="mb-1">Wallet Balance</p>
                      <p />
                      <h5>1494.04₹ </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="top-block-one">
                      <div className="">
                        <p className="mb-1">Total Withdraw</p>
                        <p />
                        <h5>N/A </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {requiredDocuments && (
              <div className="card card-block card-stretch">
                <div className="card-header">
                  <h4>Documents</h4>
                </div>
                <div className="card-body">
                  {requiredDocuments?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Document Name</th>
                            <th>Document Type</th>
                            <th>Document Submitted</th>
                            <th>Expiry Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {requiredDocuments.map((document, index) => {
                            const documentFound = submittedDocuments.find(
                              (y) => y.documentType === document._id
                            );

                            return (
                              <tr key={index}>
                                <td>{document.name}</td>
                                <td>{document.type}</td>
                                <td>{documentFound ? "Yes" : "No"}</td>
                                <td>
                                  {documentFound && document.hasExpiry
                                    ? moment(
                                        documentFound?.expirationDate
                                      ).format("DD MMM, YYYY")
                                    : "N/A"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p>No documents available for this driver.</p>
                  )}
                </div>
              </div>
            )}
            {ratings && (
              <div className="card card-block">
                <div className="card-header">
                  <h4>Rating & Reviews ( {ratings?.list?.length} )</h4>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    {ratings?.list?.map((x) => (
                      <li className="list-group-item">
                        <h5 className="mb-1">Shayan Ali</h5>
                        <RatingComponent
                          iconSize={22}
                          rating={x.rating}
                          readOnly
                        />
                        <p className="mb-1">{x.feedback}</p>
                        <small></small>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DriverDetails;
