import React from "react";
import { Link } from "react-router-dom";
import SattlementTable from "./SattlementTable";

function AmmountSattlements() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Sattlements List</h4>
              </div>
            </div>
            <SattlementTable />
            {/* <Table /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmmountSattlements;
