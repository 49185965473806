import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { rideAPI } from "./services/rideAPI";

import { contactAPI } from "./services/contactAPI";
import { notificationAPI } from "./services/notificationAPI";
import { applicationAPI } from "./services/applicationAPI";
import { paymentAPI } from "./services/paymentAPI";
import rootReducer from "./rootReducer";
import { walletAPI } from "./services/walletAPI";
import { flatFeeApi } from "./services/flatFeeApi";
import { userAPI } from "./services/userAPI";
import { driversAPI } from "./services/driversAPI";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rideAPI.middleware,
      userAPI.middleware,
      contactAPI.middleware,
      notificationAPI.middleware,
      applicationAPI.middleware,
      paymentAPI.middleware,
      walletAPI.middleware,
      flatFeeApi.middleware,
      driversAPI.middleware,
    ),
});

setupListeners(store.dispatch);

// export default store;
export default store;
