import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const asyncGetCountries = createAsyncThunk(
  "asyncGetCountries",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/countries");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateCountry = createAsyncThunk(
  "asyncCreateCountry",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { payload, callback } = obj;
      const response = await axiosPrivate.post("/countries", payload);
      toast.success("Country Created Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateCountry = createAsyncThunk(
  "asyncUpdateCountry",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { id, data, callback } = obj;
      const response = await axiosPrivate.patch(`/countries/${id}`, data);
      toast.success("Update Country Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

const countrySlice = createSlice({
  name: "countries",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetCountries.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCountries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCountries.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
});

// export const {} = countrySlice.actions;
export default countrySlice.reducer;
