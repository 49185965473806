import React from "react";

function TableRow({ index, srNo, rowData, columns }) {
  return (
    <tr className="odd" key={index}>
      <td className="">
        {srNo} {")"}
      </td>

      {columns.map((e, ind) => {
        if (!e.hidden) {
          return (
            <td className="" key={ind}>
              {e.displayField
                ? e.displayField(rowData)
                : rowData[e.key]?.length > 0
                ? rowData[e.key]
                : "N/A"}
            </td>
          );
        } else <></>;
      })}
    </tr>
  );
}

export default TableRow;
