import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetAdminDashboard = createAsyncThunk(
  "asyncGetAdminDashboard",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/stats/getAdminDashboard");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const adminDashboardSlice = createSlice({
  name: "adminDashboardSlice",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    data: {},
    isError: false,
    add: {},
    update: {},
  },

  extraReducers: (builder) => {
    // Cases for Get Cabs
    builder.addCase(asyncGetAdminDashboard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetAdminDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetAdminDashboard.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
});

// export const {} = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;
