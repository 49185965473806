import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../components/CustomButton";
import { asyncChangePassword } from "../../redux/slices/authSlice";
import toast from "react-hot-toast";

function ChangePassword() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const [passwordDetails, setPasswordDetails] = useState({});

  const handleChange = (evnet) => {
    const { name, value } = evnet.target;

    setPasswordDetails({
      ...passwordDetails,
      [name]: value,
    });
  };

  const updatePasswordHandler = (e) => {
    e.preventDefault();

    if (passwordDetails.newPassword === passwordDetails.currentPassword) {
      toast.error("Current and new password can't be same!");
      return;
    } else if (
      passwordDetails.newPassword !== passwordDetails.confirmPassword
    ) {
      toast.error("New and confirm password does not match!");
      return;
    }

    dispatch(
      asyncChangePassword({
        obj: {
          newPassword: passwordDetails.newPassword,
          currentPassword: passwordDetails.currentPassword,
        },
      })
    );
  };

  return (
    <div className="tab-content">
      <form onSubmit={updatePasswordHandler}>
        <div className="paste_here active">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="form-group has-feedback">
                <label
                  for="old_password"
                  className="form-control-label col-md-12"
                >
                  Old Password <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    className="form-control"
                    id="old_password"
                    placeholder="Old Password"
                    required
                    name="currentPassword"
                    type="password"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group has-feedback">
                <label for="password" className="form-control-label col-md-12">
                  New Password <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    className="form-control"
                    id="password"
                    placeholder="New Password"
                    required
                    name="newPassword"
                    type="password"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group has-feedback">
                <label
                  for="password-confirm"
                  className="form-control-label col-md-12"
                >
                  Confirm New Password <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    className="form-control"
                    id="password-confirm"
                    placeholder="Confirm New Password"
                    required
                    name="confirmPassword"
                    type="password"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group ">
                <div className="col-md-12">
                  <CustomButton
                    title="Update"
                    className="btn btn-md btn-primary float-md-right mt-15"
                    type="submit"
                    isLoading={auth.isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
