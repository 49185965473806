import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { toggleOpenAndClose } from "../../redux/slices/TrackToggleSlice";

function TrackingSidebar({ driversList, selectedDriver, setSelectedDriver }) {
  const [searchQuery, setSearchQuery] = useState("");
  // const [trackOpen, setTrackOpen] = useState(true);

  const selectDriverHandler = (data) => {
    setSelectedDriver(data);
  };

  const isActive = useSelector((state) => state?.TrackToggle?.isActive);
  console.log(isActive, "isActive");
  return (
    <>
      {isActive && (
        <div
          className="show"
          style={{
            width: "350px",
            height: "100%",
            transition: "transform 0.3s",
            transform: "translateX(0px)",
            overflowY: "scroll",
            background: "#353535",
          }}
        >
          <div className="map-sidebar">
            <div
              className="position-sticky top-0 sticky-top bg-secondary"
              style={{ padding: "10px 10px" }}
            >
              <input
                type="text"
                placeholder="Search Driver"
                className="map-search-bar w-100"
                style={{ padding: "10px 10px ", borderRadius: "5px" }}
                defaultValue=""
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {/* <div className="d-flex justify-content-center w-100">
          <ul className="date-display d-flex align-items-center">
            <button className="btn btn-secondary py-0">
              <i className="fa fa-angle-left" />
            </button>
            <h6 className="pl-3 pr-3 m-0" id="date-info">
              <input
                type="date"
                className="form-control height-35 f-14"
                defaultValue="2024-09-23"
              />
            </h6>
            <button className="btn btn-secondary py-0">
              <i className="fa fa-angle-right" />
            </button>
          </ul>
        </div> */}
            </div>
            <div className="map-asset-list  " style={{ padding: "0px 20px" }}>
              {driversList
                .filter((x) =>
                  `${x.driver.firstName} ${x.driver.lastName}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((x) => {
                  if (x.driver) {
                    return (
                      <div
                        className="map-asset-item my-3 bg-primary p-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => selectDriverHandler(x)}
                      >
                        {/* <p>
                    <strong>Asset#: 22299</strong>{" "}
                  </p> */}
                        <p className="mb-0">
                          <i className="fa fa-user" aria-hidden="true" />{" "}
                          {x.driver?.firstName} {x.driver?.lastName}
                        </p>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TrackingSidebar;
