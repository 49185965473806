import React, { useEffect, useState } from "react";
import TrackingSidebar from "./TrackingSidebar";
import TrackingMap from "./TrackingMap";
import socket from "../../services/socket";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


function TrackingPage() {
  const auth = useSelector((state) => state.auth);
  const [driversList, setdriversList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);

  console.log("auth=>", auth.userInfo);
  useEffect(() => {
    if (auth?.userInfo?._id) {
      socket.emit("start-tracking", { user: auth.userInfo._id }, (res) => {
        console.log("res=>", res);
        if (res.success) {
          setdriversList(res.data.filter((x) => x.driver) || []);
        }
      });
      socket.on("tracking-data", (res) => {
        console.log("tracking data=>", res);
        if (res.success) {
          setdriversList(res.data.filter((x) => x.driver));
        }
      });
    }

    return () => {
      socket.off("tracking-data");
    };
  }, [auth.userInfo]);


 const param = useLocation()
  console.log(param.pathname, "param")
  useEffect(()=> {
    if(param.pathname){
      document.body.classList.add("sidebar-main");
    }
    return () => {
      document.body.classList.remove("sidebar-main");
    };

  },[param.pathname])


  return (
    <div className="d-flex h-100 w-100">
      <TrackingSidebar
        driversList={driversList}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
      />
      <TrackingMap driversList={driversList} selectedDriver={selectedDriver} />
    </div>
  );
}

export default TrackingPage;
