import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
// import AdminDashboard from "./pages/adminPages/AdminDashboardPage";

import "./assets/css/all.min.css";

import "./assets/css/backend-bundle.min.css";
import "./assets/css/backend.css";
// import "./assets/css/all.min.css";
// import "https://cdn.jsdelivr.net/npm/remixicon@3.4.0/fonts/remixicon.css";
// import "./assets/css/select2.min.css";
// import "./assets/css/jquery-confirm.min.css";
// import "./assets/css/intlTelInput.css";
import "./assets/css/custom.css";

// import "./assets/css/bootstrap-icons.css";
// import "./assets/css/bootstrap-timepicker.min.css";
// import "./assets/css/datepicker.min.css";
// import "./assets/css/font-awesome.css";
// import "./assets/css/font-awesome.min.css";
// import "./assets/css/main.css";
// import "./assets/css/main.css.map";
// import "./assets/css/main.min.css";
// import "./assets/css/select2.min.css";
// import "./assets/css/simple-line-icons.css";
// import "./assets/css/custom-css.css";
// import "./assets/css/login.css";

// import "./assetsgeneral/css/font-awesome.css";
// import "./assetsgeneral/css/font-awesome.min.css";
// import "./assetsgeneral/css/style.css";
// import "./assetsgeneral/fonts/fontawesome-webfont.eot";
// import "./assetsgeneral/fonts/fontawesome-webfont.svg";
// import "./assetsgeneral/fonts/fontawesome-webfont.ttf";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff2";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff2";

// import AdminBookingPage from "./pages/adminPages/AdminBookingPage";
// import AdminDispatchingPage from "./pages/adminPages/AdminDispatchingPage";
// import AdminClientViewPage from "./pages/adminPages/AdminClientViewPage";
// import AdminReportsPage from "./pages/adminPages/AdminReportsPage";
// import AdminTrackPage from "./pages/adminPages/AdminTrackPage";
// import AdminSettingViewCompanyInfoPage from "./pages/adminPages/AdminSettingViewCompanyInfoPage";
// import AdminSettingVehiclePage from "./pages/adminPages/AdminSettingVehiclePage";
// import AdminSettingInvoicingPage from "./pages/adminPages/AdminSettingInvoicingPage";
// import AdminSettingPaymentsPage from "./pages/adminPages/AdminSettingPaymentsPage";
// import AdminUserPage from "./pages/adminPages/AdminUserPage";
// import AdminPermissionCreatePermission from "./pages/adminPages/AdminPermissionCreatePermission";
// import AdminPermissionPermissionPage from "./pages/adminPages/AdminPermissionPermissionPage";
// import AdminAddUserPage from "./pages/adminPages/AdminAddUserPage";
// import AdminBookingTomorrowPage from "./pages/adminPages/AdminBookingTomorrowPage";
// import AdminBookingTodayPage from "./pages/adminPages/AdminBookingTodayPage";
// import AdminUserViewPage from "./pages/adminPages/AdminUserViewPage";
// import LoginPage from "./pages/loginPage/LoginPage";
// import AdminClientAddComponentPage from "./pages/adminPages/AdminClientAddComponentPage";
// import AdminClientEditComponentPage from "./pages/adminPages/AdminClientEditComponentPage";
// import AdminAddVehiclePage from "./pages/adminPages/AdminAddVehiclePage";
// import Demo from "./pages/Demo";
// import Demo2 from "./pages/Demo2";
// import ModalTesting from "./pages/adminPages/ModalTesting";
// import AdminDispatchingSchedullingOrderPage from "./pages/adminPages/AdminDispatchingSchedullingOrderPage";
// import { useSelector } from "react-redux";
// import Unauthorized from "./pages/Unauthorized";
// import Checkuser from "./pages/Checkuser";
// import AdminRoutePage from "./pages/adminPages/AdminRoutesPage";
// import AdminClientFullDetailPage from "./pages/adminPages/AdminClientFullDetailPage";
// import AdminVehicleFullDetailPage from "./pages/adminPages/AdminVehicleFullDetailPage";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AdminSettingGenerateInvoicePage from "./pages/adminPages/AdminSettingGenerateInvoicePage";
// import AdminAddRoutePage from "./pages/adminPages/AdminAddRoutePage";
// import AdminOffHourSettingPage from "./pages/adminPages/AdminOffHourSettingPage";
// import AdminRouteDetailsPage from "./pages/adminPages/AdminRouteDetailsPage";
// import AdminEditRoutePage from "./pages/adminPages/AdminEditRoutePage";
// import AdminCreateReturnRoutePage from "./pages/adminPages/AdminCreateReturnRoutePage";
// import AdminRoutesLogPage from "./pages/adminPages/AdminRoutesPage/AdminRoutesLogPage";

// import AdminDispatchingPendingOrderPage from "./pages/adminPages/AdminDispatchingPendingOrderPage";
// import Demo3 from "./pages/Demo3";
// import AdminDispatchEditPage from "./pages/adminPages/AdminDispatchEditPage";
// import AdminMessageComposePage from "./pages/adminPages/AdminMessageComposePage";
// import AdminMessageInboxViewPage from "./pages/adminPages/AdminMessageInboxViewPage";
// import AdminMessagingThreadPage from "./pages/adminPages/AdminMessagingThreadPage";
// import AdminNotificationsPage from "./pages/adminPages/AdminNotificationsPage";
// import AdminBookingView from "./pages/adminPages/AdminBookingView";
// import AdminOtherSettingPage from "./pages/adminPages/AdminOtherSettingPage";
// import AdminUserLogInActivitiesPage from "./pages/adminPages/AdminUserLogInActivitiesPage";
// import AdminPermissionEditModalPage from "./pages/adminPages/AdminPermissionEditModalPage";
import RidesPage from "./pages/RidesPage";
import RideDetails from "./pages/RideDetails";

// import DashboardPage from "./pages/dashboard";

import Drivers from "./pages/Drivers";
import AddDriverPage from "./pages/AddDriverPage";
// import UsersPage from "./pages/Users";

import Cabs from "./pages/Cabs";
import Fares from "./pages/Fares";

import DashboardPage from "./pages/Dashboard";
import Users from "./pages/Users";
import AdminLayout from "./layout/AdminLayout";
import LoginPage from "./pages/loginPage/LoginPage";
// import Faq from "./pages/landingWebsitePages/Faq";

import { Toaster } from "react-hot-toast";
import Test from "./pages/test";
import CreateFare from "./pages/Fares/createFare";
import CabCategories from "./pages/CabCategories";
import CreateCabCategory from "./pages/CabCategories/createCabCategory";
import UpdateFare from "./pages/Fares/updateFare";
//Passenger
import Passengers from "./pages/Passengers";
import PassengerDetails from "./pages/PassengerDetails";
import EditPassenger from "./pages/EditPassenger";
import AddPassenger from "./pages/AddPassenger";
import PageUnderDevelopment from "./pages/PageUnderDevelopment";
import Wallets from "./pages/Wallets";
import WalletTopups from "./pages/WalletTopups";
import EditDriver from "./pages/EditDriver";
import DriverDetails from "./pages/DriverDetails";
import DriverDocuments from "./pages/DriverDocuments";
import AddDriverDocument from "./pages/AddDriverDocument";
import CabDocuments from "./pages/CabDocuments";
import AddCabDocument from "./pages/AddCabDocument";
import PendingCabsPage from "./pages/PendingCabs";
import RequiredDocuments from "./pages/RequiredDocuments";
import AddRequiredDocument from "./pages/AddRequiredDocument";
import EditCab from "./pages/EditCab";
import AddCab from "./pages/AddCab";
import EditCabDocuments from "./pages/EditCabDocuments";
import EditDriverDocument from "./pages/EditDriverDocument";
import DriversLocation from "./pages/DriversLocation";
import AmmountSattlements from "./pages/AmountSattlements";
import SattlementsPending from "./pages/PendingSattlements";
import ClearSattlementRequestsPage from "./pages/ClearSattlementRequestsPage";
import DriversPending from "./pages/DriversPending";
// import WalletTransactions from "./pages/WalletTransactions";
import SubmittedDocuments from "./pages/SubmittedDocuments";
import RideRequestsPage from "./pages/RideRequestsPage";
import SubmittedDocumentsDetails from "./pages/SubmittedDocumentsDetails";
import EditSubmittedDocuments from "./pages/EditSubmittedDocuments";
import RequiredDocumentsUpdate from "./pages/RequiredDocumentsUpdate";
import Permission from "./pages/Permission";
import ProfilePage from "./pages/ProfilePage";
import Setting from "./pages/Settings";
import DriverEarnings from "./pages/DriverEarnings";
// import CountryPassenger from "./pages/Country/AddCountry";
// import CountryTable from "./pages/Country/CountryTable";
// import CountryForm from "./pages/Country/countryForm";
import AddCountry from "./pages/Country/AddCountry";
import CountryPage from "./pages/Country";
import EditCountry from "./pages/Country/EditCountry";
import AddUser from "./pages/Users/AddUser";
import UserPage from "./pages/Users";
import EditUser from "./pages/Users/EditUser";
import Home from "./pages/home";
import AllowCompoment from "./utils/allowCompoment";
import sidebarList from "./layout/AdminLayout/MenuSidebar";
// import CheckPermission from "./utils/checkPermission";
import { useLocation } from "react-router-dom";
import Faqs from "./pages/faqs";
import Queries from "./pages/Queries/index";
import EditVehicleCategory from "./pages/CabCategories/editCabCategory";
import DriversApplication from "./pages/DriverApplication";
import EditApplication from "./pages/EditApplication/page";
import PaymentMethod from "./pages/PaymentMethod";
import FlatFeePage from "./pages/FlatFee";
import CreateFlatFee from "./pages/FlatFee/CreateFee";
import UpdateFlatFee from "./pages/FlatFee/UpdateFee";
import TrackingPage from "./pages/TrackingPage";

const App = () => {
  // const auth = useSelector((state) => state.auth);

  const location = useLocation();
  const manageRoleAccess = sidebarList.find((items) =>
    items?.subItems?.find((subitems) => subitems?.path === location?.pathname)
  );
  const allowPermissionAccess = manageRoleAccess?.subItems?.find(
    (subitems) => subitems?.path === location?.pathname
  )?.role;

  return (
    <>
      <Toaster />
      {/* <AdminHeader />
      <AdminSidebar /> */}
      <Routes>
        <Route
          path=""
          element={
            <AdminLayout>
              <Outlet />
            </AdminLayout>
          }
        >
          <Route path="/rides" element={<RidesPage />} />
          <Route path="/rides/:id" element={<RideDetails />} />
          <Route path="/ride-requests" element={<RideRequestsPage />} />

          {/* Passengers */}
          <Route path="/passengers" element={<Passengers />} />
          <Route path="/passengers/add" element={<AddPassenger />} />
          <Route path="/passengers/edit/:id" element={<EditPassenger />} />

          <Route path="/countries/add" element={<AddCountry />} />
          <Route path="/countries" element={<CountryPage />} />
          <Route path="/countries/edit/:id" element={<EditCountry />} />

          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users" element={<UserPage />} />
          <Route path="/users/edit/:id" element={<EditUser />} />

          <Route
            path="/passengers/details/:id"
            element={<PassengerDetails />}
          />

          {/* Wallets */}
          <Route path="/wallets" element={<Wallets />} />
          <Route path="/wallets/topup-requests" element={<WalletTopups />} />
          {/* <Route
            path="/wallets/transactions"
            element={<WalletTransactions />}
          /> */}

          {/* Chat */}
          <Route path="/drivers" element={<Drivers />} exact />
          <Route path="/drivers/pending" element={<DriversPending />} exact />
          <Route
            path="/drivers/application"
            element={<DriversApplication />}
            exact
          />
          <Route
            path="/drivers/application/:id"
            element={<EditApplication />}
            exact
          />
          <Route path="/drivers/add" element={<AddDriverPage />} exact />
          <Route path="/drivers/edit/:id" element={<EditDriver />} exact />
          <Route
            path="/drivers/details/:id"
            element={<DriverDetails />}
            exact
          />
          <Route
            path="/drivers/locations"
            element={<DriversLocation />}
            exact
          />

          <Route path="/drivers/earnings" element={<DriverEarnings />} />

          <Route path="/users" element={<Users />} exact />
          {/* <Route path="/users/add" element={<AddUserPage />} exact /> */}
          <Route path="/cabs" element={<Cabs />} />
          <Route path="/cabs/add" element={<AddCab />} />
          <Route path="/cabs/pending" element={<PendingCabsPage />} />
          <Route path="/cabs/edit/:id" element={<EditCab />} />
          <Route path="/categories" element={<CabCategories />} />
          <Route path="/categories/:id" element={<EditVehicleCategory />} />
          <Route path="/categories/add" element={<CreateCabCategory />} />
          {/* <Route path="/categories/add" element={<EditCabCategory />} /> */}
          <Route path="/fares" element={<Fares />} />
          <Route path="/fares/add" element={<CreateFare />} />
          <Route path="/fares/edit/:id" element={<UpdateFare />} />

          {/* Flat Fee */}
          <Route path="/fees" element={<FlatFeePage />} />
          <Route path="/fees/add" element={<CreateFlatFee />} />
          <Route path="/fees/edit/:id" element={<UpdateFlatFee />} />

          <Route path="/payment-methods" element={<PaymentMethod />} />
          {/* Ammount Sattlements */}
          <Route
            path="/sattlements/clear-requests"
            element={<ClearSattlementRequestsPage />}
          />
          <Route path="/sattlements" element={<AmmountSattlements />} />
          <Route
            path="/sattlements/pending"
            element={
              <AllowCompoment allow={allowPermissionAccess}>
                <SattlementsPending />
              </AllowCompoment>
            }
          />
          <Route
            path="/documents/required"
            element={<RequiredDocuments />}
            exact
          />
          <Route
            path="/documents/required/edit/:id"
            element={<RequiredDocumentsUpdate />}
            exact
          />
          <Route
            path="/documents/required/add"
            element={<AddRequiredDocument />}
            exact
          />
          <Route
            path="/documents/driver"
            element={<SubmittedDocuments />}
            exact
          />
          <Route
            path="/documents/driver/:id"
            element={<SubmittedDocumentsDetails />}
            exact
          />
          <Route
            path="/documents/driver/edit/:id"
            element={<EditSubmittedDocuments />}
            exact
          />
          <Route path="/documents/cab" element={<CabDocuments />} exact />
          <Route path="/documents/cab/add" element={<AddCabDocument />} exact />
          <Route
            path="/documents/cab/edit/:id"
            element={<EditCabDocuments />}
            exact
          />

          <Route path="/documents" element={<DriverDocuments />} exact />
          {/* <Route path="/documents/driver" element={<DriverDocuments />} exact /> */}
          <Route path="/documents/add" element={<AddDriverDocument />} exact />
          <Route
            path="/documents/edit/:id"
            element={<EditDriverDocument />}
            exact
          />

          {/* <Route
            path="/documents/driver/edit/:id"
            element={<EditSubmittedDocuments />}
            exact
          /> */}
          {/* setting Permission */}
          <Route path="/permission" element={<Permission />} exact />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/setting" element={<Setting />} />
          {/* faqs */}
          <Route path="/faqs" element={<Faqs />} exact />
          <Route path="/queries" element={<Queries />} exact />

          {/* <Route path="/cabs/add" element={<AddUserPage />} /> */}
          <Route path="/track" element={<TrackingPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="*" element={<PageUnderDevelopment />} />
        </Route>

        <Route index element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </>
  );
};
export default App;
