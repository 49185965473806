import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";

import { Link, useLocation } from "react-router-dom";
import { asyncGetCountries } from "../../redux/slices/countrySlice";
import CheckPermission from "../../utils/checkPermission";

const CountryTable = () => {
  const auth = useSelector((state) => state.auth);

  const location = useLocation();

  const columns = [
    {
      displayField: (e) => <>{e?.name}</>,
      displayName: "Name",
      searchable: true,
      key: "name",
    },
    {
      displayField: (e) => <>{e.shortName}</>,
      displayName: "Short Name",
      searchable: true,
      key: "short name",
    },
    {
      displayField: (e) => <>{e.code}</>,
      displayName: "Code",
      searchable: true,
      key: "code",
    },

    {
      displayField: (e) => <>{e.currency}</>,
      displayName: "Currency",
      searchable: true,
      key: "currency",
    },

    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link
            className="mr-2"
            title="Update Rider"
            to={`/countries/edit/${e._id}`}
          >
            <i className="fas fa-edit text-primary" />
          </Link>
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];
  const wallets = useSelector((state) => state.countries);

  const { isLoading, list } = wallets;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetCountries());
  }, [dispatch, auth.country]);

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
    </>
  );
};
export default CountryTable;
