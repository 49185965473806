import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncPostVehicleCategories } from "../../redux/slices/vehicleCategorySlice";
import { useNavigate } from "react-router-dom";
import DropzoneUpload from "../../components/DropzoneUpload";
import CustomButton from "../../components/CustomButton";

function CategoryForm({
  categoriesHandler,
  categoriesSubmit,
  setCategoriesData,
  categoriesData,
  isLoading,
}) {
  // const [categoriesData, setCategoriesData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const { id } = userInfo;

  // const categoriesHandler = (event) => {
  //   const { name, value } = event.target;
  //   setCategoriesData({
  //     ...categoriesData,
  //     [name]: value,
  //   });
  // };

  // const categoriesSubmit = () => {
  //   dispatch(
  //     asyncPostVehicleCategories({
  //       obj: categoriesData,
  //       callback: () => {
  //         navigate("/categories");
  //       },
  //     })
  //   );
  // };

  const [isUploading, setUpLoading] = useState(false);

  return (
    <div className="d-flex flex-column w-tables rounded mt-3 form_Add_cate">
      <div
        id="leads-table_wrapper"
        className="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <form onSubmit={categoriesSubmit} className="p-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group my-3">
                <label className="f-14 text-dark-grey mb-12" for="base_fair">
                  Category Name
                </label>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  id="base_fair"
                  placeholder="Enter name"
                  name="name"
                  value={categoriesData.name}
                  onChange={categoriesHandler}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="per_kilo_charge"
                >
                  Engine CC
                </label>
                <input
                  type="number"
                  className="form-control height-35 f-14"
                  id="per_kilo_charge"
                  placeholder="Engine CC"
                  name="engineCC"
                  value={categoriesData.engineCC}
                  onChange={categoriesHandler}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="waiting_charge"
                >
                  Seating space
                </label>
                <input
                  type="number"
                  className="form-control height-35 f-14"
                  id="waiting_charge"
                  placeholder="Enter seating space"
                  name="seatingSpace"
                  value={categoriesData.seatingSpace}
                  onChange={categoriesHandler}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="form-control-label" htmlFor="driver_document">
                Upload Category Image{" "}
                <span className="text-danger" id="document_required" />{" "}
              </label>
              <DropzoneUpload
                name="imageUrl"
                directory={`assets/vehicle-categories/${id}/`}
                onChange={categoriesHandler}
                setUpLoading={setUpLoading}
                isEnabled={true}
                disableReason="Please select driver and document type first!"
              />
              <span className="selected_file" />
            </div>
            <div className="col-lg-12">
              <div
                className="form-group my-3"
                style={{ marginTop: "1.5em !important" }}
              >
                <CustomButton
                  type="submit"
                  title={"Submit"}
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={categoriesSubmit}
                  disabled={isUploading}
                  isLoading={isLoading}
                ></CustomButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CategoryForm;
