import React from "react";
import { Link } from "react-router-dom";
import DriversEarningsTable from "./DriversEarningsTable";

function DriverEarnings() {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-block card-stretch card-height">
              {" "}
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title mb-0">Drivers Earnings </h4>
                </div>
              </div>
              <DriversEarningsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverEarnings;
