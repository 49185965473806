import DriverLocationsMap from "./DriverLocationsMap";

function DriversLocation() {
  return (
    <>
      {/* <div className="card card-block card-stretch card-height">
        {" "}
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title mb-0">Locations List</h4>
          </div>
        </div>
      </div> */}

      <DriverLocationsMap />
    </>
  );
}

export default DriversLocation;
