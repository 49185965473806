import React, { useEffect, useState } from "react";
import GeneralSettings from "./GeneralSettings";
import MobileSettings from "./MobileMoneySettings";
import WalletSettings from "./WalletSettings";
import RideSettings from "./RideSettings";
import { useDispatch } from "react-redux";
import { asyncGetCompanySetting } from "../../redux/slices/settingSlice";

const tabs = [
  { name: "General Settings", content: <GeneralSettings /> },
  { name: "Mobile Money Settings", content: <MobileSettings /> },
  { name: "Wallet Settings", content: <WalletSettings /> },
  { name: "Ride Settings", content: <RideSettings /> },
];

function Setting() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    dispatch(asyncGetCompanySetting());
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch">
            <div className="card-body p-0">
              <div className="d-flex justify-content-between align-items-center p-3">
                <h5 className="font-weight-bold">Setting</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-tabs" role="tablist">
                {tabs.map((x, index) => (
                  <li
                    className="nav-item"
                    onClick={() => setSelectedTab(index)}
                  >
                    <a
                      href="javascript:void(0)"
                      data-target=".paste_here"
                      className={`nav-link ${
                        selectedTab === index ? "active" : ""
                      }`}
                      data-toggle="tabajax"
                      rel="tooltip"
                    >
                      {" "}
                      {x.name}
                    </a>
                  </li>
                ))}
              </ul>
              <div className="tab-content">{tabs[selectedTab].content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
