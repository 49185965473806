import React from "react";

import CustomButton from "../../components/CustomButton";

function FaqsForm({ submit, handleInputChange, formData }) {
  return (
    <>
      <form onSubmit={submit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="first_name" className="form-control-label">
              Subject <span className="text-danger">*</span>
            </label>
            <input
              placeholder="subject"
              className="form-control"
              required
              name="subject"
              type="text"
              onChange={handleInputChange}
              value={formData.subject}
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="first_name" className="form-control-label">
              Title<span className="text-danger">*</span>
            </label>
            <input
              placeholder="title"
              className="form-control"
              required
              name="title"
              type="text"
              onChange={handleInputChange}
              value={formData.title}
            />
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="first_name" className="form-control-label">
              For <span className="text-danger">*</span>
            </label>

            <select
              className="form-control height-35 f-14"
              id="currency"
              name="for"
              onChange={handleInputChange}
              //   value={formData.currency}
              value={formData.for}
              required
            >
              <option> Please Select </option>
              <option value={"Driver"}>Driver</option>
              <option value={"Passenger"}>Passenger</option>
            </select>
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="first_name" className="form-control-label">
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              placeholder="Description"
              className="form-control"
              required
              name="description"
              type="text"
              onChange={handleInputChange}
              value={formData.description}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <CustomButton
            className="btn btn-md mb-4 btn-primary float-right"
            title="Submit"
            // isLoading={isLoading}
          />
        </div>
      </form>
    </>
  );
}

export default FaqsForm;
