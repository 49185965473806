import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetFaqsQuestion = createAsyncThunk(
  "asyncGetFaqsQuestion",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/faqs");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncPostFaqsQuestion = createAsyncThunk(
  "asyncPostFaqsQuestion",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { payload, callback } = obj;

    try {
      const response = await axiosPrivate.post("/faqs", payload);
      toast.success("FAQS created Successfully !");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateFaqsQuestion = createAsyncThunk(
  "asyncUpdateFaqsQuestion",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { payload, callback, id } = obj;

    try {
      const response = await axiosPrivate.patch(`/faqs/${id}`, payload);
      toast.success("FAQS  Update Successfully !");
      callback && callback();

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncDeletedFaqsQuestion = createAsyncThunk(
  "asyncDeletedFaqsQuestion",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { callback, id } = obj;

    try {
      const response = await axiosPrivate.delete(`/faqs/${id}`);
      toast.success("FAQS  Delete Successfully !");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

const FaqsSlice = createSlice({
  name: "FaqsSlice",
  initialState: {
    isLoading: false,
    list: [],
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(asyncGetFaqsQuestion.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetFaqsQuestion.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetFaqsQuestion.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    builder.addCase(asyncDeletedFaqsQuestion.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = state.list.filter(
        (items) => items._id !== action.payload._id
      );
      state.isError = false;
      state.message = null;
    });
  },
});

// export const {} = FaqsSlice.actions;
export default FaqsSlice.reducer;
