import React, { useState } from "react";

import PaymentTable from "./PaymentTable";
import AddPaymentMethodModal from "./AddPaymentMethodModal";
import { useAddPaymentMethodMutation } from "../../redux/services/paymentAPI";
import { notifyFailure, notifySuccess } from "../../components/toast";

function PaymentMethod() {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [addPaymentMethod] = useAddPaymentMethodMutation();

  const submitHandler = async () => {
    try {
      await addPaymentMethod(formData).unwrap();
      setOpenModal(false);
      notifySuccess("verification successfully completed");
    } catch (error) {
      notifyFailure(error.data.message);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-block card-stretch card-height">
              {" "}
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title mb-0">Payment Methods List</h4>
                </div>
                <div
                  className="float-right btn btn-sm btn-primary"
                  onClick={() => setOpenModal(!openModal)}
                >
                  <i className="fa fa-plus-circle" /> Add Payment Method
                </div>{" "}
              </div>
              <PaymentTable />
            </div>
          </div>
        </div>
      </div>

      <AddPaymentMethodModal
        show={openModal}
        title={"Add Payment Method"}
        setOpenModal={setOpenModal}
        handleInputChange={handleInputChange}
        submitHandler={submitHandler}
      />
    </>
  );
}

export default PaymentMethod;
