import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { asyncGetFaqsQuestion } from "../../redux/slices/faqsSlice";
import { asyncGetContacts } from "../../redux/slices/contactSlice";
import Table from "../../components/table";
import {
  useGetContactQuery,
  useUpdateContactMutation,
} from "../../redux/services/contactAPI";
import TableV2 from "../../components/tableV2";
import CustomModal from "../../components/modals/CustomModal";
import QueriesDetail from "./QueriesDetail";

function QueriesTable() {
  const [viewOpen, setViewOpen] = useState(false);
  const contact = useSelector((state) => state?.contact);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const {
    data: clients,
    isLoading,
    error,
  } = useGetContactQuery({
    page: currentPage,
    limit: itemsPerPage,
  });

  const [updateContact, { isLoading: shai, isError }] =
    useUpdateContactMutation();

  const submitHandler = async (id) => {
    setViewOpen(id);
    try {
      await updateContact({
        payload: { isRead: true },
        id: id,
      }).unwrap();
    } catch (error) {
      console.log("Invalid username or password", error);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetContacts());
  }, []);
  const columns = [
    {
      key: "name",
      displayName: "name",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <p className="ml-2 mb-0 ">{e?.firstName} </p>
          <p className="ml-2 mb-0 ">{e?.lastName} </p>
        </div>
      ),
      searchable: true,
    },
    {
      key: "Email",
      displayName: "Email",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <p className="ml-2 mb-0">{e?.email} </p>
        </div>
      ),
      searchable: true,
    },
    {
      key: "services",
      displayName: "Services",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <p className="ml-2 mb-0"> {e?.services} </p>
        </div>
      ),
      searchable: true,
    },
    {
      key: "message",
      displayName: "message",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <p className="ml-2 mb-0"> {e?.message}</p>
        </div>
      ),
      searchable: true,
    },

    {
      key: "Action",
      displayName: "Action",
      displayField: (e) => (
        <>
          <div className="d-flex justify-content-start align-items-center ">
            <div className="mr-2" onClick={() => submitHandler(e?._id)}>
              <i className="fas fa-edit text-primary" />
            </div>
          </div>
        </>
      ),
      searchable: true,
      // hidden: CheckPermission(location),
    },
  ];
  return (
    <>
      {" "}
      {/* <Table dataSource={clients?.results} isLoading={isLoading} columns={columns} /> */}
      <TableV2
        dataSource={clients?.results || []}
        isLoading={isLoading}
        columns={columns}
        totalEntries={clients?.totalResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={clients?.totalPages}
      />
      {viewOpen && (
        <CustomModal
          show={viewOpen}
          setOpenModal={setViewOpen}
          title={"Queries Detail"}
        >
          {" "}
          <QueriesDetail clients={clients} viewOpen={viewOpen} />
        </CustomModal>
      )}
    </>
  );
}

export default QueriesTable;
