import React from "react";

function QueriesDetail({ clients, viewOpen }) {
  const DataDetail = clients?.results.find((items) => items._id === viewOpen);

  return (
    <div className="row">
      <div className="col-md-6">
        <p>
          <b>Name:</b> {DataDetail?.name}
        </p>
      </div>
      <div className="col-md-6">
        <p>
          <b>Email:</b> {DataDetail?.email}
        </p>
      </div>

      <div className="col-md-6">
        <p>
          <b>Subject:</b> {DataDetail?.subject}
        </p>
      </div>

      <div className="col-md-6">
        <p>
          <b>Message:</b> {DataDetail?.message}
        </p>
      </div>
    </div>
  );
}

export default QueriesDetail;
