import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetWallets = createAsyncThunk(
  "getWallets",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { limit, page, query } = obj;
    try {
      const response = await axiosPrivate.get(
        `/wallets${`?limit=${limit}&page=${page}`}${
          query ? `&query=${query}` : ""
        }`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetTopupRequests = createAsyncThunk(
  "GetTopupRequests",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const { limit, page, query } = obj;
    try {
      const response = await axiosPrivate.get(
        `/wallets/topupRequests${`?limit=${limit}&page=${page}`}${
          query ? `&query=${query}` : ""
        }`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateTopupStatus = createAsyncThunk(
  "UpdateTopupStatus",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { id, data, callback, toast } = payload;
    try {
      const response = await axiosPrivate.patch(
        `/wallets/updateTopupStatus/${id}`,
        data
      );
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const WalletsSlice = createSlice({
  name: "Wallets",
  initialState: {
    isLoading: false,

    list: [],
    isError: false,
    add: {},
    update: {},
    topupRequests: { isLoading: false, list: [], isError: false },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetWallets.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetWallets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetWallets.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // case for wallertRequests
    builder.addCase(asyncGetTopupRequests.pending, (state, action) => {
      state.topupRequests.isLoading = true;
    });

    builder.addCase(asyncGetTopupRequests.fulfilled, (state, action) => {
      state.topupRequests.isLoading = false;
      state.topupRequests.list = action.payload;
      state.topupRequests.isError = false;
      state.topupRequests.message = null;
    });

    builder.addCase(asyncGetTopupRequests.rejected, (state, action) => {
      state.topupRequests.isLoading = false;
      state.topupRequests.message = action.payload;
      state.topupRequests.isError = true;
    });

    builder.addCase(asyncUpdateTopupStatus.fulfilled, (state, action) => {
      state.topupRequests.isLoading = false;
      state.topupRequests.list = state.topupRequests.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
      state.topupRequests.isError = false;
      state.topupRequests.message = null;
    });
  },
  reducers: {},
});

// export const {} = WalletsSlice.actions;
export default WalletsSlice.reducer;
