import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import {
  asyncDeletedFaqsQuestion,
  asyncGetFaqsQuestion,
  asyncUpdateFaqsQuestion,
} from "../../redux/slices/faqsSlice";
import CustomModal from "../../components/modals/CustomModal";
import FaqsForm from "./faqsForm";
import DeleteComfirmationModal from "../../components/modals/deleteConfirmationModal";

const FaqsTable = () => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const faqs = useSelector((state) => state?.faqs);

  const dispatch = useDispatch();

  const { isLoading, list } = faqs;

  const [formData, setFormData] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const faqsQuestionSubmit = (event) => {
    event.preventDefault();

    dispatch(
      asyncUpdateFaqsQuestion({
        payload: {
          ...formData,
        },
        callback: () => {
          setOpenModal(false);
          dispatch(asyncGetFaqsQuestion());
        },
        id: openModal,
      })
    );
  };

  useEffect(() => {
    const updateFaqs = list.find((items) => items._id === openModal);

    if (updateFaqs) {
      setFormData({
        subject: updateFaqs.subject,
        title: updateFaqs.title,
        description: updateFaqs.description,
        for: updateFaqs.for,
      });
    }

    if (!openModal) dispatch(asyncGetFaqsQuestion());
  }, [dispatch, list, openModal]);

  const columns = [
    {
      key: "Name",
      displayName: "Subject",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <p className="ml-2 mb-0">{e.subject}</p>
        </div>
      ),
      searchable: true,
    },
    {
      key: "title",
      displayName: "Title",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <p className="ml-2 mb-0">{e.title}</p>
        </div>
      ),
      searchable: true,
    },
    {
      key: "phone",
      displayName: "Description",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <p className="ml-2 mb-0">{e.description}</p>
        </div>
      ),
      searchable: true,
    },

    {
      key: "Action",
      displayName: "Action",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <div
            className="mr-2"
            title="Update Passenger"
            onClick={() => setOpenModal(e._id)}
          >
            <i className="fas fa-edit text-primary" />
          </div>
          <div className="mr-2" onClick={() => setDeleteRequest(e._id)}>
            <i className="fas fa-times text-danger" />
          </div>
        </div>
      ),
      searchable: true,
      // hidden: CheckPermission(location),
    },
  ];

  useEffect(() => {
    dispatch(asyncGetFaqsQuestion());
  }, [dispatch]);

  useEffect(() => {
    dispatch(asyncGetFaqsQuestion());
  }, [dispatch, deleteRequest]);

  const onReject = () => {
    dispatch(
      asyncDeletedFaqsQuestion({
        id: deleteRequest,
        callback: () => {
          setDeleteRequest(false);
        },
      })
    );
  };

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {openModal && (
        <CustomModal
          show={openModal}
          setOpenModal={setOpenModal}
          title={"Update Faqs"}
        >
          <FaqsForm
            submit={faqsQuestionSubmit}
            handleInputChange={handleInputChange}
            formData={formData}
          />
        </CustomModal>
      )}

      <DeleteComfirmationModal
        show={deleteRequest}
        message={"Are you sure you want to reject this Question?"}
        title={"Faqs Questions"}
        onHide={() => setDeleteRequest(false)}
        onAccept={() => onReject()}
      />
    </>
  );
};

export default FaqsTable;
