import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetRides = createAsyncThunk(
  "getRides",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/rides${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetRideDetails = createAsyncThunk(
  "getRideDetails",
  async (id, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/rides/${id}`);
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCreateRide = createAsyncThunk(
  "createRides",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.post("/vehicles", obj);
      toast.success("Ride created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncUpdateRide = createAsyncThunk(
  "updateRide",
  async (
    { id, data },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`/vehicles/${id}`, data);
      toast.success("Ride created Successfully");
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

// RideRequests start
export const asyncGetRideRequests = createAsyncThunk(
  "asyncGetRideRequests",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/rideRequests${country ? `?country=${country}` : ""}`
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

const rideSlice = createSlice({
  name: "rides",
  initialState: {
    isLoading: false,
    list: [],
    isError: false,
    rideDetails: {
      ride: null,
    },
    add: {},
    update: {},
    rideRequests: { isLoading: false, list: [], isError: false },
  },
  extraReducers: (builder) => {
    // Cases for Get Rides

    builder.addCase(asyncGetRides.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRides.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRides.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // rider Request
    builder.addCase(asyncGetRideRequests.pending, (state, action) => {
      state.rideRequests.isLoading = true;
    });
    builder.addCase(asyncGetRideRequests.fulfilled, (state, action) => {
      state.rideRequests.isLoading = false;
      state.rideRequests.list = action.payload;
      state.rideRequests.isError = false;
      state.rideRequests.message = null;
    });
    builder.addCase(asyncGetRideRequests.rejected, (state, action) => {
      state.rideRequests.isLoading = false;
      state.rideRequests.message = action.payload;
      state.rideRequests.isError = true;
    });

    // Cases for ride details
    builder.addCase(asyncGetRideDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRideDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rideDetails = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRideDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add Ride
    builder.addCase(asyncCreateRide.pending, (state, action) => {
      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateRide.fulfilled, (state, action) => {
      state.add = { isLoading: false, message: "Ride Created Successfully!" };
      state.list.push(action.payload);
    });

    builder.addCase(asyncCreateRide.rejected, (state, action) => {
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Ride
    builder.addCase(asyncUpdateRide.pending, (state, action) => {
      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateRide.fulfilled, (state, action) => {
      state.add = { isLoading: false, message: "Ride Updated Successfully!" };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });

    builder.addCase(asyncUpdateRide.rejected, (state, action) => {
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {},
});

// export const {} = rideSlice.actions;
export default rideSlice.reducer;
