import React from "react";

import { Link, NavLink } from "react-router-dom";

import PendingCabsTable from "./PendingCabsTable";

function PendingCabsPage() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Pending Cabs List</h4>
              </div>
              {/* <Link to="add" className="float-right btn btn-sm btn-primary">
                <i className="fa fa-plus-circle" /> Add Cab
              </Link>{" "} */}
            </div>
            <PendingCabsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingCabsPage;
