import React from "react";
import { Link, useLocation } from "react-router-dom";
import DriverDocumentsTable from "./DriverDocumentsTable";
import CheckPermission from "../../utils/checkPermission";

function DriverDocuments() {
  const location = useLocation();
  const check = CheckPermission(location);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Document List</h4>
              </div>

              {!check && (
                <Link
                  to="/documents/add"
                  className="float-right btn btn-sm btn-primary"
                >
                  <i className="fa fa-plus-circle" /> Add Document
                </Link>
              )}
            </div>
            <DriverDocumentsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverDocuments;
