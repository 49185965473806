import React from "react";

function TableHead({ itemsPerPage, setItemsPerPage, setCurrentPage, onChange, setSearchData }) {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-2">
          <div className="dataTables_length" id="dataTableBuilder_length">
            <label>
              Show{" "}
              <select
                name="dataTableBuilder_length"
                aria-controls="dataTableBuilder"
                className=""
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                  setCurrentPage(1);
                }}
              >
                {[10, 25, 50, 100].map((current, i) => {
                  return (
                    <option value={current} key={i}>
                      {current}
                    </option>
                  );
                })}
              </select>{" "}
              entries
            </label>
          </div>
        </div>
        <div className="col-md-6" />
        <div className="col-md-4">
          <div id="dataTableBuilder_filter" className="dataTables_filter">
            <label>
              <input
                type="search"
                className="p-2"
                placeholder="Search"
                aria-controls="dataTableBuilder"
                onChange={(e) => onChange(e)}
              />
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableHead;
