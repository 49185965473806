import React from "react";
import { Link } from "react-router-dom";
import UserTable from "./UserTable";

function UserPage() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            {" "}
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0"> User List</h4>
              </div>
              <Link to="add" className="float-right btn btn-sm btn-primary">
                <i className="fa fa-plus-circle" /> Add User
              </Link>{" "}
            </div>
            <UserTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPage;
