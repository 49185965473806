import React from "react";
import DotsLoader from "./loading/DotsLoader";

function CustomButton(props) {
  const { title, isLoading, icon } = props;
  return (
    <button className="btn btn-primary" disabled={isLoading} {...props}>
      {isLoading ? (
        <DotsLoader />
      ) : (
        <>
          {icon} {title}
        </>
      )}
    </button>
  );
}

export default CustomButton;
