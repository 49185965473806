import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import FaqsQuestion from "../../pages/faqs/faqsForm";
import { IoClose } from "react-icons/io5";

function CustomModal({ show, setOpenModal, children , title }) {
  const handleClose = () => {
    setOpenModal(false);
  };

 
  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className="m-0"> {title}</h5>
        <div className="m-0" onClick={handleClose}>
          <IoClose />
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default CustomModal;
