import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import axiosPrivate from "../../services/api";

export const asyncGetContacts = createAsyncThunk(
  "asyncGetContacts",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/contacts");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const contactSlice = createSlice({
  name: "ContactSlice",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
  },

  extraReducers: (builder) => {
    builder.addCase(asyncGetContacts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetContacts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload.results;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetContacts.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
});

// export const {} = contactSlice.actions;
export default contactSlice.reducer;
