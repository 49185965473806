import React from "react";

function TablePagination({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const generatePageNumbers = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    } else if (currentPage <= 2) {
      return [1, 2, 3, "dots", totalPages];
    } else if (currentPage >= totalPages - 1) {
      return [1, "dots", totalPages - 2, totalPages - 1, totalPages];
    } else {
      return [
        1,
        "dots",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "dots",
        totalPages,
      ];
    }
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="row align-items-center" style={{backgroundColor: "#353535", padding: "10px 20px 10px 20px"}}>
      <div className="col-md-6">
        <div
          className="dataTables_info"
          id="dataTableBuilder_info"
          role="status"
          aria-live="polite"
          style={{color: "white"}}
        
        >
          Showing {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)}{" "}
          to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems}{" "}
          entries
        </div>
      </div>
      <div className="col-md-6">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="dataTableBuilder_paginate"
        >
          <a
            className={`paginate_button previous ${
              currentPage === 1 ? "disabled" : ""
            }`}
            aria-controls="dataTableBuilder"
            tabIndex={0}
            id="dataTableBuilder_previous"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </a>
          {pageNumbers.map((item, index) =>
            item === "dots" ? (
              <span key={index} className="ellipsis">
                ...
              </span>
            ) : (
              <a
                key={index}
                className={`paginate_button ${
                  currentPage === item ? "current" : ""
                }`}
                aria-controls="dataTableBuilder"
                tabIndex={0}
                onClick={() => handlePageChange(item)}
              >
                {item}
              </a>
            )
          )}
          <a
            className={`paginate_button next ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            aria-controls="dataTableBuilder"
            tabIndex={0}
            id="dataTableBuilder_next"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </a>
        </div>
      </div>
    </div>
  );
}

export default TablePagination;
