import React from "react";
import { Button, Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import DropzoneUpload from "../../components/DropzoneUpload";

import { useSelector } from "react-redux";

function AddPaymentMethodModal({
  show,
  title,
  setOpenModal,
  handleInputChange,
  submitHandler,
}) {
  const handleClose = () => {
    setOpenModal(false);
  };

  const country = useSelector((state) => state?.auth?.country);

  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className="m-0"> {title}</h5>
        <div className="m-0" onClick={handleClose}>
          <IoClose />
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-lg-12 my-2">
            <label htmlFor="address" className="form-control-label">
              Logo:
            </label>
            <DropzoneUpload
              directory={`assets/vehicle-categories/${country}/`}
              name="imageUrl"
              onChange={handleInputChange}
              isEnabled={true}
              //   disableReason="Please select driver and document type first!"
            />
          </div>
          <div className="col-lg-12 my-2">
            <div>
              <h6>Name: </h6>
              <input
                placeholder="Name"
                className="form-control"
                required=""
                name="name"
                type="text"
                id="first_name"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-lg-12 my-2">
            <label htmlFor="address" className="form-control-label">
              Description
            </label>
            <textarea
              className="form-control textarea"
              rows={3}
              placeholder="description"
              name="description"
              cols={50}
              id="address"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={submitHandler}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPaymentMethodModal;
