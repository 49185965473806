import React, { useEffect, useState } from "react";

const DatePicker = () => {
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [daysInMonth, setDaysInMonth] = useState(31);

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    if (e.target.value === "2" && selectedDay > 28) {
      setSelectedDay("");
    }
  };
                                                                                                
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    if (selectedMonth === "2") {
      setDaysInMonth(28);
      if (selectedDay > 28) {
        setSelectedDay("");
      }
    } else if (["4", "6", "9", "11"].includes(selectedMonth)) {
      setDaysInMonth(30);
      if (selectedDay > 30) {
        setSelectedDay("");
      }
    } else {
      setDaysInMonth(31);
    }
  }, [selectedMonth, selectedDay]);

  const years = Array.from(
    { length: new Date().getFullYear() - 1899 },
    (_, index) => 1900 + index
  ).reverse();

  return (
    <div className="">
      <select value={selectedDay} onChange={handleDayChange}>
        <option value="">Day</option>
        {/* Generate day options */}
        {Array.from({ length: daysInMonth }, (_, index) => (
          <option key={index + 1} value={index + 1}>
            {index + 1}
          </option>
        ))}
      </select>

      <select value={selectedMonth} onChange={handleMonthChange}>
        <option value="">Month</option>
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
        {/* Add options for all 12 months */}
      </select>

      <select value={selectedYear} onChange={handleYearChange}>
        <option value="">Year</option>
        {/* Generate year options, e.g., from 1900 to the current year */}
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}                            
      </select>
    </div>   
  );
};

export default DatePicker;
