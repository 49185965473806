import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useLocation } from "react-router-dom";

import {
  asyncGetTopupRequests,
  asyncUpdateTopupStatus,
} from "../../redux/slices/walletSlice";
import moment from "moment/moment";
import DeleteComfirmationModal from "../../components/modals/deleteConfirmationModal";
import toast from "react-hot-toast";
import CheckPermission from "../../utils/checkPermission";
import TableV2 from "../../components/tableV2";

const WalletTopupRequestsTable = () => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const wallets = useSelector((state) => state.wallets.topupRequests);

  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { isLoading, list } = wallets;

  const {
    // limit,
    //  page,
    results,
    totalPages,
    totalResults,
  } = list;

  // };

  const dispatch = useDispatch();

  const onApprove = () => {
    console.log("clickthis")
    dispatch(
      asyncUpdateTopupStatus({
        id: selectedItem,
        data: { status: "Approved" },
        callback: () => {
          setShowAcceptModal(false);
          toast.success("Request Approved Successfully!");
        },
      })
    );
  };

  const onReject = () => {
    dispatch(
      asyncUpdateTopupStatus({
        id: selectedItem,
        data: { status: "Rejected" },
        callback: () => {
          setShowDeleteModal(false);
          toast.success("Request Rejected Successfully!");
        },
      })
    );
  };

  const columns = [
    {
      displayField: (e) => (
        <>
          {e?.wallet?.user?.firstName} {e?.wallet?.user?.lastName}
        </>
      ),
      displayName: "Passenger",
      searchable: true,
    },
    {
      displayField: (e) => (
        <>
          {e?.currency} {e?.amount}
        </>
      ),
      displayName: "Amount Request",
      searchable: true,
    },
    {
      key: "paymentMethod",
      displayName: "Payment Method",
      searchable: true,
    },
    {
      // key: "phone",
      displayName: "Time",
      displayField: (e) => (
        <> {moment(e?.updatedAt).format("hh:mm a @ MM-DD-YYYY")} </>
      ),

      searchable: true,
    },
    {
      displayField: (e) => (
        <>
          {e.status === "Pending" ? (
            <span className="text-capitalize badge bg-primary">{e.status}</span>
          ) : e.status === "Approved" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
      displayName: "Status",
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        
        <>
        {console.log(e.id, "chal")}
          {e.status === "Pending" ? (
            <div className="d-flex justify-content-start align-items-center">
              <button
                className="mr-2 text-danger bg-transparen border-none"
                disabled={e.status !== "Pending"}
              >
                <i
                  className="fas fa-check text-success"
                  onClick={() => {
                    setSelectedItem(e?._id || e?.id);
                    setShowAcceptModal(true);
                  }}
                />
              </button>

              <button
                className="mr-2 text-danger bg-transparent border-none "
                disabled={e.status !== "Pending"}
              >
                <i
                  className="fas fa-times"
                  onClick={() => {
                    setSelectedItem(e?._id || e?.id);
                    setShowDeleteModal(true);
                  }}
                />
              </button>
            </div>
          ) : (
            <div className="">N/A</div>
          )}
        </>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  useEffect(() => {
    dispatch(asyncGetTopupRequests({ page: currentPage, limit: itemsPerPage }));
  }, [dispatch, currentPage, itemsPerPage]);

  const searchDataHandlerOnTable = (e) => {
    const { value } = e.target;
    dispatch(
      asyncGetTopupRequests({
        query: value,
        page: currentPage,
        limit: itemsPerPage,
      })
    );
  };

 

  return (
    <>
      <TableV2
        dataSource={
          results && results.length > 0
            ? results.filter((x) => x.paymentMethod === "Mobile Money")
            : []
        }
        {...{
          columns,
          isLoading,
          searchDataHandlerOnTable,
          currentPage,
          setCurrentPage,
          itemsPerPage,
          setItemsPerPage,
          totalPages,
          totalEntries: totalResults,
        }}
      />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}

      <DeleteComfirmationModal
        show={showAcceptModal}
        message={"Are you sure you want to approve this request?"}
        title={"Approve Request"}
        onHide={() => setShowAcceptModal(false)}
        onAccept={onApprove}
      />

      <DeleteComfirmationModal
        show={showDeleteModal}
        message={"Are you sure you want to reject this request?"}
        title={"Reject Request"}
        onHide={() => setShowDeleteModal(false)}
        onAccept={() => onReject()}
      />
    </>
  );
};
export default WalletTopupRequestsTable;
