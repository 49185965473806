import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FeeForm from "./FeeForm";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetVehicleCategories } from "../../redux/slices/vehicleCategorySlice";

import { useCreateFlatFeeMutation } from "../../redux/services/flatFeeApi";
import { notifyFailure, notifySuccess } from "../../components/toast";

const initialState = {};
function CreateFlatFee() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [formData, setFormData] = useState(initialState);

  const changeHandler = useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const [createFlatFee, { isLoading }] = useCreateFlatFeeMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await createFlatFee({ payload: formData }).unwrap();
      navigate("/fees");
      notifySuccess("Fee updated successfully");
    } catch (err) {
      console.log("err=>", err);
      notifyFailure(err.data.message);
    }
  };

  useEffect(() => {
    dispatch(asyncGetVehicleCategories());
  }, [dispatch, auth.country]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Add Fees</h4>
            </div>
          </div>
          <FeeForm {...{ submitHandler, changeHandler, formData, isLoading }} />
        </div>
      </div>
    </div>
  );
}

export default CreateFlatFee;
