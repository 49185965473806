import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetDrivers } from "../../redux/slices/driverSlice";
import { Link, useLocation } from "react-router-dom";
import { asyncGetRequiredDocuments } from "../../redux/slices/documentSlice";
import CheckPermission from "../../utils/checkPermission";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";
const RequiredDocumentsTable = () => {
  const location = useLocation();
  const columns = [
    {
      key: "name",
      displayName: "Name",
      searchable: true,
    },
    {
      key: "type",
      displayName: "Document For",
      searchable: true,
    },
    {
      displayName: "Has Expiry",
      displayField: (e) => (
        <>
          {e.hasExpiry ? (
            <span className="text-capitalize badge bg-primary">Yes</span>
          ) : (
            <span className="text-capitalize badge bg-danger">No</span>
          )}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Has Back Side",
      displayField: (e) => (
        <>
          {e.hasBackSide ? (
            <span className="text-capitalize badge bg-primary">Yes</span>
          ) : (
            <span className="text-capitalize badge bg-danger">No</span>
          )}
        </>
      ),
      searchable: true,
    },
    {
      key: "status",
      displayName: "Status",
      displayField: (e) => (
        <>
          {e.status === "Active" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link className="mr-2" to={`edit/${e._id}`} title="Update Driver">
            <i className="fas fa-edit text-primary" />
          </Link>
          <Link className="mr-2" to={`details/${e._id}`}>
            <i className="fas fa-eye text-secondary" />
          </Link>
          {/* <a
          className="mr-2 text-danger"
          href="javascript:void(0)"
          data--submit="rider36"
          data--confirmation="true"
          data-title="Delete Rider"
          title="Delete Rider"
          data-message="Are you sure you want to delete?"
        >
          <i className="fas fa-trash-alt" />
        </a> */}
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  const documents = useSelector((state) => state.documents);
  const auth = useSelector((state) => state.auth);

  const { isLoading, list } = documents;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  // const openMoreVertDropDown = () => {
  //   setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  // };

  const [modal, setModal] = useState({ open: false, data: null });

  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };

  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(asyncGetRequiredDocuments());
  }, [auth.country]);

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};
export default RequiredDocumentsTable;
