import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";

function FareForm({ submitHandler, formData, changeHandler, isLoading }) {
  const vehicleCategories = useSelector((state) => state.vehicleCategories);

  const userInfo = useSelector((state) => state.auth.userInfo);
  const country = userInfo.country;

  return (
    <div className="d-flex flex-column w-tables rounded mt-3 ">
      <div
        id="leads-table_wrapper"
        className="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <form onSubmit={submitHandler} className="p-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="service_package"
                >
                  Select Category
                </label>
                <select
                  className="form-control height-35 f-14"
                  id="service_package"
                  name="vehicleCategory"
                  onChange={changeHandler}
                  value={formData.vehicleCategory}
                  required
                >
                  <option>Select category</option>
                  {vehicleCategories.list.map((x) => (
                    <option value={(x.id)} key={x.id}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group my-3">
                <label className="f-14 text-dark-grey mb-12" for="minimum_fare">
                  Minimum Fare ( {country.currency} )
                </label>
                <input
                  type="number"
                  step="0.1"
                  className="form-control height-35 f-14"
                  id="minimum_fare"
                  placeholder="Please enter minimum fare"
                  name="minimumFare"
                  onChange={changeHandler}
                  value={formData.minimumFare}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label className="f-14 text-dark-grey mb-12" for="base_fare">
                  Base Fare ( {country.currency} )
                </label>
                <input
                  type="number"
                  step="0.1"
                  min={0}
                  className="form-control height-35 f-14"
                  id="base_fare"
                  placeholder="Please input base fare"
                  name="baseFare"
                  onChange={changeHandler}
                  value={formData.baseFare}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="per_kilo_charge"
                >
                  Per KM Charges ( {country.currency} )
                </label>
                <input
                  type="number"
                  step="0.1"
                  min={0}
                  className="form-control height-35 f-14"
                  id="per_kilo_charge"
                  placeholder="Please input service charges"
                  name="perKmFee"
                  onChange={changeHandler}
                  value={formData.perKmFee}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="waiting_charge"
                >
                  Waiting Charges Per Minute ( {country.currency} )
                </label>
                <input
                  type="number"
                  step="0.1"
                  min={0}
                  className="form-control height-35 f-14"
                  id="waiting_charge"
                  placeholder="Please input waiting charge"
                  name="perMinuteWaitingFee"
                  onChange={changeHandler}
                  value={formData.perMinuteWaitingFee}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="cancellation_fee"
                >
                  Cancellation Fee ( {country.currency} )
                </label>
                <input
                  type="number"
                  step="0.1"
                  min={0}
                  className="form-control height-35 f-14"
                  id="cancellation_fee"
                  placeholder="Please input cancellation fee"
                  name="cancellationFee"
                  onChange={changeHandler}
                  value={formData.cancellationFee}
                  required
                />
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="commission_type"
                >
                  Commission Type
                </label>
                <select
                  className="form-control height-35 f-14"
                  id="commission_type"
                  name="commissionType"
                  onChange={changeHandler}
                  value={formData.commissionType}
                  required
                >
                  <option>Select commission type</option>
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>{" "}
                </select>
              </div>
            </div> */}

            {/* {formData.commissionType === "percentage" ? ( */}
            {/* <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="company_commission"
                >
                  Company Commission (%)
                </label>
                <input
                  type="number"
                  step="0.1"
                  min={0}
                  className="form-control height-35 f-14"
                  id="company_commission"
                  placeholder="Enter commission %"
                  name="commissionValue"
                  onChange={changeHandler}
                  value={formData.commissionValue}
                  required
                />
              </div>
            </div> */}
            {/* ) : ( */}

            {/* )} */}
            <div className="col-lg-12">
              <div
                className="form-group my-3"
                style={{ marginTop: "1.5em !important" }}
              >
                <CustomButton
                  className="btn-primary rounded f-14 p-2 mr-3"
                  isLoading={isLoading}
                  title="Submit"
                  type="submit"
                />
                {/* <button
                  type="submit"                                    
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Submit
                </button> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FareForm;
