import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetRideFares = createAsyncThunk(
  "getRideFares",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const country = getState().auth.country;
    try {
      const response = await axiosPrivate.get(
        `/rideFares${country ? `?country=${country}` : ""}`
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncCreateRideFare = createAsyncThunk(
  "createRideFare",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;
    try {
      const response = await axiosPrivate.post("/rideFares", data);
      toast.success("Ride fare created Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);
export const asyncUpdateRideFare = createAsyncThunk(
  "updateRideFare",
  async (
    { id, data, callback },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      // const { data, callback } = payload;
      const response = await axiosPrivate.patch(`/rideFares/${id}`, data);
      toast.success("Ride fare updated Successfully");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

const rideFareSlice = createSlice({
  name: "rideFares",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
  },
  extraReducers: (builder) => {
    // Cases for Get Rides
    builder.addCase(asyncGetRideFares.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRideFares.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRideFares.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for Add Ride
    builder.addCase(asyncCreateRideFare.pending, (state, action) => {
      state.add = { isLoading: true };
    });
    builder.addCase(asyncCreateRideFare.fulfilled, (state, action) => {
      state.add = {
        isLoading: false,
        message: "Ride fare created successfully!",
      };
      state.list.push(action.payload);
    });
    builder.addCase(asyncCreateRideFare.rejected, (state, action) => {
      state.add = { isLoading: false, message: action.payload };
    });

    // Cases for Update Ride
    builder.addCase(asyncUpdateRideFare.pending, (state, action) => {
      state.update = { isLoading: true };
    });
    builder.addCase(asyncUpdateRideFare.fulfilled, (state, action) => {
      state.update = {
        isLoading: false,
        message: "Ride fare updated successfully!",
      };
      state.list = state.list.map((x) =>
        x._id === action.payload._id ? action.payload : x
      );
    });
    builder.addCase(asyncUpdateRideFare.rejected, (state, action) => {
      state.update = { isLoading: false, message: action.payload };
    });
  },
  reducers: {
    // userLogout(state, action) {
    //   state.userInfo = null;
    // },
  },
});

// export const {} = rideFareSlice.actions;
export default rideFareSlice.reducer;
