import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const paymentAPI = createApi({
  reducerPath: "paymentAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchPayment"],
  endpoints: (builder) => ({
    getPaymentMethod: builder.query({
      query: () => ({
        url: `/paymentMethods`,
        method: "GET",
      }),
      providesTags: ["refetchPayment"],
    }),

    addPaymentMethod: builder.mutation({
      query: (payload) => ({
        url: `/paymentMethods`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["refetchPayment"],
    }),

    ClearSattlementRequests: builder.query({
      query: ({ page = 1, limit = 10, query, country }) => ({
        url: `/dailySattlements/clearSattlementRequests`,
        method: "GET",
        params: {
          page,
          limit,
          country: country ? country : undefined,
          query: query ? query : undefined,
        },
      }),
      invalidatesTags: ["refetchPayment"],
    }),
  }),
});

export const {
  useGetPaymentMethodQuery,
  useAddPaymentMethodMutation,
  useClearSattlementRequestsQuery,
} = paymentAPI;
