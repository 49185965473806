import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CategoryForm from "./categoryForm";
import { useDispatch, useSelector } from "react-redux";
import { asyncUpdateVehicleCategory } from "../../redux/slices/vehicleCategorySlice";

function EditVehicleCategory() {
  const [categoriesData, setCategoriesData] = useState({});
  const vehicleCategories = useSelector((state) => state.vehicleCategories);
  const { list, update } = vehicleCategories;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const param = useParams();

  const vehicleCategoryFound = list.find((items) => items._id === param.id);

  useEffect(() => {
    if (vehicleCategoryFound) {
      setCategoriesData({
        name: vehicleCategoryFound.name,
        engineCC: vehicleCategoryFound.engineCC,
        seatingSpace: vehicleCategoryFound.seatingSpace,
        imageUrl: vehicleCategoryFound.imageUrl,
        // country: vehicleCategoriesById.country,
      });
    } else {
      navigate("/categories");
    }
  }, [navigate, vehicleCategoryFound]);

  const categoriesHandler = (event) => {
    const { name, value } = event.target;
    setCategoriesData({
      ...categoriesData,
      [name]: value,
    });
  };

  const categoriesSubmit = (e) => {
    e.preventDefault();

    dispatch(
      asyncUpdateVehicleCategory({
        data: categoriesData,
        id: param.id,
        callback: () => {
          navigate("/categories");
        },
      })
    );
  };

  return (
    <div className="px-4 py-2 border-top-0 emp-dashboard">
      <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
        <div id="table-actions" className="flex-grow-1 align-items-center">
          <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
            Create Category{" "}
          </h4>
        </div>
        <div
          className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
          role="group"
        >
          <NavLink
            to="/fares"
            type="button"
            className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
            id="add-lead"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
            </svg>{" "}
            View Fares
          </NavLink>
        </div>
      </div>
      <CategoryForm
        {...{
          categoriesHandler,
          categoriesSubmit,
          setCategoriesData,
          categoriesData,
          isLoading: update.isLoading,
        }}
      />
    </div>
  );
}

export default EditVehicleCategory;
