import React from "react";
import { Link } from "react-router-dom";
import SubmittedDocumentsTable from "./SubmittedDocumentsTable";
import { useSelector } from "react-redux";

function SubmittedDocuments() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { role } = userInfo;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch card-height">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title mb-0">Submitted Documents List</h4>
              </div>
              {role !== "super_admin" && (
                <Link
                  to="/documents/add"
                  className="float-right btn btn-sm btn-primary"
                >
                  <i className="fa fa-plus-circle" /> Add Document
                </Link>
              )}
            </div>
            <SubmittedDocumentsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmittedDocuments;
