import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import sidebarList from "../layout/AdminLayout/MenuSidebar";

function CheckPermission(location) {
  const userInfoAuth = useSelector((state) => state.auth.userInfo);
  const { role } = userInfoAuth;

  const manageRoleAccess = sidebarList.find((items) =>
    items?.subItems?.find(
      (items) =>
        items?.path === location?.pathname &&
        items.manage?.find((gol) => gol === role)
    )
  );

  if (manageRoleAccess) {
    return false;
  } else {
    return true;
  }
}

export default CheckPermission;
